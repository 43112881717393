//importing npm dependencies and hooks 
import React, { useEffect, useState, useContext } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport, ColumnToggle } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Form, InputGroup, Table } from 'react-bootstrap';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { set, useForm } from "react-hook-form"
import Popup from '../popup';
import { getTableManagementData, updateTableData, addTableData } from '../../services/api';
import { confirmAlert } from 'react-confirm-alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCompanyData, updateCompanyData, addCompanyData } from '../../services/api';
import { PaginationContext } from "../../context/pagination-context";
import {useMsal} from "@azure/msal-react";
//importing translation
import { useTranslation } from 'react-i18next';

//importing styles
import '../../css/table.css';
import '../../css/form.css';
import '../../css/admin.css';
import '../../css/business.css';

//importing sample table data later removed

const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;
const ref = React.createRef();

function CompanyList() {

    const {
        page,
        pageSize,
        displayedCtx,
        dispatch: dispatchPagination,
    } = useContext(PaginationContext);
   
    //initiate language translation 
    const { t, i18n } = useTranslation();
    function formatDate(date) {
        let mydate = new Date(date);
        let days = mydate.getDate();

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        let month = monthNames[mydate.getMonth()];
        let year = mydate.getFullYear();
        return month + " " + days + " " + year;
       
    };
    function formatFromDate(cell, row) {

        return (
            <>{ row.workStartDate === null ? null : formatDate(row.workStartDate)}
            </>
        );
    }

    const { ToggleList } = ColumnToggle;
    const columns = [
        
        {
            dataField: 'companyName',
            text: t('Company Name'),
        },
        {
            dataField: 'noOfEmployees',
            text: t( 'No. Of Employees'),
            headerClasses: ' tbl-line',
           
        },
        {
            dataField: 'businessManager',
            text: t('Business Manager'),
        },
        {
            dataField: 'onSiteManager',
            text: t('On-site Manager'),
        },
        {
            dataField: 'hseManager',
            text: t('HSE Manager'),
        },
        {
            dataField: 'workStartDate',
            text: t('Work Start Date'),
            headerClasses: 'workSD',
            formatter: formatFromDate,
        },
        {
            dataField: 'poNumber',
            text: t('PO No.'),
        },
        
        {
            dataField:'actions',
            text:'',
            formatter:(cell,row,i18n)=>(
                <div>
                    <Link to="/viewCompany" state={{ data: row }} ><button class="popup-admin-btn" type="button" style={{ marginLeft: 10 }}
                    // onClick={()=>handleAction('view, row')}
                    >{t('View')}</button> </Link> 
                      <Link to="/editCompany"  state={{ data: row }}  disabled={row.isApproved}><button disabled={row.isApproved} class="popup-admin-btn" type="button" style={{ marginLeft: 10 }} 
                    // onClick={()=>handleAction('view, row')}
                    // onClick={()=>disableCompany(row['id'])}
                    >{t('Edit')}</button></Link>
                     {/* <button class="popup-admin-btn" type="button" style={{ marginLeft: 10 }} 
                    // onClick={()=>handleAction('view, row')}
                    >{t('Disable')}</button> */}
                    {/* <Link to="/contractorList">
                     <button class="popup-admin-btn" type="button" style={{ marginLeft: 10 }}
                    
                    >{t('Users')}</button>
                    </Link> */}
                </div>
                
            ),
            headerClasses: 'tbl-header-medium tbl-line',

        }
    ];

    const defaultSorted = [
        {
            dataField: 'SiteName',
            order: 'asc'
        }
    ];

    var asc = document.getElementById(".caret-4-asc");
    var desc = document.getElementById(".caret-4-asc");
    // state initialization

    const [tableData, setTableData] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [inactiveData, setInactiveData] = useState([]);
    const [fullData, setFullData] = useState([]);
    const [tableSelect, setTableSelect] = useState({ selected: [] });
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState();
    const [selectedRow, setSelectedRow] = useState([]);
    // const [page, setPage] = useState(1);
    const { instance } = useMsal();
    // table no record message
    const emptyDataMessage = () => { return 'No Records Found' };

    // table pagination options
    const options = {
        page: page,
        sizePerPage: 25,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        custom: true,
        totalSize: tableData.length,
        // onPageChange: function (page, sizePerPage) {
        //     options.page = page;
        //     setPage(page);
        //     options.sizePerPage = sizePerPage;
        // },
        onPageChange: function (page, sizePerPage) {
            options.page = page;
            dispatchPagination({ type: "set_page", payload: { page } });
            options.sizePerPage = sizePerPage;
        },
    };
    const selectRow = {
        mode: 'checkbox', // single row selection
        selected: tableSelect.selected,
        onSelect: function (row, isSelect) {
            if (isSelect == true) {
                setTableSelect(() => ({
                    selected: [...tableSelect.selected, row.companyName]
                }))
                if (document.getElementById('edit-icon').style.color == "rgb(182, 182, 182)") {
                    toast.warning("Please Save the records before edit");
                }
                setSelectedRow([row, ...selectedRow]);

            } else {
                let data;
                setTableSelect(() => ({
                    selected: (tableSelect.selected).filter(x => x !== row.companyName)
                }))
                for (let i = 0; i < selectedRow.length; i++) {
                    if (selectedRow[i].companyName == row['companyName']) {
                        data = selectedRow.splice(row, i);
                    }
                }
                setSelectedRow(data)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.companyName);
            if (isSelect == true) {
                setSelectedRow(rows);
                setTableSelect(() => ({
                    selected: ids
                }))
            } else {
                setSelectedRow([])
                setTableSelect(() => ({
                    selected: []
                }))
            }
        }
    };

  

    // Function For Api Calls
    const getTableDataFunc = () => {
        let mounted = true;
        let culture = 'fr-CH'
        if (i18n.language == 'en') {  
          culture = 'en-US'
        }
        if (i18n.language == 'de') {      
          culture = 'de-DE'
        }

        getCompanyData(culture, instance)
        .then(items => {
           
           if(items) {setTableData([items])
            sessionStorage.setItem('companyId', items.id);
            sessionStorage.setItem('companyName', items.companyName);
            setFullData([items])} 
        
            
        })








        // setSelectedRow([])
        // setTableSelect({ selected: [] });
        // let GGG= getCompanyData();
        // console.log(GGG,"calling")
        
    //     let items = [{
    //         company_Name:"qqq LTD.",
    //         no_of_Employees:"15",
    //         business_Manager:"business@abc.com",
    //         onsite_Manager: "onsite@abc.com",
    //         hse_Manager:"hse@abc.com",
    //         work_Start_Date:"12/7/2023",
    //         po_No:"12345"
    //     },
    // ]
        
        
    }

    // for update row 
    // const updateFunc = (data) => {
    //     updateCompanyData(data)
    //         .then(data => {
    //             if (data['status'] === 400) {
    //                 toast.error(<p style={{ color: "red" }}>HTTP Error 400 : Bad Request</p>)
    //             } else if (data['status'] === 404) {
    //                 toast.error(<p style={{ color: "red" }}>HTTP Error 404 : Not Found</p>)
    //             } else if (data['status'] === 500) {
    //                 toast.error(<p style={{ color: "red" }}> {data['detail']}</p>)
    //             } else if (data == "Site Mst details updated.") {
    //                 toast.success("Site Mst details updated");
    //                 getTableDataFunc();
    //             } else {
    //                 toast.error(<p style={{ color: "red" }}> Unknown error Occurred!</p>)

    //             }
    //         })
    // }
    //for adding new row
    const addFunc = () => {
        
    }
    useEffect(() => {
        setTableData([]);
        setTimeout(() => getTableDataFunc(), 1500);
        // getTableDataFunc();

    }, [i18n.language]);

    const onEditClick = (event) => {
        var elems = document.querySelectorAll('.editable');
        var elemsRead = document.querySelectorAll('.readable');
        let select_list = selectedRow.sort((a, b) => a.companyName - b.companyName)
        select_list.forEach((element, i) => {
            var index = 0, length = elems.length;
            for (; index < length; index++) {
                let clsNames = elems[index].classList;
                clsNames = clsNames.value;
                if (clsNames.includes("edit" + select_list[i].companyName+'e')) {
                    elems[index].style.display = "block";
                    elemsRead[index].style.display = "none";
                    document.getElementById('edit-icon').style.display = "none";
                    document.getElementById('edit-cancel').style.display = "block";
                    document.getElementById('save-icon').style.color = "#5B3181";
                    document.getElementById('edit-icon').style.color = "#B6B6B6";
                    document.getElementById('add-icon').style.color = "#B6B6B6";
                }
            }
        });

    }

    const cancelEdit = (event) => {
        var elems = document.querySelectorAll('.editable');
        var elemsRead = document.querySelectorAll('.readable');
        let select_list = selectedRow.sort((a, b) => a.companyName - b.companyName)
        select_list.forEach((element, i) => {
            var index = 0, length = elems.length;
            for (; index < length; index++) {
                let clsNames = elems[index].classList;
                clsNames = clsNames.value;
                if (clsNames.includes("edit" + select_list[i].companyName+'e')) {
                    elems[index].style.display = "none";
                    elemsRead[index].style.display = "block";
                    document.getElementById('edit-icon').style.display = "block";
                    document.getElementById('edit-cancel').style.display = "none";
                    document.getElementById('edit-icon').style.color = "#5B3181";
                    document.getElementById('save-icon').style.color = "#B6B6B6";
                    document.getElementById('add-icon').style.color = "#5B3181";
                    
                }
            }
        })
    }

    const onSaveClick = (event) => {
        let formValue = getValues();
        let select_list = selectedRow.sort((a, b) => a.companyName - b.companyName)
        // for mapping all edit data to save 
        let arrData = [];
        select_list.forEach((element, i) => {

            let formId = formValue['companyName' + select_list[i].companyName];
            if ((select_list[i].companyName) == formId) {
                arrData.push(
                    {
                        "companyName": select_list[i].companyName,
                        "noOfEmployees": formValue['No of Employees' + select_list[i].companyName],
                        "businessManager": formValue['Business Manager' + select_list[i].companyName] == 'true' ? true : false
                    }
                )
            }

        })
        // for mapping all edit data to save end here
        var elems = document.querySelectorAll('.editable');
        var elemsRead = document.querySelectorAll('.readable');

        select_list.forEach((element, i) => {
            var index = 0, length = elems.length;
            for (; index < length; index++) {
                let clsNames = elems[index].classList;
                clsNames = clsNames.value;
                if (clsNames.includes("edit" + select_list[i].companyName+'e')) {
                    elems[index].style.display = "none";
                    elemsRead[index].style.display = "block";
                    document.getElementById('edit-icon').style.color = "#5B3181";
                    document.getElementById('save-icon').style.color = "#B6B6B6";
                    document.getElementById('add-icon').style.color = "#5B3181";
                    document.getElementById('edit-cancel').style.display = "none"
                    document.getElementById('edit-icon').style.display = "block";
                    
                }
            }
        })
        //function call for update data
       // updateFunc(arrData)
    }


    // search box display functions
    const handleFilter = (event, param, filterval) => {
        if (param == "searchInput") {
            if (document.getElementById(param).style.display == "none") {
                document.getElementById(param).style.display = "block";
            } else {
                document.getElementById(param).style.display = "none";
            }
        } else if (param == "filter_alt") {
            if (document.getElementById('filter_alt-child').style.display == "block") {
                document.getElementById('filter_alt-child').style.display = "none";
            } else {
                document.getElementById('filter_alt-child').style.display = "block";
            }
        } else if (param == 'active_filter' || param == 'inActive_filter' || param == 'allData') {
            document.getElementById('filter_alt-child').style.display = "none";
            param == 'active_filter' ? setTableData(activeData) : param == 'allData' ? setTableData(fullData) : setTableData(inactiveData);
        }
    }

    // popup
    const togglePopup = () => {
        setIsOpen(true);

        let numRows = (
            <>

                <Row className="sub-header pb-2 pt-2 mb-2">
                    <b>Add New Site</b>
                </Row>
                <Row className="heading mb-2 mt-2">
                    <Col md={8}>
                        No. Of Employees:
                        <div className="popup-input mt-2">
                            <InputGroup  >
                                <Form.Control
                                    placeholder="Enter No. Of Employees"
                                    aria-label="No. Of Employees "
                                    aria-describedby="basic-addon1"
                                    className=""
                                    {...register("SiteName")}
                                />
                            </InputGroup>
                        </div>
                    </Col>


                    <Col md={4}>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}></Col>
                    <Col md={6}>
                        <Row>
                            <Col md={6}>
                                <Link to="/SiteTable"> <Button className='cancel-button m-2' variant="light" onClick={closepopup}>Cancel </Button></Link>

                            </Col>
                            <Col md={6}>
                                <Button className='add-button m-2' variant="light" type="submit" onClick={addFunc} >Add </Button>

                            </Col>

                        </Row>
                    </Col>
                </Row>
            </>
        )
        setContent(
            numRows
        );

    }
    const closeTogglePopup = () => {
        setIsOpen(false);
    }

    function closepopup() {
        document.querySelector('.close-icons').click()
    }
    //second header functions


    const {
        register,
        handleSubmit,
        getValues,
        reset,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
    };


    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} >
                <ToolkitProvider
                    bootstrap4
                    keyField='companyName'
                    data={tableData}
                    columns={columns}
                    bordered={false}
                    defaultSorted={defaultSorted}
                    hover
                    exportCSV
                    search
                    columnToggle
                >
                    {
                        toolkitprops => (
                            <React.Fragment>
                                <div className="row second-header" >
                                    <div className="col-7 tittle-div">
                                        <Row>
                                            <Col md={8}><span className="tittle">{t('Company')}</span>
                                            </Col>
                                            <Col md={1}><span 
                                            // className="vertical-line"
                                            > </span>
                                            </Col>
                                        </Row>

                                    </div>
                                    

                                    <div className="col-5 button-div">
                                        <div className="search-input" id="searchInput" style={{ display: "none" }}>
                                            <SearchBar className="custome-search-field"
                                                delay={100}
                                                id="search-bar-0"
                                                placeholder={"Search"}
                                                {...toolkitprops.searchProps} />
                                        </div>
                                        <div className="M-icons">
                                            {/* <button className="addNew" onClick={event => handleFilter(event, 'searchInput')}><span class="material-symbols-outlined">search</span></button> */}
                                            
                                            {/* <Link to="/addCompany">
                                            <button className="addNew"><span class="material-symbols-outlined addR" id="add-icon">add_box</span></button>
                                            </Link> */}

                                            {/* popup  */}
                                            {isOpen && <Popup
                                                content={content}
                                                handleClose={closeTogglePopup}
                                                popupWidth={"50%"}
                                                popupHeight={"200px"}

                                            />}
                                            {/* popup  */}
                                            {/* <button className="addNew" id="" onClick={event => handleFilter(event, 'filter_alt')}><span class="material-symbols-outlined">filter_alt</span></button> */}
                                            <ul class="filter-li" id="filter_alt-child" style={{ right: "10px" }}>
                                                <li onClick={event => handleFilter(event, 'allData')}>All</li>
                                                <li onClick={event => handleFilter(event, 'active_filter')}>Active</li>
                                                <li onClick={event => handleFilter(event, 'inActive_filter')}>InActive</li>
                                            </ul>
                                        </div>
                                    </div>



                                </div>

                                <div className="row table-block">
                                    <div className="col-12" ref={ref}>

                                        <BootstrapTable
                                            bootstrap4
                                            hover
                                            noDataIndication={emptyDataMessage}
                                            headerClasses="tbl-line"
                                            classes='tbl-line'
                                            // selectRow={selectRow}
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                            {...toolkitprops.baseProps}
                                        />
                                    </div>

                                </div>
                                <div className="row" >
                                    <div className="col-5 ">
                                        <div className="table-result"><PaginationTotalStandalone
                                            {...paginationProps}
                                        /></div>
                                    </div>
                                    {/* <div className="col-4 table-col"></div> */}
                                    <div className="col-6">
                                        <nav data-pagination className="pagination-nav">
                                            <PaginationListStandalone {...paginationProps} />

                                        </nav>
                                    </div>
                                    <div className="col-1 table-col"></div>
                                </div>

                            </React.Fragment>
                        )
                    }
                </ToolkitProvider>
            </form>
        </div>
    );

    return (
        <div>
            <ToastContainer />
            <PaginationProvider
                pagination={
                    paginationFactory(options)
                }
            >
                {contentTable}
            </PaginationProvider>
        </div >
    );


}


export default CompanyList;