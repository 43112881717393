import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import React, { useEffect, useState } from "react";
import { getLanguage } from './api';
import translation from './translation';


  let translationData = translation();
  let resources = translationData.translationData;
  i18n.use(initReactI18next).init({
    resources,
    lng: sessionStorage.getItem("language")
      ? sessionStorage.getItem("language")
      : "fe",
  
    interpolation: {
      escapeValue: false,
    },
  });




export default i18n;
