import React, { useEffect, useState } from "react";
import { Form, FormLabel, InputGroup, Table } from 'react-bootstrap';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { set, useForm } from "react-hook-form"
import Popup from '../popup';
//import { getTableManagementData, updateTableData, addTableData } from '../../services/api';
import { updateCompanyData } from '../../services/api';
import { confirmAlert } from 'react-confirm-alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import moment from "moment";

//importing translation
import { useTranslation } from 'react-i18next';

//importing styles
import '../../css/table.css';
import '../../css/form.css';
import '../../css/admin.css';
import '../../css/business.css';

function ViewCompany() {
   // const [dateRange, setDateRange] = useState(new Date());

    const location = useLocation();
    let editCompanyData = location.state.data; 

    let workStartDateEDR
    if (editCompanyData.workStartDate != null && editCompanyData.workStartDate !=  "") {

        workStartDateEDR= moment(editCompanyData.workStartDate).format("DD/MM/YYYY")
    }else {
        workStartDateEDR = "DD/MM/YYYY"
    }
    const {
        register,
        handleSubmit,
        getValues,
        reset,
        watch,
        formState: { errors }
    } = useForm();

    const { t, i18n } = useTranslation();
    function formatDate(date) {
        console.log(date, "tesla")
        let mydate = new Date(date);
        let days = mydate.getDate();
        

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        let month = monthNames[mydate.getMonth()];
        let year = mydate.getFullYear();
        return month + " " + days + " " + year;
       
    };
    function formatFromDate(cell, row) {

        return (
            <>{formatDate(row.workStartDate)}
            </>
        );
    }
   



    return (
        <div className="row rect-row form-row" >

            <div className="row second-header" >
                <div className="col-7 tittle-div">
                    <Row>
                        <Col md={10}> <Link to="/"> <button  className="addNew" style={{ marginTop: "3px" }}><span class="material-symbols-outlined" title="clear" >arrow_back</span></button> </Link><span className="tittle">{t('View Company') +" - "+ editCompanyData.companyName}</span>
                        </Col>
                        <Col md={1}><span className="vertcal-line"> </span>
                        </Col>
                    </Row>

                </div>


                <div className="col-5 ">

                </div>
                <div className="col-1"></div>
                <div className="col-10 form-col">
                {/* <form
                       onSubmit={handleSubmit(onSubmitForm)}

                    > */}
                    <Row className="sub-header pb-2 pt-2 mb-2">

                    </Row>
                    <Row className="heading mb-2 mt-2">

                        <Row>
                            <Col md={1}></Col>
                            <Col md={5}>
                                <div className="fLeft" >
                               <label   style={{marginRight:'10px'}}> {t('Company Name')}:</label>
                               <label style={{fontWeight:'bold'}}> {editCompanyData.companyName}</label>
                               </div>
                                  </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('Point of Contact')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.pointOfContactUserName}</label>
                                </div>
                                 </Col>
                                 <Col md={1}></Col>
                            <Col md={5}>
                              <div className="fLeft" >
                               <label style={{marginRight:'10px'}} >{t('Form F-505 received completed with requested documentation')}:</label>
                               <label style={{fontWeight:'bold'}}>{editCompanyData.f505Path}</label>
                             </div>
                            </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                 <label  style={{marginRight:'10px'}}>Email:</label>
                                 <label style={{fontWeight:'bold'}}>{editCompanyData.pointOfContactEmail}</label>
                               </div>
                             </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('No Of Employees on Site')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.noOfEmployees}</label>
                                </div>
                               </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('HSE Manager Contact')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.hseManager}</label>
                                
                               </div>
                                </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('No of Project Batch')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.noOfProjectBatch}</label>
                                </div>
                                </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                <label  style={{marginRight:'10px'}}>{t('List of Vehicles')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.vehicleListPath}</label>
                                </div>
                            </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('Radioactive Sources')}:</label>
                                <label style={{fontWeight:'bold'}} >{editCompanyData.radioactiveSourcesPath} </label>
                             </div>
                                </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                                 <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('SUVA Hazard List')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.suvaActivitiesPath} </label>
                                </div>
                                </Col>
                                
                                <Col md={1}></Col>
                            <Col md={5}>
                             
                             <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('Business Manager Contact')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.businessManager} </label>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                            <label style={{marginRight:'10px'}}>{t('Oranization Chart of functions')}:</label>
                            <label style={{fontWeight:'bold'}}>{editCompanyData.orgFunctionPath} </label>
                            </div>
                            </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('Business Risk Analysis')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.businessRiskPath}</label>
                                </div>
                                </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('On-site Manager Contact')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.onSiteManager} </label>
                                </div>
                            </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                             
                             <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('Work Start Date')}:</label>
                                <label style={{fontWeight:'bold'}} title="DD/MM/YYYY">{workStartDateEDR}</label>
                                
                            </div>
                                </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('Lifting Tool Test Certificate')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.liftingToolCertificatePath} </label>

                                </div>
                            </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                               <label style={{marginRight:'10px'}}>{t('Sub-Contract Company Name')}:</label>
                               <label style={{fontWeight:'bold'}}>{editCompanyData.subContractingCompanyName}</label>
                             </div>
                           
                            
                                </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('PO Number')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.poNumber}</label>
                                </div>
                             </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('Sub-Contractor Company POC')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.subContractingCompanyPOCName}</label>
                                </div>
                            </Col>
                                <Col md={1}></Col>
                            <Col md={5}>
                            <div className="fLeft" >
                                <label style={{marginRight:'10px'}}>{t('Sub-Contractor POC Email')}:</label>
                                <label style={{fontWeight:'bold'}}>{editCompanyData.subContractingCompanyPOCEmail}</label>
                                </div>
          
                            </Col>
                        </Row>




                    </Row>
                   
                </div>

            </div>
            <ToastContainer />

        </div >
    );
}


export default ViewCompany;