import React, { useEffect, useState } from "react";
import { Form, Dropdown, FormLabel, InputGroup, Table } from "react-bootstrap";
import { Link, useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Button, Row, Col, Container } from "react-bootstrap";
import { set, useForm } from "react-hook-form";
import Popup from "../popup";
import { addVisitorsData, getAvailableSkills, getAvailableFunctions, submitEditRegistration } from '../../services/api';
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import FileResizer from "react-image-file-resizer";
import { useMsal } from "@azure/msal-react";
import moment from "moment";

//importing translation
import { useTranslation } from "react-i18next";

//importing styles
import "../../css/table.css";
import "../../css/form.css";
import "../../css/admin.css";
import "../../css/business.css";
const CryptoJS = require("crypto-js");

let header = "";
function RegistrationAdd() {
  const { t, i18n } = useTranslation();
  // const location = useLocation();
  //let editRegistrationData = location.state.data; 
  const { instance } = useMsal();
  const [selectedHours, setSelectedHours] = useState('Normal Working Hours')
  const [selectedRole, setSelectedRole] = useState('Worker')
  const [selectedFunction, setSelectedFunction] = useState('')

  const [availableFunctions, setAvailableFunctions] = useState([]);
  const [availableFunctionsWorker, setAvailableFunctionsWorker] = useState([]);
  const [file, setFile] = useState(null);
  const [photoFile, setPhotoFile] = useState("")
  const [govtIdFile, setGovtIdFile] = useState("")
  const [employeeCvFile, setemployeeCvFile] = useState("")
  const [dobDate, setDobDate] = useState();
  const [workStartDate, setWorkStartDate] = useState();
  const [yearsOfExpDate, setYearsOfExpDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [proofOfAuthFile, setproofOfAuthFile] = useState("")
  const [proofOfExSituFile, setproofOfExSituFile] = useState("")
  const [proofOfMFile, setproofOfMFile] = useState("")
  const [proofOfSCBAFile, setproofOfSCBAFile] = useState("")
  const [proofOfATEXFile, setproofOfATEXFile] = useState("")
  const [proofOfHarnessFile, setproofOfHarnessFile] = useState("")
  const [proofOfN1File, setproofOfN1File] = useState("")
  const [allSkills, setAllSkills] = useState([])
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [empId, setEmpId] = useState(0);
  const [n1RiskfileUpload, setN1RiskfileUpload] = useState(true)
  const [atexUpload, setAtexUpload] = useState(true)
  const [scbaUpload, setSCBAUpload] = useState(true)
  const [harnessUpload, setHarnessUpload] = useState(true)

  const encryptKey = 'vmsuat1234';
  let ssCompanyId = sessionStorage.getItem('companyId')

  let ssCompanyName = sessionStorage.getItem('companyName');
  const navigate = useNavigate();
  const functionOptions = selectedRole === 'Worker'
    ? availableFunctionsWorker
    : availableFunctions;
  const getAvlFunction = (locationId) => {
    let mounted = true;
    let culture = 'fr-CH'
    if (i18n.language == 'en') {
      culture = 'en-US'
    }
    if (i18n.language == 'de') {
      culture = 'de-DE'
    }

    getAvailableFunctions(1, culture, instance)
      .then(items => {
        if (mounted) {
          setAvailableFunctions(items.managerFunctions);
          setAvailableFunctionsWorker(items.workerFunctions);
          return () => mounted = false;
        }


      })
  }
  const getAllSkills = (locationId) => {
    let mounted = true;
    let culture = 'fr-CH'
    if (i18n.language == 'en') {
      culture = 'en-US'
    }
    if (i18n.language == 'de') {
      culture = 'de-DE'
    }

    getAvailableSkills(1, culture, instance)

      .then(items => {
        if (mounted) {
          setAllSkills(items);
          //setAvailableFunctionsWorker(items.Collaborator);
          return () => mounted = false;
        }


      })
  }

  const handleSelectChange = (e, index, selectedId) => {
    const value = e.target.value;
    if (index == "N1") {
      setN1RiskfileUpload(value === 'Yes' ? false : true);
    } else if (index == "atex") {

      setAtexUpload(value === 'Yes' ? false : true);
    } else if (index == "scba") {

      setSCBAUpload(value === 'Yes' ? false : true);
    } else if (index == "harness") {

      setHarnessUpload(value === 'Yes' ? false : true);
    }

    if (value === "Yes") {
      document.getElementById(selectedId + 'fileinput').disabled = false;
      document.getElementById(selectedId + 'fileup').style.background = "none";
      // document.getElementById(selectedId + 'file-icon').style.background = "block";

    } else if (value === "No") {
      document.getElementById(selectedId + 'fileinput').disabled = true;
      document.getElementById(selectedId + 'fileup').style.background = "#e9ecef";
      document.getElementById(selectedId + 'fileinput').value = ""
      //  document.getElementById(selectedId + 'file-icon').style.background = "none";
    }
    if (value === "No" && proofOfN1File != "" && index == "N1") {
      setproofOfN1File("")
    } else if (value === "No" && proofOfATEXFile != "" && index == "atex") {
      setproofOfATEXFile("")
    } else if (value === "No" && proofOfSCBAFile != "" && index == "scba") {
      setproofOfSCBAFile("")
    } else if (value === "No" && proofOfHarnessFile != "" && index == "harness") {
      setproofOfHarnessFile("")
    }
  }


  const handleSelect = (selectedId, value, skillList, skillName) => {
    if (value == "Yes") {
      document.getElementById(selectedId + 'fileinput').disabled = false;
      document.getElementById(selectedId + 'fileup').style.background = "none";
      // document.getElementById(selectedId + 'file-icon').style.background = "block";

    } else if (value === "No") {
      document.getElementById(selectedId + 'fileinput').disabled = true;
      document.getElementById(selectedId + 'fileup').style.background = "#e9ecef";
      document.getElementById(selectedId + 'fileinput').value = ""
      //  document.getElementById(selectedId + 'file-icon').style.background = "none";

    }
    // const updatedSkills = selectedSkills.map(skill =>
    //   skill.id === selectedId ? { ...skill, isSelected: value === 'Yes' } : skill
    // );
    // setSelectedSkills({...updatedSkills});
    if (value === "Yes") {
      let skillDetails = [{
        "visitorSkillTypeId": selectedId,
        "skillType": skillName,
        // "documentPath": "Lift Driver License Path",
        // "documentPathEncoded":"liftDriver.pdf"
      }]
      setSelectedSkills(selectedSkills.concat(skillDetails))
    }
    if (value === "No") {

      const updatedData = selectedSkills.filter((item) => item.visitorSkillTypeId !== selectedId);
      setSelectedSkills(updatedData);

    }

  }

  const resizeFile = (file) => new Promise(resolve => {
    // const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
    const allowedTypes = ['PDF', 'JPEG', 'JPG', 'PNG'];

    FileResizer.imageFileResizer(file, 600, 600, allowedTypes, 100, 0,
      uri => {
        resolve(uri);
      }, 'base64');
  });
  const resizePhoto = (file) => new Promise(resolve => {
    FileResizer.imageFileResizer(file, 480, 640, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'base64');
  });

  const handleFileChange = async (e, docName) => {
    const selectedFile = e.target.files[0];
    // mime check 
    var fileReader = new FileReader();
    fileReader.onloadend = async function (event) {
      var arr = (new Uint8Array(event.target.result)).subarray(0, 4);

      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      console.log(header, "header in fun");
      // Check the file signature against known types
      // let type;
      // switch (header) {
      //   case "89504e47":
      //   case "64617461":
      //     type = "image/png";
      //     header = ""
      //     break;
      //   case "25504446":
      //   case "255044462D":
      //     type = "application/pdf";
      //     header = ""
      //     break;
      //   case "ffd8ffe0":
      //   case "ffd8ffe1":
      //   case "ffd8ffe2":
      //   case "ffd8ffe3":
      //   case "ffd8ffe8":
      //     type = "image/jpeg";
      //     header = ""
      //     break;
      //   default:
      //     type = "unknown"; // Or you can use the blob.type as fallback
      //     toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
      //     e.target.value = null;
      //     header = ""
      //     break;
      // }

      const allowedTypes = ['89504e47', '64617461', '25504446', '255044462D', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'];
      if (!allowedTypes.includes(header)) {
        header = "";
        toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
        e.target.value = null;
        return;
      }
      const selectedFile = e.target.files[0];
      if (allowedTypes.includes(header)) {
        header = "";
        if (selectedFile) {


          // const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
          // if (!allowedTypes.includes(selectedFile.type)) {
          //   toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
          //   e.target.value = null;
          //   return;
          // }
          const maxSizeInBytes = 4 * 1024 * 1024;
          const maxNormalFileSizeInByte = 1024 * 1024
          if (selectedFile.size > maxSizeInBytes && e.target.files[0].type != 'application/pdf') {
            toast.error(t('File size exceeds 4 MB limit.'))
            e.target.value = null;
            return;
          }
          if (docName === "photoPath" && e.target.files[0].type != 'image/jpeg') {
            toast.error(t('Please upload only .jpg format.'))
            e.target.value = null;
          }

          let base64Content
          if (selectedFile.size > maxNormalFileSizeInByte && e.target.files[0].type != 'application/pdf') {
            console.log("if")
            const file = e.target.files[0];
            // const documentData = await resizeFile(file);
            let documentData
            if (e.target.name === "photoPath") {
              documentData = await resizePhoto(file)
            } else {
              documentData = await resizeFile(file);
            }

            // Photo width & height validation
            // if (e.target.name === "photoPath") {
            //   const image = new Image();
            //   image.src = documentData;

            //   image.onload = function () {
            //     const width = this.width;
            //     const height = this.height;
            //     // setImageDimensions({ width, height });

            //     if (width > 480 || height > 640) {
            //       toast.error(t('Please upload an image with equal to or less than 480px width and 640px height.'));
            //       e.target.value = null;
            //       return;
            //     }
            //   };
            // }
            // Photo width & height validation end 

            base64Content = documentData.split(',')[1];

            setFile(selectedFile);
            if (e.target.name === "photoPath") {
              setPhotoFile(base64Content);
            } else if (e.target.name === "govtIdPath") {
              setGovtIdFile(base64Content);
            } else if (e.target.name === "employeeCVPath") {
              setemployeeCvFile(base64Content);
            } else if (e.target.name === "proofOfAuthorization") {
              setproofOfAuthFile(base64Content);
            } else if (e.target.name === "proofOfExSituTrainingCompletedPath") {
              setproofOfExSituFile(base64Content);
            } else if (e.target.name === "proofOfMedicalClearance") {
              setproofOfMFile(base64Content);
            } else if (e.target.name === "proofOfSCBAMaskAuthPath") {
              setproofOfSCBAFile(base64Content);
            } else if (e.target.name === "proofOfATEXAuthPath") {
              setproofOfATEXFile(base64Content);
            } else if (e.target.name === "proofOfHarnessAuthPath") {
              setproofOfHarnessFile(base64Content);
            } else if (e.target.name === "proofOfN1ChemicalRiskAuthPath") {
              setproofOfN1File(base64Content);
            }

          } else {
            const maxPdfFileSizeInByte = 1.5 * 1024 * 1024
            if (selectedFile.size > maxPdfFileSizeInByte && e.target.files[0].type === 'application/pdf') {
              toast.error(t('File size exceeds 1.5 MB limit.'))
              e.target.value = null;
              return;
            }

            const file = e.target.files[0];
            let documentData
            if (e.target.name === "photoPath") {
              documentData = await resizePhoto(file)
            }
            const reader = new FileReader();
            reader.onload = (event) => {

              // Photo width & height validation
              // if (e.target.name === "photoPath") {
              //   const image = new Image();
              //   image.src = event.target.result;;

              //   image.onload = function () {
              //     const width = this.width;
              //     const height = this.height;
              //     // setImageDimensions({ width, height });

              //     if (width > 480 || height > 640) {
              //       toast.error(t('Please upload an image with equal to or less than 480px width and 640px height.'));
              //       e.target.value = null;
              //       return;
              //     }
              //   };
              // }
              // Photo width & height validation end 
              // base64Content = event.target.result.split(',')[1];
              let base64Content;
              if (e.target.name === "photoPath") {
                base64Content = documentData.split(',')[1];
              } else {
                base64Content = event.target.result.split(',')[1];
              }

              setFile(selectedFile);
              if (e.target.name === "photoPath") {
                setPhotoFile(base64Content);
              } else if (e.target.name === "govtIdPath") {
                setGovtIdFile(base64Content);
              } else if (e.target.name === "employeeCVPath") {
                setemployeeCvFile(base64Content);
              } else if (e.target.name === "proofOfAuthorization") {
                setproofOfAuthFile(base64Content);
              } else if (e.target.name === "proofOfExSituTrainingCompletedPath") {
                setproofOfExSituFile(base64Content);
              } else if (e.target.name === "proofOfMedicalClearance") {
                setproofOfMFile(base64Content);
              } else if (e.target.name === "proofOfSCBAMaskAuthPath") {
                setproofOfSCBAFile(base64Content);
              } else if (e.target.name === "proofOfATEXAuthPath") {
                setproofOfATEXFile(base64Content);
              } else if (e.target.name === "proofOfHarnessAuthPath") {
                setproofOfHarnessFile(base64Content);
              } else if (e.target.name === "proofOfN1ChemicalRiskAuthPath") {
                setproofOfN1File(base64Content);
              }


            };
            reader.readAsDataURL(selectedFile)
          }

        }
      }


    };
    fileReader.readAsArrayBuffer(selectedFile);
  }
  const handleFileChangeDynamic = async (e, id) => {
    const selectedFile = e.target.files[0];
    // mime check 
    var fileReader = new FileReader();
    fileReader.onloadend = async function (event) {
      var arr = (new Uint8Array(event.target.result)).subarray(0, 4);

      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      console.log(header, "header in fun");
      // Check the file signature against known types
      const allowedTypes = ['89504e47', '64617461', '25504446', '255044462D', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'];
      if (!allowedTypes.includes(header)) {
        header = "";
        toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
        e.target.value = null;
        return;
      }
      const selectedFile = e.target.files[0];
      if (allowedTypes.includes(header)) {
        header = "";

        if (selectedFile) {

          // const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
          // if (!allowedTypes.includes(selectedFile.type)) {
          //   toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
          //   e.target.value = null;
          //   return;
          // }
          const maxSizeInBytes = 4 * 1024 * 1024;
          const maxNormalFileSizeInByte = 1024 * 1024
          if (selectedFile.size > maxSizeInBytes && e.target.files[0].type != 'application/pdf') {
            toast.error(t('File size exceeds 4 MB limit.'))
            e.target.value = null;
            return;
          }
          let base64Content
          if (selectedFile.size > maxNormalFileSizeInByte && e.target.files[0].type != 'application/pdf') {
            console.log("if")

            const file = e.target.files[0];
            const documentData = await resizeFile(file);
            base64Content = documentData.split(',')[1];

            document.getElementById(id + 'fileinput').style.opacity = null
            setFile(selectedFile);
            let skillsArray = selectedSkills
            selectedSkills.forEach((element, elementId) => {
              if (element.visitorSkillTypeId == id) {
                skillsArray[elementId].documentPathEncoded = CryptoJS.AES.encrypt(base64Content, encryptKey).toString()
                skillsArray[elementId].documentPath = selectedFile.name
                setSelectedSkills(skillsArray)
              }
            });

          } else {
            const maxPdfFileSizeInByte = 1.5 * 1024 * 1024
            if (selectedFile.size > maxPdfFileSizeInByte && e.target.files[0].type === 'application/pdf') {
              toast.error(t('File size exceeds 1.5 MB limit.'))
              e.target.value = null;
              return;
            }
            console.log("else")
            const reader = new FileReader();
            reader.onload = (event) => {

              base64Content = event.target.result.split(',')[1];
              document.getElementById(id + 'fileinput').style.opacity = null
              setFile(selectedFile);
              let skillsArray = selectedSkills
              selectedSkills.forEach((element, elementId) => {
                if (element.visitorSkillTypeId == id) {
                  skillsArray[elementId].documentPathEncoded = CryptoJS.AES.encrypt(base64Content, encryptKey).toString()
                  skillsArray[elementId].documentPath = selectedFile.name
                  setSelectedSkills(skillsArray)
                }
              });

            };
            reader.readAsDataURL(selectedFile)
          }

        }
      }



    };
    fileReader.readAsArrayBuffer(selectedFile);
  }

  const handleChangeSelect = event => {
    console.log(event.target.value);
    setSelectedRole(event.target.value);
    if (selectedRole != "Manager") {
      toast.info(t("Please Select The Function !"));
    }
  };




  useEffect(() => {
    registerLocale("en-GB", enGB)
    getAllSkills();
    getAvlFunction();

  }, [])
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors }
  } = useForm();
  const onSubmitForm = (data, e) => {
    e.preventDefault();
    let encryptDob
    if (dobDate != null && dobDate != "") {
      encryptDob = JSON.stringify(dobDate)
      encryptDob = CryptoJS.AES.encrypt(encryptDob, encryptKey).toString()
    } else {
      encryptDob = null
    }
    let encryptAuthStartDate
    if (startDate != null && startDate != "") {
      encryptAuthStartDate = JSON.stringify(startDate)
      encryptAuthStartDate = CryptoJS.AES.encrypt(encryptAuthStartDate, encryptKey).toString()
    } else {
      encryptAuthStartDate = null
    }
    let encryptEndDate
    if (endDate != null && endDate != "") {
      encryptEndDate = JSON.stringify(endDate)
      encryptEndDate = CryptoJS.AES.encrypt(encryptEndDate, encryptKey).toString()
    } else {
      encryptEndDate = null
    }
    let enYearsOfExpDate
    if (yearsOfExpDate != null && yearsOfExpDate != "") {
      enYearsOfExpDate = moment(yearsOfExpDate).format("MM-DD-YYYY HH:mm:ss");
      enYearsOfExpDate = CryptoJS.AES.encrypt(enYearsOfExpDate, encryptKey).toString()
    } else {
      enYearsOfExpDate = null
    }

    let gId = null;
    if (data["govtId"]) {
      const key = 'visitormsuat1234';
      const keyutf = CryptoJS.enc.Utf8.parse(key);
      const iv = CryptoJS.enc.Base64.parse(key);
      const enc = CryptoJS.AES.encrypt(data["govtId"], keyutf, { iv: iv });
      gId = enc.toString();
    }
    let workStdate
    if (workStartDate != null && workStartDate != "") {
      workStdate = moment(workStartDate).format("YYYY-MM-DD")
    } else {
      workStdate = null
    }

    //  photoPath jpeg to jpg name convertion
    let renamePhotoPath
    if (data.photoPath[0]?.name != "" && data.photoPath[0]?.name != undefined) {
      const originalPhotoPath = data.photoPath[0]?.name
      const dotIndex = originalPhotoPath.lastIndexOf('.');
      const fileNameWithoutExtension = originalPhotoPath.slice(0, dotIndex);
      renamePhotoPath = fileNameWithoutExtension + '.jpg';
    } else {
      renamePhotoPath = ""
    }

    //  photoPath jpeg to jpg name convertion end 

    let Data = {
      "companyId": parseInt(ssCompanyId),
      "dateOfBirth": (dobDate != null && dobDate != "") ? encryptDob : null,
      "firstName": data["firstName"],
      "lastName": data["lastName"],
      "locationId": 1,
      "govtId": data["govtId"] ? gId : "",
      "visitorFunctionId": data["visitorFunctionId"] ? parseInt(data["visitorFunctionId"]) : 3,
      "isManager": selectedRole == "Manager" ? true : false,
      "visitorContractorTypeId": parseInt(data["visitorContractorTypeId"]),
      "approvalStatusId": 1,
      "shiftId": selectedHours === "Normal Working Hours" ? 1 : 2,
      "varoPointOfContact": data["varoPointOfContact"],
      "workStartDate": (workStartDate != null && workStartDate != "") ? workStdate : null,
      "languageKnownId": parseInt(data["languageKnownId"]),
      "authCHStartDate": (startDate != null && startDate != "") ? encryptAuthStartDate : null,
      "authCHEndDate": (endDate != null && endDate != "") ? encryptEndDate : null,
      "yearsOfExperience": (yearsOfExpDate != null && yearsOfExpDate != "") ? enYearsOfExpDate : null,
      "skills": selectedSkills,
      "photoPath": data.photoPath && data.photoPath[0] ? renamePhotoPath : null,
      "photoEncoded": photoFile ? CryptoJS.AES.encrypt(photoFile, encryptKey).toString() : null,
      "govtIdPath": data.govtIdPath && data.govtIdPath[0] ? data.govtIdPath[0].name : null,
      "govtIdEncoded": govtIdFile ? CryptoJS.AES.encrypt(govtIdFile, encryptKey).toString() : "",
      "employeeCVPath": selectedRole == 'Worker' ? null : (data.employeeCVPath && data.employeeCVPath[0] ? data.employeeCVPath[0].name : null),
      "employeeCVEncoded": selectedRole == 'Worker' ? null : (employeeCvFile ? CryptoJS.AES.encrypt(employeeCvFile, encryptKey).toString() : null),
      "proofOfAuthorization": data.proofOfAuthorization && data.proofOfAuthorization[0] ? data.proofOfAuthorization[0].name : null,
      "proofOfAuthorizationEncoded": proofOfAuthFile ? CryptoJS.AES.encrypt(proofOfAuthFile, encryptKey).toString() : null,
      "proofOfExSituTrainingCompletedPath": data.proofOfExSituTrainingCompletedPath && data.proofOfExSituTrainingCompletedPath[0] ? data.proofOfExSituTrainingCompletedPath[0].name : null,
      "ProofOfExSituTrainingCompletedEncoded": proofOfExSituFile ? CryptoJS.AES.encrypt(proofOfExSituFile, encryptKey).toString() : null,
      "proofOfMedicalClearance": data.proofOfMedicalClearance && data.proofOfMedicalClearance[0] ? data.proofOfMedicalClearance[0].name : null,
      "proofOfMedicalClearanceEncoded": proofOfMFile ? CryptoJS.AES.encrypt(proofOfMFile, encryptKey).toString() : null,
      "proofOfSCBAMaskAuthPath": data.proofOfSCBAMaskAuthPath && data.proofOfSCBAMaskAuthPath[0] ? data.proofOfSCBAMaskAuthPath[0].name : null,
      "proofOfSCBAMaskAuthEncoded": proofOfSCBAFile ? CryptoJS.AES.encrypt(proofOfSCBAFile, encryptKey).toString() : null,
      "proofOfATEXAuthPath": data.proofOfATEXAuthPath && data.proofOfATEXAuthPath[0] ? data.proofOfATEXAuthPath[0].name : '',
      "proofOfATEXAuthEncoded": proofOfATEXFile ? CryptoJS.AES.encrypt(proofOfATEXFile, encryptKey).toString() : null,
      "proofOfHarnessAuthPath": data.proofOfHarnessAuthPath && data.proofOfHarnessAuthPath[0] ? data.proofOfHarnessAuthPath[0].name : null,
      "proofOfHarnessAuthEncoded": proofOfHarnessFile ? CryptoJS.AES.encrypt(proofOfHarnessFile, encryptKey).toString() : null,
      "proofOfN1ChemicalRiskAuthPath": data.proofOfN1ChemicalRiskAuthPath && data.proofOfN1ChemicalRiskAuthPath[0] ? data.proofOfN1ChemicalRiskAuthPath[0].name : null,
      "proofOfN1ChemicalRiskAuthEncoded": proofOfN1File ? CryptoJS.AES.encrypt(proofOfN1File, encryptKey).toString() : null,
    }
    onSaveClick(Data);
  }
  const onSaveClick = (data) => {
    addFunc(data);
  };

  const addFunc = (data) => {
    let culture = 'fr-CH'
    if (i18n.language == 'en') {
      culture = 'en-US'
    }
    if (i18n.language == 'de') {
      culture = 'de-DE'
    }
    addVisitorsData(data, culture, instance)
      .then(data => {
        if (data['status'] === 404) {
          toast.error(<p style={{ color: "red" }}>HTTP Error 404 : Not Found</p>)
        } else if (data['status'] === 500) {
          toast.error(<p style={{ color: "red" }}> {data['detail']}</p>)
        } else if (data.successMessage == null) {

          toast.error(<p style={{ color: "red" }}> {data.errorMessage}</p>)
        } else {
          setEmpId(data.employeeId)
          toast.success(data.successMessage);
          setTimeout(() => navigate('/registerList'), 2000);

        }
      })
  }

  // const onSubmitClick = (data) => {
  //   let culture = 'fr-CH'
  //   if (i18n.language == 'en') {
  //     culture = 'en-US'
  //   }
  //   if (i18n.language == 'de') {
  //     culture = 'de-DE'
  //   }
  //   console.log(data, "submitBtn")
  //   submitEditRegistration(empId, culture, instance)
  //     .then(data => {
  //       if (data['status'] === 404) {
  //         toast.error(<p style={{ color: "red" }}>HTTP Error 404 : Not Found</p>)
  //       } else if (data['status'] === 500) {
  //         toast.error(<p style={{ color: "red" }}> {data['detail']}</p>)
  //       } else if (data['name'] == "SuccessMessage") {
  //         toast.success(data['value']);
  //         //  getTableDataFunc();
  //       } else if (data) {
  //         toast.error(<p style={{ color: "red" }}> {data}</p>)

  //       } else {
  //         toast.error(<p style={{ color: "red" }}> Unknown error Occurred!</p>)

  //       }
  //     })

  // };
  return (
    <div className="row rect-row form-row">
      <div className="row second-header">
        <div className="col-5 tittle-div">
          <Row>
            <Col md={8}>
              {" "}
              <Link to="/registerList">
                {" "}
                <button className="addNew" style={{ marginTop: "3px" }}>
                  <span class="material-symbols-outlined" title="clear">
                    arrow_back
                  </span>
                </button>{" "}
              </Link>
              <span className="tittle">{t("Add Employee")}</span>
            </Col>
            <Col md={1}>
              <span className="vertcal-line"> </span>
            </Col>
          </Row>
        </div>

        {/* <div className="col-7 button-div"></div>
        <div className="col-1"></div> */}
        <div className="col-10 form-col edit-form">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Row className="sub-header pb-2 pt-2 mb-2"></Row>
            <Row className="heading mb-2 mt-2">
              <Row>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft"> {t("Company Name")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <InputGroup>
                      <Form.Control
                        placeholder={t("Enter Company Name")}
                        aria-label="Company Name "
                        aria-describedby="basic-addon1"
                        disabled
                        className=""
                        {...register("CompanyName")}
                        defaultValue={ssCompanyName}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("EmployeeFname​​")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <InputGroup>
                      <Form.Control
                        placeholder={t("EmployeeFname​​")}
                        aria-label="Name "
                        aria-describedby="basic-addon1"
                        className=""
                        {...register("firstName")}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Dateob​")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <div className="calendar">
                      <DatePicker
                        className="selectpickers"
                        // selectsRange={false}
                        // startDate={startDate}
                        // endDate={endDate}
                        showYearDropdown
                        selected={dobDate}
                        onChange={(date) => {
                          setDobDate(date)
                        }
                        }
                        dateFormat="dd/MM/yyyy"
                        isClearable={true}
                        maxDate={new Date("01/01/2009")}
                      //{...register("WorkStartDate")}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Employee Last Name")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <InputGroup>
                      <Form.Control
                        placeholder={t("Employee Last Name")}
                        aria-label="FirstName "
                        aria-describedby="basic-addon1"
                        className=""
                        {...register("lastName")}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Govt ID")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <InputGroup>
                      <Form.Control
                        placeholder={t("Govt ID")}
                        aria-label="GovtID"
                        aria-describedby="basic-addon1"
                        className=""
                        {...register("govtId")}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Proof of Govt ID")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <input
                          type="file"
                          id="file-upload"
                          className="Upload-excel-input"
                          {...register("govtIdPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: govtIdFile ? null : 0 }} onChange={(e) => handleFileChange(e)}
                        />
                        <input type="hidden"
                          {...register("govtIdPathEncoded")}
                          value={govtIdFile} />
                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}

                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("ContactVARO​")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <InputGroup>
                      <Form.Control
                        placeholder={t("ContactVARO​")}
                        aria-label="Name "
                        aria-describedby="basic-addon1"
                        className=""
                        {...register("varoPointOfContact")}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Photo")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <input
                          type="file"
                          id="file-upload"
                          className="Upload-excel-input"
                          {...register("photoPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: photoFile ? null : 0 }}
                          onChange={(e) => handleFileChange(e, "photoPath")}
                        />
                        <input type="hidden"
                          {...register("photoEncoded")}
                          value={photoFile} />

                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Work Start Date")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <div className="calendar">
                      <DatePicker
                        className="selectpickers"
                        // selectsRange={false}
                        // startDate={startDate}
                        // endDate={endDate}
                        showYearDropdown
                        selected={workStartDate}
                        onChange={(date) => {
                          setWorkStartDate(date)
                        }
                        }
                        dateFormat="dd/MM/yyyy"
                        isClearable={true}
                        minDate={new Date()}
                      //{...register("WorkStartDate")}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Type of Contract")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <select className="heading mb-2 mt-2  selectpicker"
                          {...register("visitorContractorTypeId")}
                        >
                          <option>{t("Select")}</option>
                          <option value={1}>CDI</option>
                          <option value={2}>CDD</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <label className="fLeft">{t("Role")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                      <div style={{ display: 'flex', gap: '20px' }}>
                        <input type="radio" id="workerRadio" name="role" value="Worker" checked={selectedRole === 'Worker'}
                          // onChange={() => setSelectedRole('Worker')} 
                          onChange={handleChangeSelect}
                        />
                        <label htmlFor="workerRadio">{t("Worker")}</label>


                        <input type="radio" id="managerRadio" name="role" value="Manager" checked={selectedRole === 'Manager'}
                          // onChange={() => setSelectedRole('Manager')}
                          onChange={handleChangeSelect}
                        />
                        <label htmlFor="managerRadio">{t("Manager")}</label>
                      </div>

                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <label className="fLeft">{t("Work Shift")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                      <div style={{ display: 'flex', gap: '20px' }}>
                        <input type="radio" id="normalShift" name="shift" value="Normal Working Hours" checked={selectedHours === 'Normal Working Hours'}
                          onChange={() => setSelectedHours('Normal Working Hours')}
                        />
                        <label htmlFor="normalalRadio">{t("Normal Working Hours")}</label>


                        <input type="radio" id="workerShift" name="shift" value="Shift Working Hours" checked={selectedHours === 'Shift Working Hours'}
                          onChange={() => setSelectedHours('Shift Working Hours')}

                        />
                        <label htmlFor="workerRadio">{t("Shift Working Hours")}</label>

                      </div>

                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Function")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>

                      <div className="popup-Dropdown">
                        <select className="heading mb-2 mt-2  selectpicker"
                          //value={selectedFunction}
                          onChange={(e) => {

                            setSelectedFunction(e.target.value);


                          }}

                          {...register("visitorFunctionId")}

                        // disabled={selectedRole==='Worker'}
                        >


                          {/* {selectedRole != 'Worker' ? <option value="Select">{t("Select")}</option> : <></>} */}
                          {selectedRole === 'Manager' ? <option value="" selected={selectedRole === 'Manager'}>{t("Select")}</option> : <></>}
                          {functionOptions.map((option, index) => (

                            <option value={option.id}>

                              {t(option.functionName)

                              }
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Employee CV")}:<span style={{ color: 'red', marginLeft: '2px' }} >{selectedRole === 'Manager' ? '*' : ''}</span></label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown" style={{ background: (selectedRole === 'Worker') ? "#e9ecef" : "white" }}>
                        <input
                          type="file"
                          id="file-upload"
                          disabled={selectedRole === 'Worker'}
                          className="Upload-excel-input"
                          {...register("employeeCVPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: employeeCvFile ? null : 0 }} onChange={(e) => handleFileChange(e)}
                        />
                        <input type="hidden"
                          {...register("employeeCVPathEncoded")}
                          value={employeeCvFile} />
                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Language")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <select className="heading mb-2 mt-2  selectpicker"
                          {...register("languageKnownId")}
                        >
                          <option value={1}>French</option>
                          <option value={2}>English</option>
                          <option value={3}>German</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Ex-Situ training questionnaire completed and signed")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <input
                          type="file"
                          id="file-upload"
                          className="Upload-excel-input"
                          {...register("proofOfExSituTrainingCompletedPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: proofOfExSituFile ? null : 0 }} onChange={(e) => handleFileChange(e)}

                        />
                        <input type="hidden"
                          {...register("ProofOfExSituTrainingCompletedEncoded")}
                          value={proofOfExSituFile} />

                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("ProofMedicalClearance")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <input
                          type="file"
                          id="file-upload"
                          className="Upload-excel-input"
                          {...register("proofOfMedicalClearance")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: proofOfMFile ? null : 0 }} onChange={(e) => handleFileChange(e)}

                        />
                        <input type="hidden"
                          {...register("proofOfMedicalClearanceEncoded")}
                          value={proofOfMFile} />

                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Years of experience")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </label>
                  <div className="popup-input mt-2">
                    <div className="calendar">
                      <DatePicker
                        className="selectpickers"
                        // selectsRange={false}
                        // startDate={startDate}
                        // endDate={endDate}
                        selected={yearsOfExpDate}
                        showYearDropdown
                        onChange={(date) => {
                          setYearsOfExpDate(date)
                        }
                        }
                        dateFormat="dd/MM/yyyy"
                        isClearable={true}
                        maxDate={new Date()}
                      //{...register("WorkStartDate")}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={12}>
                  <div class="panel panel-default centre" style={{ fontWeight: 'bold' }}>
                    <div class="panel-body">{t("Authorization to work in Switzerland")}:</div>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Start Date")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </label>
                  <div className="popup-input mt-2">
                    <div className="calendar" >
                      <DatePicker
                        className="selectpickers"
                        // selectsRange={false}
                        // startDate={startDate}
                        // endDate={endDate}
                        selected={startDate}
                        showYearDropdown
                        onChange={(date) => {
                          setStartDate(date)
                          setEndDate()
                        }
                        }
                        dateFormat="dd/MM/yyyy"
                        isClearable={true}
                      //  maxDate={new Date()}
                      //{...register("WorkStartDate")}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("End Date")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </label>
                  <div className="popup-input mt-2">
                    <div className="calendar">
                      <DatePicker
                        className="selectpickers"
                        // selectsRange={false}
                        // startDate={startDate}
                        // endDate={endDate}

                        // disabled={startDate !=null}
                        disabled={startDate == null}
                        selected={endDate}
                        showYearDropdown
                        onChange={(date) => {
                          setEndDate(date)
                        }
                        }
                        dateFormat="dd/MM/yyyy"
                        isClearable={true}
                        minDate={new Date(startDate)}
                      //{...register("WorkStartDate")}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Proof of Authorization")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <input
                          type="file"
                          id="file-upload"
                          className="Upload-excel-input"
                          {...register("proofOfAuthorization")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: proofOfAuthFile ? null : 0 }} onChange={(e) => handleFileChange(e)}
                        />

                        <input type="hidden"
                          {...register("proofOfAuthorizationEncoded")}
                          value={proofOfAuthFile} />
                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {/* {editRegistrationData.proofOfAuthorization &&
                          <span title={editRegistrationData.proofOfAuthorization} className="material-symbols-outlined" >
                            attach_file
                          </span>
                        } */}
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={1}></Col>
                <Col md={5}>

                  <Col md={12}>
                  </Col>

                </Col>


                <div style={{ height: '15px' }}></div>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Minimum N1 chemical risk authorization or equivalent")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <select className="heading mb-2 mt-2  selectpicker" id="N1RiskAuth" onChange={(e, index) => handleSelectChange(e, "N1", 101)}>
                          <option value={'Yes'}  >{t("Yes")}</option>
                          <option value={'No'} selected >{t("No")}</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Proof of N1 chemical risk clearance​")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown" id="101fileup" style={{ background: n1RiskfileUpload ? "#e9ecef" : 'white' }} >
                        <input
                          type="file"
                          id="101fileinput"
                          className="Upload-excel-input"
                          {...register("proofOfN1ChemicalRiskAuthPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: proofOfN1File ? null : 0 }}
                          onChange={(e) => handleFileChange(e)}
                          disabled={n1RiskfileUpload}
                        />
                        <input type="hidden"
                          {...register("proofOfN1ChemicalRiskAuthEncoded")}
                          value={proofOfN1File} />

                        <span class="material-symbols-outlined" id="cloud-upload">

                          cloud_upload
                        </span>
                        {/* {editRegistrationData.proofOfN1ChemicalRiskAuthPath &&
                          <span title={editRegistrationData.proofOfN1ChemicalRiskAuthPath} className="material-symbols-outlined" >
                            attach_file
                          </span>
                        } */}
                      </div>
                    </Col>
                  </Row>
                </Col>


                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("ATEX technical authorization 0")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <select className="heading mb-2 mt-2  selectpicker" id="AtexAuth" onChange={(e) => handleSelectChange(e, "atex", 102)}>
                          <option value={'Yes'} >{t("Yes")}</option>
                          <option value={'No'} selected >{t("No")}</option>

                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Proof of ATEX technical authorization 0")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown" id="102fileup" style={{ background: atexUpload ? "#e9ecef" : 'white' }} >
                        <input
                          type="file"
                          id="102fileinput"
                          className="Upload-excel-input"
                          placeholder={file ? file.name : 'Choose a file'}
                          {...register("proofOfATEXAuthPath")}
                          style={{ color: 'black', opacity: proofOfATEXFile ? null : 0 }}
                          onChange={(e) => handleFileChange(e)}
                          disabled={atexUpload}
                        />
                        <input type="hidden"
                          {...register("proofOfATEXAuthEncoded")}
                          value={proofOfATEXFile} />


                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {/* {editRegistrationData.proofOfATEXAuthPath &&
                          <span title={editRegistrationData.proofOfATEXAuthPath} className="material-symbols-outlined" >
                            attach_file
                          </span>
                        } */}
                      </div>
                    </Col>
                  </Row>
                </Col>


                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Authorization to wear the SCBA mask")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown" >
                        <select className="heading mb-2 mt-2  selectpicker" id="SCBA" onChange={(e, index) => handleSelectChange(e, "scba", 103)}>
                          <option value={'Yes'} >{t("Yes")}</option>
                          <option value={'No'} selected >{t("No")}</option>

                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Proof of authorization to wear the SCBA mask")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown" id="103fileup" style={{ background: scbaUpload ? "#e9ecef" : 'white' }} >
                        <input
                          type="file"
                          id="103fileinput"
                          className="Upload-excel-input"
                          {...register("proofOfSCBAMaskAuthPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: proofOfSCBAFile ? null : 0 }}
                          onChange={(e) => handleFileChange(e)}
                          disabled={scbaUpload}
                        />
                        <input type="hidden"
                          {...register("proofOfSCBAMaskAuthEncoded")}
                          value={proofOfSCBAFile} />

                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {/* {editRegistrationData.proofOfSCBAMaskAuthPath &&
                          <span title={editRegistrationData.proofOfSCBAMaskAuthPath} className="material-symbols-outlined" >
                            attach_file
                          </span>
                        } */}
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Authorization to wear the harness")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <select className="heading mb-2 mt-2  selectpicker" id="Harness" onChange={(e) => handleSelectChange(e, "harness", 104)}>
                          <option value={'Yes'}>{t("Yes")}</option>
                          <option value={'No'} selected>{t("No")} </option>

                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Proof of authorization to wear the harness")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown" id="104fileup" style={{ background: harnessUpload ? "#e9ecef" : 'white' }}>
                        <input
                          type="file"
                          id="104fileinput"
                          className="Upload-excel-input"
                          {...register("proofOfHarnessAuthPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: proofOfHarnessFile ? null : 0 }}
                          onChange={(e) => handleFileChange(e)}
                          disabled={harnessUpload}
                        />
                        <input type="hidden"
                          {...register("proofOfHarnessAuthEncoded")}
                          value={proofOfHarnessFile} />

                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {/* {editRegistrationData.proofOfHarnessAuthPath &&
                          <span title={editRegistrationData.proofOfHarnessAuthPath} className="material-symbols-outlined" >
                            attach_file
                          </span>
                        } */}
                      </div>
                    </Col>
                  </Row>
                </Col>
                {/* <Col md={1}></Col> */}
                <>

                  {allSkills?.map((item, index) => (
                    //  editRegistrationData.skills.includes(item.skillName)? (
                    allSkills ? (

                      <React.Fragment key={index}>

                        <Col md={1}></Col>
                        <Col md={5}>
                          <label className="fLeft">
                            {t(item.skillName)}:
                          </label>
                          <Row className="heading mb-2 mt-2">
                            <Col md={12}>
                              <div className="popup-Dropdown" >
                                <select className="heading mb-2 mt-2  selectpicker" id={item.id} onChange={(e) => handleSelect(item.id, e.target.value, item, item.skillName)}>
                                  <option value={'Yes'} >{t("Yes")}</option>
                                  <option value={'No'} selected>{t("No")}</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={5}>
                          <label className="fLeft" >
                            {t('Proof of')} {t(item.skillName)}:
                          </label>
                          <Row className="heading mb-2 mt-2">
                            <Col md={12}>
                              <div className="popup-Dropdown" id={item.id + 'fileup'} style={{ background: "#e9ecef" }}>

                                <input
                                  type="file"
                                  id={item.id + 'fileinput'}
                                  // disabled={!item.documentPath}
                                  className="Upload-excel-input"
                                  style={{ color: 'black', opacity: 0 }}
                                  placeholder={file ? file.name : 'Choose a file'}
                                  //  style={{ color: 'black'}}
                                  // {...register("visitorSkillTypeId")}
                                  // {...register("skillType")}
                                  // {...register("documentPath")}
                                  // {...register("documentPathEncoded")}
                                  onChange={(e) => handleFileChangeDynamic(e, item.id)}
                                  disabled

                                />
                                <span className="material-symbols-outlined" id="cloud-upload">
                                  cloud_upload
                                </span>


                              </div>


                            </Col>
                          </Row>
                        </Col>

                      </React.Fragment>



                    ) : null
                  ))}
                </>














                <col md={1}></col>
              </Row>
            </Row>
            <Col md={1}></Col>


            <Col md={1}></Col>

            <Row>
              <Col md={6}></Col>
              <Col md={6}>
                <Row>

                  <Col md={4} className="canBtn"> 
                    <Link to="/registerList">
                      {" "}
                      <Button className="cancel-button m-2" variant="light">
                        {t("Cancel")}{" "}
                      </Button>
                    </Link>
                  </Col>
                  <Col md={4} className="addBtn">
                    {empId == 0 ?
                      <Button
                        className="add-button m-2"
                        variant="light"
                        type="submit"
                        onClick={event => onSaveClick(event)}
                      >
                        {t("Save")}{" "}

                      </Button> : <Button
                        className="cancel-button m-2"
                        variant="light"
                      //type="submit"
                      >
                        {t("Save")}{" "}
                      </Button>}
                  </Col>
                  {/* <Col md={4}>
                    {empId != 0 ?
                      <Button
                        className="add-button m-2"
                        variant="light"
                        //type="submit"
                        onClick={event => onSubmitClick(event.target.value)}
                      >
                        {t("Submit")}{" "}
                      </Button> : <Button
                        className="cancel-button m-2"
                        variant="light"
                      //type="submit"
                      >
                        {t("Submit")}{" "}
                      </Button>}
                  </Col> */}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6}></Col>
              <Col md={6}>
                <Row>

                  <Col md={4}>

                  </Col>
                  <Col md={4}>
                    <div className="dummydiv">

                    </div>
                  </Col>

                </Row>
              </Col>
            </Row>
          </form>
        </div>
      </div>
      <ToastContainer />

    </div>
  );
}
export default RegistrationAdd;
