import React, { useEffect, useState } from "react";
import { Form, Dropdown, FormLabel, InputGroup, Table } from "react-bootstrap";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { Button, Row, Col, Container } from "react-bootstrap";
import { set, useForm } from "react-hook-form";
import Popup from "../popup";
import { getVisitorSkills, getVisitorImg } from "../../services/api";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import Spinner from 'react-bootstrap/Spinner';
import {useMsal } from "@azure/msal-react";

//importing translation
import { useTranslation } from "react-i18next";

//importing styles
import "../../css/table.css";
import "../../css/form.css";
import "../../css/admin.css";
import "../../css/business.css";
import moment from "moment";
//import cripto-js for file encryption
const CryptoJS = require("crypto-js");

function RegistrationView() {
  const { t, i18n } = useTranslation();
  //const[contractType, setContractType]=useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState();
  const [selectedRole, setSelectedRole] = useState('')
  const [selectedFunction, setSelectedFunction] = useState('')
  const [vSkills, setVSkills] = useState([]);
  const { instance } = useMsal();

  const location = useLocation();
  let editRegistrationData = location.state.data;
  console.log(editRegistrationData, "ERD1")
  const encryptKey = 'vmsuat1234';
  let dcDob
  if (editRegistrationData.dateOfBirth != null && editRegistrationData.dateOfBirth != "") {
    dcDob = CryptoJS.AES.decrypt(editRegistrationData.dateOfBirth, encryptKey);
    dcDob = dcDob.toString(CryptoJS.enc.Utf8);
    dcDob = dcDob.replaceAll('"', '')
    dcDob = moment(dcDob).format("DD/MM/YYYY")

  } else {

    dcDob = "DD/MM/YYYY"
  }

  let dcYearsOfExp
  if (editRegistrationData.yearsOfExperience != null && editRegistrationData.yearsOfExperience != "") {
    dcYearsOfExp = CryptoJS.AES.decrypt(editRegistrationData.yearsOfExperience, encryptKey);
    dcYearsOfExp = dcYearsOfExp.toString(CryptoJS.enc.Utf8);
    dcYearsOfExp = dcYearsOfExp.replaceAll('"', '')
    dcYearsOfExp = moment(dcYearsOfExp).format("DD/MM/YYYY")

  } else {

    dcYearsOfExp = "DD/MM/YYYY"
  }



  let dcGovtId = null;
  if (editRegistrationData.govtId != '') {
    const key = 'visitormsuat1234';
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);
    dcGovtId = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(editRegistrationData.govtId) },
      keyutf,
      {
        iv: iv
      });

    dcGovtId = CryptoJS.enc.Utf8.stringify(dcGovtId)
  }

  let dcStartDate
  if (editRegistrationData.authCHStartDate != null && editRegistrationData.authCHStartDate != "") {
    dcStartDate = CryptoJS.AES.decrypt(editRegistrationData.authCHStartDate, encryptKey);
    dcStartDate = dcStartDate.toString(CryptoJS.enc.Utf8);
    dcStartDate = dcStartDate.replaceAll('"', '')
    dcStartDate = moment(dcStartDate).format("DD/MM/YYYY")

  } else {

    dcStartDate = "DD/MM/YYYY"
  }
  let dcEndDate
  if (editRegistrationData.authCHEndDate != null && editRegistrationData.authCHEndDate != "") {
    dcEndDate = CryptoJS.AES.decrypt(editRegistrationData.authCHEndDate, encryptKey);
    dcEndDate = dcEndDate.toString(CryptoJS.enc.Utf8);
    dcEndDate = dcEndDate.replaceAll('"', '')
    dcEndDate = moment(dcEndDate).format("DD/MM/YYYY")

  } else {

    dcEndDate = "DD/MM/YYYY"
  }
  let workStartDateMom 
  if(editRegistrationData.workStartDate != null && editRegistrationData.workStartDate != ""){
    workStartDateMom = moment(editRegistrationData.workStartDate).format("DD/MM/YYYY")
  }else {
    workStartDateMom = "DD/MM/YYYY"
  }


  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors }
  } = useForm();

  function formatDate(date) {
    console.log(date, "tesla")
    let mydate = new Date(date);
    let days = mydate.getDate();


    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    let month = monthNames[mydate.getMonth()];
    let year = mydate.getFullYear();
    return month + " " + days + " " + year;

  };
  function formatFromDate(cell, row) {

    return (
      <>{formatDate(row.workStartDate)}
      </>
    );
  }
  const getSkills = () => {
    let mounted = true;
    const visitorId = editRegistrationData.userId
    getVisitorSkills(visitorId, instance)

      .then(items => {
        console.log(items, "skills")
        if (mounted) {
          setVSkills(items);
          //setAvailableFunctionsWorker(items.Collaborator);
          return () => mounted = false;
        }


      })
  }

  useEffect(() => {
    registerLocale("en-GB", enGB)
    getSkills();


  }, [])
  //console.log(vSkills, "VSKILLS")
  // const viewDoc = (e, companyId, userId, fileName, fileType, id, vSkills) => {
  //   console.log(vSkills, "VSKILLS")

  //   let getObj = {
  //     "LocationId": 1,
  //     "CompanyId": companyId,
  //     "EmployeeId": userId,
  //     "FileType": fileType,
  //     "SkillTypeId": id,
  //     "FileName": fileName
  //   }
  //   let culture = 'fr-CH'
  //   if (i18n.language == 'en') {
  //     culture = 'en-US'
  //   }
  //   if (i18n.language == 'de') {
  //     culture = 'de-DE'
  //   }
  //   getVisitorImg(getObj, culture)
  //     .then(items => {
  //       if (items) {
  //         let originalText = items['fileEncoded'];
  //         let encodedUrl = CryptoJS.AES.decrypt(originalText, encryptKey);
  //         encodedUrl = encodedUrl.toString(CryptoJS.enc.Utf8);
  //         if (encodedUrl.charAt(0) == 'J') {
  //           let docSrc = `data:application/pdf;base64,${encodedUrl}`;
  //           togglePopup(docSrc, fileName, "pdf");
  //         } else {
  //           let image = new Image();
  //           image.src = `data:image/jpeg;base64,${encodedUrl}`;
  //           togglePopup(image.src, fileName, "img");
  //         }

  //       } else {
  //         toast.error('Error with view Document.')
  //       }
  //     })
  // }

  // const downloadDoc = (e, doc, fileName) => {
  //   var a = document.createElement("a");
  //   a.href = doc; //Image Base64 Goes here
  //   a.download = fileName; //File name Here
  //   a.click(); //Downloaded file
  // }
  // popup
  // const togglePopup = (doc, fileName, docType) => {
  //   setIsOpen(true);

  //   let numRows = (
  //     <>

  //       <Row className="sub-header pb-2 pt-2 mb-2">
  //         <Col md={11}>
  //           <b>{fileName}</b>
  //         </Col>
  //         <Col md={1}>
  //           <button style={{ background: "#FFFFFF", border: "none", color: "#512479", marginTop: "-11px" }} id="" data-toggle="tooltip" data-placement="top" title="Download" onClick={(e) => downloadDoc(e, doc, fileName)}><span class="material-symbols-outlined">download</span></button> </Col>

  //       </Row>
  //       <Row className="heading mb-2 mt-2">
  //         <Col md={12}>
  //           <div className="popup-input mt-2">
  //             {docType == 'pdf' || 'png' ?
  //               <iframe style={{ width: "570px", height: "350px", objectFit: "cover" }} src={doc + '#toolbar=0'}  ></iframe> : <img id="foo" style={{ width: "500px", height: "350px", objectFit: "cover" }} src={doc} />}
  //           </div>
  //         </Col>



  //       </Row>
  //       <Row>
  //         <Col md={6}></Col>
  //         <Col md={6}>
  //           <Row>
  //             <Col md={6}>
  //               {/* <Link to="/SiteTable"> <Button className='cancel-button m-2' variant="light" onClick={closepopup}>Cancel </Button></Link> */}

  //             </Col>
  //             <Col md={6}>
  //               {/* <Button className='add-button m-2' variant="light" type="submit" onClick={addFunc} >Add </Button> */}

  //             </Col>

  //           </Row>
  //         </Col>
  //       </Row>
  //     </>
  //   )
  //   setContent(
  //     numRows
  //   );

  // }
  const closeTogglePopup = () => {
    setIsOpen(false);
  }

  function closepopup() {
    document.querySelector('.close-icons').click()
  }


  return (
    <div className="row rect-row form-row">
      <div className="row second-header">
        <div className="col-5 tittle-div">
          <Row>
            <Col md={8}>
              {" "}
              <Link to="/registerList">
                {" "}
                <button className="addNew" style={{ marginTop: "3px" }}>
                  <span class="material-symbols-outlined" title="clear">
                    arrow_back
                  </span>
                </button>{" "}
              </Link>
              <span className="tittle">{t("View Employee")}</span>
            </Col>
            <Col md={1}>
              <span className="vertcal-line"> </span>
            </Col>
          </Row>
        </div>

        {/* <div className="col-7 button-div"></div>
        <div className="col-1"></div> */}
        <div className="col-10 form-col edit-form">
          <Row className="sub-header pb-2 pt-2 mb-2"></Row>
          <Row className="heading mb-2 mt-2">

            <Row>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}> {t("Company Name")}:</label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.companyName}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("EmployeeFname​​")}:</label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.firstName}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>​{t("Dateob​")}:</label>
                  <label style={{ fontWeight: 'bold' }} title="MM/DD/YYYY">{(dcDob)}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("Employee Last Name")}:</label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.lastName}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("Govt ID")}:</label>
                  <label style={{ fontWeight: 'bold' }}>{dcGovtId}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("Proof of Govt ID")}:</label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.govtIdPath}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("ContactVARO​")}:</label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.varoPointOfContact}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("Photo")}:</label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.photoPath}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("Work Start Date")}:</label>
                  <label style={{ fontWeight: 'bold' }} title="MM/DD/YYYY">{workStartDateMom}</label>
                </div>

              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("Type of Contract")}:</label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.contractType}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("Role")}:</label>{console.log(editRegistrationData.functionId,"VSid")}
                 {editRegistrationData.functionId &&  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.functionId === 3 ? t('Worker') : t('Manager')}</label>}
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("Work Shift")}:</label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.shiftId === 1 ? t('Normal Working Hours') : t('Shift Working Hours')}</label>
                </div>

              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("Function")}:</label>
                  <label style={{ fontWeight: 'bold' }}>{t(editRegistrationData.functionName)}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >

                  {editRegistrationData.functionId === 3 ? null : (<><label style={{ marginRight: '10px' }}>{t("Employee CV")}:</label>
                    <label style={{ fontWeight: 'bold' }}>{editRegistrationData.employeeCVPath}</label></>)}
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("Language")}:</label>
                  <label style={{ fontWeight: 'bold' }}>{t(editRegistrationData.languageKnown)}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>{t("Ex-Situ training questionnaire completed and signed")}:</label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.proofOfExSituTrainingCompletedPath}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>
                    {t("ProofMedicalClearance")}:
                  </label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.proofOfMedicalClearance}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>
                    {t("Years of experience")}:
                  </label>
                  {(editRegistrationData.yearsOfExperience < 5 && (editRegistrationData.functionName === "Business Manager" || editRegistrationData.functionName === "HSE Manager" || editRegistrationData.functionName === "Leader" || editRegistrationData.functionName === "Manager on site")) || (editRegistrationData.yearsOfExperience < 1.5 && editRegistrationData.functionName === "Collaborator") ? <Spinner style={{ marginLeft: "2px", marginTop: "3px" }} title={t(editRegistrationData.functionName) + " " + t("have a") + editRegistrationData.yearsOfExperience + " " + t("Years of Experience")} id="spinner" animation="grow" variant="danger" /> : <></>}
                  <label style={{ fontWeight: 'bold' }} title="MM/DD/YYYY">{dcYearsOfExp}</label>
                </div>
              </Col>
              {/* <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>
                    {t("Minimum N1 chemical risk authorization or equivalent")}:
                  </label>
                  <label style={{ fontWeight: 'bold' }}></label>
                </div>
              </Col> */}
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>
                    {t("Proof of N1 chemical risk clearance​")}:
                  </label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.proofOfN1ChemicalRiskAuthPath}</label>
                </div>

              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <label className="fLeft" style={{ fontWeight: 'bold' }}>
                  {t("Authorization to work in Switzerland")}:
                </label>
              </Col>


              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>
                    {t("Start Date")}:
                  </label>
                  <label style={{ fontWeight: 'bold' }} title="MM/DD/YYYY">{(dcStartDate)}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>
                    {t("End Date")}:
                  </label>
                  <label style={{ fontWeight: 'bold' }} title="MM/DD/YYYY">{(dcEndDate)}</label>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>
                    {t("Proof of Authorization")}:
                  </label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.proofOfAuthorization}</label>
                </div>
              </Col>

              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>
                    {t("Proof of Technical authorization ATEX 0")}:
                  </label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.proofOfATEXAuthPath}</label>
                </div>
              </Col>

              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}>
                    {t("Proof of authorization to wear the SCBA mask")}:
                  </label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.proofOfSCBAMaskAuthPath}</label>
                </div>
              </Col>


              <Col md={1}></Col>
              <Col md={5}>
                <div className="fLeft" >
                  <label style={{ marginRight: '10px' }}> {t("Proof of authorization to wear the harness")}:
                  </label>
                  <label style={{ fontWeight: 'bold' }}>{editRegistrationData.proofOfHarnessAuthPath}</label>
                </div>
              </Col>
              {console.log(editRegistrationData.skills, "ERDSK")}
              {vSkills?.map((item, index) => (

                vSkills ? (
                  <>
                    <Col md={1}></Col>
                    <Col md={5}>
                      <div className="fLeft" >
                        <label style={{ marginRight: '10px' }}>
                          {t(item.skillName)}:
                        </label>
                        <label style={{ fontWeight: 'bold' }}>{item.documentPath ? t('Yes') : t('No')}</label>
                      </div>
                    </Col>
                    <Col md={1}></Col>
                    <Col md={5}>
                      <div className="fLeft" >
                        <label style={{ marginRight: '10px' }}>  Proof of {t(item.skillName)}:
                        </label>
                        {item.documentPath ? <label style={{ fontWeight: 'bold' }}>
                          {item.documentPath}
                        </label> :
                          <label style={{ fontWeight: 'bold' }}>  {t('Not Available')}  </label>}

                      </div>
                    </Col>
                  </>
                ) : null
              ))}


              <col md={1}></col>
            </Row>
          </Row>
          <Col md={1}></Col>


          <Col md={1}></Col>


        </div>
      </div>
      <ToastContainer />
      {/* popup  */}
      {isOpen && <Popup
        content={content}
        handleClose={closeTogglePopup}
        popupWidth={"50%"}
        popupHeight={"430px"}

      />}
      {/* popup  */}
    </div>
  );
}
export default RegistrationView;
