import React from "react";
 
const Popup = props => {
  return (
    <div className="popup-boxs">
      <div className="boxs" style={{width: props.popupWidth, height:props.popupHeight,  maxHeight:props.popupHeight}}>
        <span class="material-symbols-outlined close-icons"  onClick={props.handleClose} >cancel</span>
        {props.content}
      </div>
    </div>
  );
};
 
export default Popup;