import React, { useState } from "react";
import "./style.css";

const VMSAccordion = ({ title, children }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="vms-accordion-item">
      <div className="vms-accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <b>{isActive ? "-" : "+"}</b>
      </div>
      {isActive && <div className="vms-accordion-content">{children}</div>}
    </div>
  );
};

export default VMSAccordion;
