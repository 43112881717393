const initialState = {
    
};

export const languageReducer = (state = initialState, action) => {  
  // new state start
  switch (action.type) {
    case 'GETLANGDATA':
      return {
        ...state,
        lang: action.payload
      };
      default:
    return state;
  }
  // new state end
 
  
};