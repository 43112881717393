//importing npm dependencies and hooks 
import React, { useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
//import { authProvider } from '../authProvider';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import useToken from '../services/useToken';
import Notification from "../component/notification";
import { signOut, getNotification } from '../services/api';
import Popup from '../component/notiPopup';
// MSAL imports
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
// importing language translation
import { useTranslation } from 'react-i18next';
import "../services/i18n";
// importing menu images
import logo_large from "../image/VARO_Energy_Logo_large.png";

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getLang, getNotifyCount } from '../redux/actions';

const Header = (props) => {

  const { instance } = useMsal();
    const [token, setToken] = useState();
    const [isNotiOpen, setIsNotiOpen] = useState(false);
  const [notific, setNotific] = useState([]);
  const notifyCount = useSelector((state) => state.notificationReducer.notifyCount);
  //translation
  const { t, i18n } = useTranslation();
  const lang = useSelector((state) => state.languageReducer.lang);


  let notiCount = props.notiCount;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  let user = JSON.parse(sessionStorage.getItem('tempToken'));
  let userName = user[0].user.userName;
  userName = userName.split(" ");

  const [isActive, setIsActive] = useState(sessionStorage.getItem('loginAs'));
  const [activeRole, setActiveRole] = useState(sessionStorage.getItem('loginAs') == 3 ? ' POC' : t("Responsible"));
  const [isActiveTab, setIsActiveTab] = useState("tab2");
  let userJson = JSON.parse(sessionStorage.getItem('token'));
  let UserRole = 1;
  const tab1 = useRef(null);
  const tab2 = useRef(null);
  const tab3 = useRef(null);
  const tab4 = useRef(null);

  // logout
  const logoutApp = (value, jwtToken) => async e => {
    let culture = 'fr-CH'
        if (i18n.language == 'en') {  
          culture = 'en-US'
        }
        if (i18n.language == 'de') {      
          culture = 'de-DE'
        }
    signOut(value, jwtToken, culture).then(items => {
      window.location.reload();
    })
  }

  function logoutToggle() {
    let popup = document.getElementById("logoutBtn");
    if (popup.style.display === "none") {
      popup.style.display = "block";
    } else {
      popup.style.display = "none";
    }
  }

  const handleLogout = (logoutType) => {
    // setAnchorEl(null);

    if (logoutType === "popup") {
      sessionStorage.clear();
      localStorage.clear();
      window.location.reload();
      instance.logoutPopup();
      
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
    // signOut(value, jwtToken).then(items => {
    //   window.location.reload();
    // })
  };
  const handleMouseOver = (event, id) => {
    document.getElementById(id).style.display = "block"
  };
  const handleMouseLeave = (event, id) => {
    document.getElementById(id).style.display = "none"
  };

  const handleClick = (event, param, activeRef) => {
    if (activeRef.current.id == 'tab1') {
      tab1.current.classList.add('active-link')
      tab2.current.classList.remove('active-link')
      tab3.current.classList.remove('active-link')
      tab4.current.classList.remove('active-link')

    } else if (activeRef.current.id == 'tab2') {
      tab2.current.classList.add('active-link')
      tab1.current.classList.remove('active-link')
      tab3.current.classList.remove('active-link')
      tab4.current.classList.remove('active-link')

    } else if (activeRef.current.id == 'tab3'){
      tab3.current.classList.add('active-link')
      tab2.current.classList.remove('active-link')
      tab1.current.classList.remove('active-link')
      tab4.current.classList.remove('active-link')
    }
    else if (activeRef.current.id == 'tab4'){
      tab4.current.classList.add('active-link')
      tab3.current.classList.remove('active-link')
      tab2.current.classList.remove('active-link')
      tab1.current.classList.remove('active-link')
    }
    else {
      tab4.current.classList.remove('active-link')
      tab3.current.classList.remove('active-link')
      tab2.current.classList.remove('active-link')
      tab1.current.classList.remove('active-link')
    }
    setIsActiveTab(activeRef.current.id);
  };

  // language change
  const handleLangChange = (event, param) => {
    dispatch(getLang(param));
    i18n.changeLanguage(param);
    sessionStorage.setItem('language', param);
  }
    
  const MINUTE_MS = 180000;

  // get table data from Api
  async function getData() {
    let culture = 'fr-CH'
    if (i18n.language == 'en') {  
      culture = 'en-US'
    }
    if (i18n.language == 'de') {      
      culture = 'de-DE'
    }
    await getNotification(culture)
      .then(items => {
        console.log(items,"items")
        if (items.status == 400 || items.status == 404) {
          //setTrainList([]) 
          setNotific([])
        } else {
          let notiC = 0;
          
          setNotific(items || [])
          dispatch(getNotifyCount(items.length));

        } 
      })
  }

  const toggleNotiPopup = () => {
    setIsNotiOpen(true);
}

const closeToggleNotiPopup = () => {
    setIsNotiOpen(false);
    setNotific([]);
}

  return (
    <>
    <nav className="col-12 navbar navbar-header navbar-expand-lg  ">
      <a className="navbar-brand" href="/"><img src={logo_large} /><span className=" vertical-line"></span> <span className="brand-title" title="Home Page">VMS </span><span className=" vertical-line" style={{ paddingTop: "13px", marginLeft: "25px", fontSize: "13px", fontWeight: "800" }}> {sessionStorage.getItem("env")}</span> </a>
      <div className="collapse navbar-collapse navbar-collapse-custom" id="navbarSupportedContent">
        <ul className="navbar-nav" >
          
          {/* -------------------Contractor module menu------------------------ */}
          {isActive == "2" &&
              <li className="nav-item nav-item-sub " style={{ width: "38%" }} >
                <a id="tab2" href="#" ref={tab2} >
                  <span ></span></a>

              </li>
            }
          {/* ------------------- POC module menu------------------------ */}
          
            <>
            {isActive == "3" &&
              <li className="nav-item nav-item-sub " style={{ width: "38%" }} onMouseOver={event => handleMouseOver(event, "sub-nv1")} onMouseLeave={event => handleMouseLeave(event, "sub-nv1")}>
                <Link to="/companyList"><a id="tab2" className={isActiveTab == 'tab2' ? 'nav-link active-link' : 'nav-link'} href="#" ref={tab2} onClick={event => handleClick(event, 'tab2', tab2)}><span class="material-symbols-outlined">apartment</span>
                  <span className="menu-text">{t('Company')}</span></a></Link>

              </li>
            }
              <li className="nav-item nav-item-sub " style={{ width: "38%" }}  onMouseOver={event => handleMouseOver(event, "sub-nv2")} onMouseLeave={event => handleMouseLeave(event, "sub-nv2")}>
                <Link to="/registerList"> <a id="tab1" className={isActiveTab == 'tab1' ? 'nav-link active-link' : 'nav-link'} href="#" ref={tab1} onClick={event => handleClick(event, 'tab1', tab1)}><span class="material-symbols-outlined">other_admission</span>
                  <span className="menu-text">{t('Registration')}</span></a></Link>
              </li>             
              <li className="nav-item nav-item-sub " style={{ width: "38%" }} onMouseOver={event => handleMouseOver(event, "sub-nv3")} onMouseLeave={event => handleMouseLeave(event, "sub-nv3")}>
                <Link to="#"> <a id="tab3" className={isActiveTab == 'tab3' ? 'nav-link active-link' : 'nav-link'} href="#" ref={tab3} onClick={event => handleClick(event, 'tab3', tab3)}><span class="material-symbols-outlined">School</span>
                  <span className="menu-text">{t('Training')}</span></a></Link>
                  <ul className='sub-nv' id="sub-nv3">                              
                  <Link to="/trainingList/session-by-emp"  onClick={event => handleClick(event, 'tab3', tab3)}><li class="menu-li">{t('Sessions By Employee')}
                  </li></Link>
                </ul>

              </li>
              <li className="nav-item nav-item-sub " style={{ width: "18%" }} onMouseOver={event => handleMouseOver(event, "sub-nv4")} onMouseLeave={event => handleMouseLeave(event, "sub-nv4")}>
                <a id="tab4" className={isActiveTab == 'tab4' ? 'nav-link active-link' : 'nav-link'} href="#"  ref={tab4} ><span class="material-symbols-outlined"><span class="material-symbols-outlined">
                  translate
                </span></span>
                  <span className="menu-text">{lang ? lang : "fe"}</span></a>
                <ul className='sub-nv' id="sub-nv4">
                  <li class="menu-li" onClick={event => handleLangChange(event, 'fe')}>French
                  </li>
                  <li class="menu-li" onClick={event => handleLangChange(event, 'en')}>English
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item nav-icon-bell td-10">
                <span className=" vertical-bell"><a className="" href="#notification"><span class="material-symbols-outlined mt-2">
                  construction</span> </a></span>
              </li> */}
                <li className="nav-item nav-icon-bell td-10">{console.log("notifyCount", notifyCount)}
                <span className=" vertical-bell" onClick={toggleNotiPopup}>{notifyCount > 0 ? <div className="noti-count">{notifyCount}</div> : <></>} <span class="material-symbols-outlined mt-2">
                  notifications</span> </span>
              </li>
            </>


          

          <li className="nav-item custom-user td-25">
          <AuthenticatedTemplate>

<a className="nav-link " href={void (0)} onClick={logoutToggle} onMouseLeave={event => handleMouseLeave(event, "logoutBtn")}>
  <div className="user-div">
    <span >{(userName[0])}</span>
    <span className="navbar-custom-user">{activeRole}</span>
  </div>
  <span class="material-symbols-outlined account_circle">account_circle</span>
  {/* <span className="sr-only">(current)</span> */}
  <span className="visually-hidden">(current)</span>
  <span className="logout-span" id="logoutBtn" >

    {/* <button style={{ width: "100%" }} >{UserRole}</button> */}

    <button style={{ width: "100%" }} onClick={() => handleLogout("popup")} >Logout</button>
  </span>
</a>

</AuthenticatedTemplate>

          </li>
        </ul>
      </div>
    </nav>
    {/* popup  */}

 {isNotiOpen && <Popup content={<>{console.log(notific)}
        <Notification notificData={notific} closeToggleNotiPopup={closeToggleNotiPopup} />
                    {/* <JdeMapping data={jdeData} closeTogglePopup={closeTogglePopup} /> */}
                </>}
                handleClose={closeToggleNotiPopup}
            />}
            {/* popup  */}
</>

  );

};
export default Header;