import React, { useCallback, useReducer } from 'react';




const setPage = (state, page) => {

  return { ...state, page };

};




const setPageSize = (state, payload) => {

  const { pageSize, nbItems } = payload;

  const lastPage = nbItems === 0 ? 1 : Math.ceil(nbItems / pageSize);

  return {

    ...state,

    page: state.page < lastPage ? state.page : lastPage,

    pageSize,

  };

};




const setDisplayedData = (state, displayed) => {

  switch (displayed) {

    case 'planned':

      return { ...state, displayed };

    case 'published':

      return { ...state, displayed };

    case 'feedback':

      return { ...state, displayed };

    default:

      return state;

  }

};




const setRendering = (state, rendering) => {

  return { ...state, rendering };

};




const setSearch = state => {

  if (!state.rendering) {

    return { ...state, page: 1 };

  }

  return state;

};




const reducers = (

  state = {

    page: 1,

    pageSize: 1,

    displayed: 'planned',

    rendering: true,

  },

  action

) => {

  const { type, payload } = action;

  switch (type) {

    case 'set_page':

      return setPage(state, payload.page);

    case 'set_page_size':

      return setPageSize(state, payload);

    case 'set_displayed':

      return setDisplayedData(state, payload.displayed);

    case 'set_rendering':

      return setRendering(state, payload.rendering);

    case 'set_search':

      return setSearch(state);

    default:

      break;

  }

  return state;

};




const initialState = {

  page: 1,

  pageSize: 1,

  displayed: 'planned',

  rendering: true,

  dispatch: (state, action) => {},

};




const setPageLocalStorage = value => {

  localStorage.setItem('page', value);

};




const setDisplayedLocalStorage = value => {

  localStorage.setItem('displayed', value);

};




export const PaginationContext = React.createContext(initialState);




const PaginationContextProvider = props => {

  const [{ page, pageSize, displayed, rendering }, dispatchStore] = useReducer(

    reducers,

    {

      page: 1,

      pageSize: 1,

      displayed: 'planned',

      rendering: true,

    }

  );




  const dispatch = useCallback(action => {

    dispatchStore(action);

    const { type, payload } = action;

    switch (type) {

      case 'set_page':

        setPageLocalStorage(payload.page);

        break;

      case 'set_displayed':

        setDisplayedLocalStorage(payload.displayed);

      default:

        break;

    }

  });




  return (

    <PaginationContext.Provider

      value={{ page, pageSize, displayed, rendering, dispatch }}

    >

      {props.children}

    </PaginationContext.Provider>

  );

};




export default PaginationContextProvider;