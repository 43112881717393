const initialState = {
    
};

export const signInReducer = (state = initialState, action) => {  
  // new state start
  switch (action.type) {
    case 'SIGNIN':
      console.log('rajarshi')
      const myHeaders = {}
      const myInit = {
        method: "GET",
        headers: myHeaders,
        credentials: "include"
        }
        return fetch('https://agw-vmsapi-d-weu-v02.varoenergy.online/api/Login/ValidateUser?emailID=rajarshi.chakraborty3@tcs.com', myInit);
    //   return fetch(`https://app-vmsapi-d-weu-v02.azurewebsites.net/api/Login/ValidateUser?emailID=rajarshi.chakraborty3@tcs.com`, {
    //     headers: {
    //         'Access-Control-Allow-Origin': 'https://app-vmsapi-d-weu-v02.azurewebsites.net',
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json',
    //         'Authorization': `Bearer sdsd`, 
           
    //     },
    //       withCredentials: true,
    //       credentials: 'include',    

    // });
      default:
    return state;
  }
  // new state end
 
  
};