
//FOR NOTIFICATION COUNT
export const GETNOTIFYDATA = 'GETNOTIFYDATA';
export const GETLANGDATA = 'GETLANGDATA';
export const GETREGCOMPANYID = 'GETREGCOMPANYID';
export const SIGNIN = 'SIGNIN';





