import React, { useCallback, useReducer } from 'react';

const hideColumn = (state, dataField) => {
  const hidden = [...state.hiddenColumns, dataField];
  return { ...state, hiddenColumns: hidden };
};

const hideColumns = (state, dataFields) => {
  const hidden = [...state.hiddenColumns, ...dataFields];
  return { ...state, hiddenColumns: hidden };
};

const displayColumn = (state, dataField) => {
  const hidden = state.hiddenColumns.filter(col => col !== dataField);
  return { ...state, hiddenColumns: hidden };
};

const reducers = (
  state = {
    hiddenColumns: [],
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case 'hide_column':
      return hideColumn(state, payload.dataField);
    case 'display_column':
      return displayColumn(state, payload.dataField);
    case 'hide_columns':
      return hideColumns(state, payload.dataFields);
    default:
      return state;
  }
};

const initialState = {
  hiddenColumns: [],
  dispatch: (state, action) => {},
};

export const ColumnsContext = React.createContext(initialState);

const addColumnLocalStorage = (hiddenColumns, dataField) => {
  if (hiddenColumns) {
    localStorage.setItem(
      'hiddenColumns',
      JSON.stringify([...hiddenColumns, dataField])
    );
    return;
  }
  localStorage.setItem('hiddenColumns', JSON.stringify([dataField]));
};

const removeColumnStorage = (hiddenColumns, dataField) => {
  let newArray = [];
  if (hiddenColumns != null) {
    newArray = hiddenColumns.filter(col => col !== dataField);
  }
  if (newArray.length === 0) {
    localStorage.removeItem('hiddenColumns');
    return;
  }
  localStorage.setItem('hiddenColumns', JSON.stringify(newArray));
};

const ColumnsContextProvider = props => {
  const [{ hiddenColumns }, dispatchStore] = useReducer(reducers, {
    hiddenColumns: [],
  });

  const dispatch = useCallback(
    action => {
      dispatchStore(action);
      const { type, payload } = action;
      const hiddenColumns = JSON.parse(localStorage.getItem('hiddenColumns'));
      switch (type) {
        case 'hide_column':
          addColumnLocalStorage(hiddenColumns, payload.dataField);
          break;
        case 'display_column':
          removeColumnStorage(hiddenColumns, payload.dataField);
          break;
        default:
          break;
      }
    },
    [dispatchStore]
  );

  return (
    <ColumnsContext.Provider value={{ hiddenColumns, dispatch }}>
      {props.children}
    </ColumnsContext.Provider>
  );
};

export default ColumnsContextProvider;