const initialState = {
    
    };
    
    export const notificationReducer = (state = initialState, action) => {  
      // new state start
      
      switch (action.type) {
        case 'GETNOTIFYDATA':
          return {
            ...state,
            notifyCount: action.payload
          };
          default:
        return state;
      }
      // new state end
     
      
    };