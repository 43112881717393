//importing npm dependencies and hooks
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Button,Row,Col } from 'react-bootstrap';
import { clearNotification, getNotification } from '../services/api';
import { useTranslation } from 'react-i18next';
import {useMsal } from "@azure/msal-react";

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getNotifyCount} from '../redux/actions';


const Notification = ({ notificDat = [] }) => {
    let user = JSON.parse(sessionStorage.getItem('token'));
    let roleId = user[0].loginAs;
    let userId = user[0].user.usR_ID;

    const { instance } = useMsal();
    const [notiData, setNotiData] = useState([]);
    const [countIntr, setCountIntr] = useState(1);
    const [newNotiData,setNewNotiData] = useState([]);
    const [notificData, setNotificData] = useState([]);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    //put notification
    function putNotification() {
        let notify_data = [];
        let request_data;
        notiData.forEach((element,x) => {
            notify_data.push({ "Notf_ID": element.id })
        });
        let notificValue = notify_data.map(getNotificData)
        function getNotificData(v, i) {
            return {"NotificationID": v.Notf_ID}
               
            
             
        }
        request_data = notificValue;
        let culture = 'fr-CH'
        // if (i18n.language == 'en') {  
        //   culture = 'en-US'
        // }
        // if (i18n.language == 'de') {      
        //   culture = 'de-DE'
        // }
        dispatch(getNotifyCount((notificData.length)-(notiData.length)));
        document.querySelector('.notiClose-icons').click()
        clearNotification(request_data, culture, instance)
    }
    const MINUTE_MS = 180000;
    async function getData () {
        let notData = [];
        let culture = 'fr-CH'
        if (i18n.language == 'en') {  
          culture = 'en-US'
        }
        if (i18n.language == 'de') {      
          culture = 'de-DE'
        }
        getNotification(culture, instance)
      .then(items => {
        if (items.status == 400 || items.status == 404) {
          //setTrainList([]) 
          setNotificData( [])
          ([])
        } else {
          let notiC = 0;
          
          setNotificData(items || [])
          if(countIntr == 1) {
            items.forEach((element, id) => {
                if (element.isRead == false && id < countIntr) {
                    notData.push(element)
                }
    
            });
            setNotiData(notData)
        }   
        setNewNotiData(notificData)
          dispatch(getNotifyCount(items.length));

        } 
      })
    }
    React.useEffect(() => {
        
        getData();
    const interval = setInterval(() => {
      getData();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

    const handleFilter = (event) => {
        if(notiData.length>0) {
            setNotiData([])
            let noti_data = [];
            notificData.forEach((element, id) => {
                if (element.isRead == false && id < countIntr+1) {
                    noti_data.push(element)
                }
    
                if (id == countIntr) {
                    setCountIntr(id + 1)
                }
    
            });
            setNotiData(noti_data)
        }
        
    }
    return (

        <div className="popup noti-popup">
            {/* <a className="close" href="#"  >&times;</a> */}
            <div className="popup-headline">Notification</div>
            <div className="notification-content" style={{overflowX:"hidden"}}>
                {notiData.map(val => (

                    <div className="noti-block noti-bg">
                        <div className="noti-header"><span class="material-symbols-outlined" title="notification">sms</span><span className="notify-content">{val.shortDescription}</span></div>
                        {/* <div className="noti-content"> { val.pricesetname+" : "+ val.bgname +" - "+val.additionalcomment} </div>
                        <span className="time-content">{val.createdon_date}</span> */}
                    </div>
                ))}

                <div style={{width:"100%"}}>
                <Row >
                    <Col md={6}>
                    <Button className='view-button m-2' variant="light" onClick={event => handleFilter(event)}> View More</Button>

                    </Col>
                    <Col md={6}>
                    <Button className='view-button m-2' variant="light"onClick={putNotification}><p  style={{color:"red"}} >ClearAll X</p> </Button>

                    </Col>
                </Row>
                </div>
                
            </div>
        </div>

    );

};
export default Notification;