import { combineReducers } from 'redux';
import {notificationReducer} from './notificationReducer';
import {languageReducer} from './languageReducer';
import {regcompReducer} from './regcompReducer';
import {signInReducer} from './signInReducer';


export default combineReducers({
    notificationReducer,
    languageReducer,
    regcompReducer,
    signInReducer
})