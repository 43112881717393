import React, { useState, useEffect } from "react";
import { Form, FormLabel, InputGroup, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Container } from 'react-bootstrap';
import VMSAccordion from "../../accordion/accordion";
import { getEmployeeByCompany, getSessionByEmployee, downloadSessionByEmployee } from '../../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import { useMsal } from "@azure/msal-react";
import moment from "moment";

//importing translation
import { useTranslation } from 'react-i18next';

export const formatUserChosenDate = (chosenDate) => {
  if (chosenDate != null) {
    let formateDate = chosenDate.getFullYear() + "-" + (parseInt(chosenDate.getMonth() + 1).length > 1 ? '' : '0') + parseInt(chosenDate.getMonth() + 1) + "-" + (chosenDate.getDate() > 9 ? '' : '0') + chosenDate.getDate()
    return formateDate;
  }
}
let APIError
const SessionByEmp = () => {
  const { instance } = useMsal();
  const [employeeByCompanyData, setEmployeeByCompanyData] = useState();
  const [selectedEmployees, setSelectedEmployees] = useState();
  const [sessionByEmpData, setSessionByEmpData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(new Date());
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const selectedOption = employeeByCompanyData.find((o) => o.userId === parseInt(value, 10));
    if (!selectedOption) {
      return;
    }
    if (selectedIds.includes(selectedOption.userId)) {
      setSelectedIds((prevSelectedIds) => prevSelectedIds.filter((id) => id !== selectedOption.userId));
    } else {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, selectedOption.userId]);
    }
    setSelectedOptions((prevSelectedOptions) => {
      const isOptionSelected = prevSelectedOptions.some((option) => option.userId === selectedOption.userId);
      if (isOptionSelected) {
        return prevSelectedOptions.filter((option) => option.userId !== selectedOption.userId);
      } else {
        return [...prevSelectedOptions, selectedOption];
      }
    });
  };
  const getSelectedOptionNames = () => {
    return selectedOptions.map((option) => option.userName);
  };
  console.log(selectedOptions, selectedIds);


  function handleSubmitClick() {
    console.log(formatUserChosenDate(dateRange))
    getSessionByEmployeeData()
  }


  const getEmployeesByCompanyData = () => {
    let mounted = true;
    let culture = 'fr-CH'
    if (i18n.language == 'en') {
      culture = 'en-US'
    }
    if (i18n.language == 'de') {
      culture = 'de-DE'
    }

    let companyID = sessionStorage.getItem('companyId')
    console.log(companyID)
    if (companyID != null && formatUserChosenDate(dateRange) != null) {
      getEmployeeByCompany(companyID, culture, instance)
        .then(items => {
          if (mounted) {
            setEmployeeByCompanyData(items)
            return () => mounted = false;
          }
          //setLoading(true);
        })
    }
    else {
      toast.error(<p style={{ color: "red" }}> {t("Please select at least one company to proceed")}</p>)
    }
  }

  // Function For Api Calls to get employee list by session
  const getSessionByEmployeeData = () => {
    let mounted = true;
    let companyID = sessionStorage.getItem('companyId')
    // if (formatUserChosenDate(dateRange) == null) {
    //   toast.error(<p style={{ color: "red" }}> {t("Please select any date to proceed")}</p>)
    // }
    // if (companyID == null) {
    //   toast.error(<p style={{ color: "red" }}> {t("Please select at least one company to proceed")}</p>)
    // }

    // if (companyID != null && formatUserChosenDate(dateRange) != null) {
    const employeeSet = []
    const employeeData = selectedIds.map((item) => {
      const data = {
        User_Id: ''
      }
      data.User_Id = item
      employeeSet.push(data)
    })
    console.log(employeeSet)
    let culture = 'fr-CH'
    if (i18n.language == 'en') {
      culture = 'en-US'
    }
    if (i18n.language == 'de') {
      culture = 'de-DE'
    }

    getSessionByEmployee(formatUserChosenDate(dateRange), companyID, employeeSet, culture, instance)
      .then(items => {
      
        if (mounted) {
          if (items['status'] === 400) {
            toast.error(<p style={{ color: "red" }}>HTTP Error 400 : Bad Request</p>)
          } else if (items['status'] === 404) {
            toast.error(<p style={{ color: "red" }}>HTTP Error 404 : Not Found</p>)
          } else if (items['status'] === 500) {
            if (items.detail === "No user found." || items.detail === "Aucun utilisateur trouvé.") {
              APIError = items.detail
            } else {
              toast.error(<p style={{ color: "red" }}> {items['detail']}</p>)
            }
          }
          setSessionByEmpData(items)
          return () => mounted = false;
        }
        setLoading(true);
      })
    // }
  }

  const PrintExcelClick = async () => {
    const id = toast.loading(t("Excel Downloading Please wait..."))
    const name = { returnByte: '' }

    const employeeSet = []
    const employeeData = selectedIds.map((item) => {
      const data = {
        User_Id: ''
      }
      data.User_Id = item
      employeeSet.push(data)
    })
    let culture = 'fr-CH'
    if (i18n.language == 'en') {
      culture = 'en-US'
    }
    if (i18n.language == 'de') {
      culture = 'de-DE'
    }

    let companyID = sessionStorage.getItem('companyId')
    downloadSessionByEmployee(formatUserChosenDate(dateRange), companyID, employeeSet, culture, instance)
      .then(json => {
        console.log(json.returnByte,"json")
        if (json['status'] === 400) {
          toast.error(<p style={{ color: "red" }}>HTTP Error 400 : Bad Request</p>)
        } else if (json['status'] === 404) {
          toast.error(<p style={{ color: "red" }}>HTTP Error 404 : Not Found</p>)
        } else if (json['status'] === 500) {
          toast.update(id, { render: json['detail'], type: "error", isLoading: false, autoClose: 3000, });
        } else if (json.returnByte != "") {
          toast.update(id, { render: t("Excel downloaded successfully"), type: "success", isLoading: false, autoClose: 3000, });
        }

        var fileName = t("Sessions By Employee");
        var bytes = Base64ToBytes(json.returnByte);
        var blob = new Blob([bytes], { type: "application/vnd.ms-excel" });
        var isIE = false || !!document.documentMode;
        if (isIE) {
          window.navigator.msSaveBlob(blob, fileName + ".xls");
        } else {
          var url = window.URL || window.webkitURL;
          var link = url.createObjectURL(blob);
          var fileLink = document.createElement('a');
          fileLink.href = link;
          fileLink.download = fileName + ".xlsx";
          fileLink.click();
        }
      });
  }

  function Base64ToBytes(base64) {
    let s = window.atob(base64);
    let bytes = new Uint8Array(s.length);
    for (var i = 0; i < s.length; i++) {
      bytes[i] = s.charCodeAt(i);
    }
    return bytes;
  };



  useEffect(() => {
    let mounted = true;
    setLoading(false);
    getEmployeesByCompanyData()
    registerLocale("en-GB", enGB)
    if (mounted) {
      APIError="";
      return () => mounted = false;
    }
  }, [loading]);
  const { t, i18n } = useTranslation();
  return (
    <>
      <ToastContainer />
      <div className="row">


        <div className="col-4 tittle-div2 ">
          <span className="tittle tr-form-m">{t("Sessions By Employee")}:</span>
          <span className="vertcal-line tr-form-m"> </span>
        </div>
        <div className="ml-15 filter-div">
          <div className="tr-col-1 tr-form-m ">

            <label className="fLeft"> Date<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
            <div className="popup-input">
              <div className="tr-calendar">

                <DatePicker
                  className="selectpickers"
                  // selectsRange={false}
                  // startDate={startDate}
                  // endDate={endDate}
                  selected={dateRange}
                  onChange={(date) => {
                    console.log(date)
                    setDateRange(date)
                  }
                  }
                  dateFormat="dd/MM/yyyy"
                  isClearable={true}
                //  maxDate={new Date()}
                // {...register("WorkStartDate")}
                />

              </div>
            </div>
          </div>

          <div className="tr-col-1 tr-form-m ml-15">
            <label className="fLeft">{t("Employee")}<span style={{ color: 'red', marginLeft: '2px' }}></span></label>
            <div className="tr-input">
              <div
                style={{
                  position: "relative",
                  width: "200px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "8px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  onClick={toggleDropdown}
                >
                  {selectedOptions.length > 0 ? (
                    <span
                      style={{
                        display: "inline-block",
                        maxWidth: "160px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {getSelectedOptionNames().join(", ")}
                    </span>
                  ) : (
                    <span style={{ color: "#999" }}>Select options</span>
                  )}
                  <span
                    style={{
                      border: "solid #999",
                      borderWidth: "0 1px 1px 0",
                      display: "inline-block",
                      padding: "4px",
                      transform: `rotate(${isOpen ? "45deg" : "225deg"})`,
                      transition: "transform 0.3s ease",
                    }}
                  ></span>
                </div>
                {isOpen && (
                  <div
                    style={{
                      display: "block",
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      border: "1px solid #ccc",
                      borderTop: "none",
                      backgroundColor: "#fff",
                      padding: "8px",
                      height: "200px",
                      overflowY: "scroll"
                    }}
                  >
                    {employeeByCompanyData?.map((option) => (
                      <div key={option.userId}>
                        <label htmlFor={option.userName}>
                          <input
                            type="checkbox"
                            id={option.userName}
                            value={option.userId}
                            defaultChecked={selectedOptions.includes(option.userId)}
                            onChange={handleCheckboxChange}
                          />
                          {option.userName}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="tr-col-05 tr-form-m ml-15 mt-40">
            <Button className='add-button m-1 tr-search' variant="light" type="submit" onClick={handleSubmitClick} >{t("Search")} </Button>
          </div>
        </div>



        <div style={{
          flexBasis: "100%",
          padding: "20px",
          backgroundColor: "#f0f0f0",
        }}>

          {sessionByEmpData && sessionByEmpData.length > 0 ?
            <div className="accordion">
              {sessionByEmpData.map((item, index) => (
                item.employees.map((val, ind) => (
                  <VMSAccordion title={val.employeeName + ' (' + item.comppanyName + ')'} data={item} key={index}>
                    <table>
                      <tr>
                        <th>{t('Training Module')}</th>
                        <th>{t('Room')}</th>
                        <th>{t('Session Date')}</th>
                        <th>{t('Start Time')}</th>
                        <th>{t('End Time')}</th>
                      </tr>

                      <tbody>
                        {val?.trainingDetails.map((elem, index1) => {
                          return (
                            <tr>
                              <td>{t(elem.module)}</td>
                              <td>{t(elem.room)}</td>
                              <td>{moment(elem.sessionDate).format("DD/MM/YYYY")}</td>
                              <td>{elem.startTime}</td>
                              <td>{elem.endTime}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </VMSAccordion>
                ))

              ))}
            </div> : <><div className="no-data" style={{ paddingLeft: "45%" }}>{sessionByEmpData && sessionByEmpData.length == 0 ? t("No Records Found") : t(APIError)}</div>
            </>}
        </div>
        <div className="col-12">
          <div className="col-10">

          </div>

          {sessionByEmpData?.length > 0 && <div>
            <Button className='add-button m-2' variant="light" type="submit" onClick={PrintExcelClick} >{t("Download")} </Button>
          </div>}

        </div>
      </div>
      {sessionByEmpData?.length == 0 && <div style={{ height: "400px" }}></div>}
    </>
  );
};

export default SessionByEmp;
