import React, { useEffect, useState } from "react";
import { getLanguage } from './api';

let translationData = [];

export default function translation() {
  let engVal = {};
  let frVal = {};
  // const [translationData, setTranslationData] = useState();
  let resources = {
    en: {
      translation: {
        "Search": "Search",
        "Download": "Download",
        "Available List of Companies": "Available List of Companies",
        "List of Companies": "List of Companies",
        "Company Name": "Company Name",
        "No. Of Employees": "No. Of Employees",
        "Business Manager": "Business Manager",
        "On-site Manager": "On-site Manager",
        "HSE Manager": "HSE Manager",
        "Work Start Date": "Work Start Date",
        "PO No.": "PO No.",
        "View": "View",
        "Edit": "Edit",
        "Disable": "Disable",
        "Users": "Users",
        "ListOfContractors": "List Of Responsibles of",
        "Id": "Id",
        "Contractor Name": "Responsible Name",
        "Contarctor Email": "Responsible Email",
        "Add New Contractor": "Add New Responsible",
        "Name of Contractor": "Name of Responsible",
        "Responsible": "Responsible",
        "Email": "Email",
        "Enter Company Name": "Enter Company Name",
        "Point of Contact": "Point of Contact",
        "Name of Point of Contact": "Name of Point of Contact",
        "Enter Email": "Enter Email",
        "Cancel": "Cancel",
        "Add": "Add",
        "Add Company": "Add Company",
        "Company": "Company",
        "No Of Employees on Site": "No Of Employees on Site",
        "HSE Manager Contact": "HSE Manager Contact",
        "Name & Email Id": "Name & Email Id",
        "No of Project Batch": "No of Project Batch",
        "List of Vehicles": "List of Vehicles",
        "Radioactive Sources": "Radioactive Sources",
        "SUVA Hazard List": "SUVA Hazard List",
        "Sub-Contracting Company?": "Sub-Contracting Company",
        "Sub-Contract Company Name": "Sub-Contractor Company Name",
        "Oranization Chart of functions": "Oranization Chart of functions",
        "Business Manager Contact": "Business Manager Contact",
        "On-site Manager Contact": "On-site Manager Contact",
        "Lifting Tool Test Certificate": "Lifting Tool Test Certificate",
        "Business Risk Analysis": "Business Risk Analysis",
        "PO Number": "PO Number",
        "PO Number of the order": "PO Number of the order",
        "Sub-Contractor Company POC": "Sub-Contractor Company POC",
        "Name": "Name",
        "Sub-Contractor POC Email": "Sub-Contractor POC Email",
        "Form F-505 received completed with requested documentation": "Form F-505 received completed with requested documentation",
        "Edit Employee": "Edit Employee",
        "View Employee": "View Employee",
        "EmployeeFname​​": "Employee First Name​",
        "Dateob​": "Date of Birth​",
        "Employee Last Name": "Employee Last Name",
        "Govt ID": "Govt ID",
        "Proof of Govt ID": "Proof of Govt ID",
        "ContactVARO​": "Contact Person at VARO​",
        "Photo​": "Photo​",
        "Function​": "Function​",
        "Select": "Select​",
        "Collaborator": "Collaborator",
        "Leader​": "Leader​",
        "Save": "Save",
        "Override": "Override",
        "Next​": "Next​",
        "Reset​": "Reset",
        "Employee CV": "Employee CV",
        "Authorization to work in Switzerland": "Authorization to work in Switzerland",
        "Start Date": "Start Date​",
        "End Date": "End Date​",
        "Proof of Authorization": "Proof of Authorization​",
        "Language​": "Language​",
        "French": "French",
        "German​": "German​",
        "English": "English",
        "Ex-Situ training questionnaire completed and signed": "Ex-Situ training questionnaire completed and signed​",
        "ProofMedicalClearance": "Proof of Medical Clearance​",
        "Years of experience": "Years of experience",
        "Previous​": "Previous​",
        "Minimum N1 chemical risk authorization or equivalent​": "Minimum N1 chemical risk authorization or equivalent​",
        "Yes": "Yes",
        "No": "No",
        "Proof of N1 chemical risk clearance​": "Proof of N1 chemical risk clearance​",
        "Training Schedule by Session": "Training Schedule by Session",
        "Sessions By Employee": "Sessions By Employee",
        "Employee": "Employee",
        "Registration": "Registration",
        "Training": "Training",
        "No Records Found": "No Records Found",
        "Select Any Date": "Select Any Date",
        "Please select any date to proceed": "Please select any date to proceed",
        "Error400": "HTTP Error 400 : Bad Request",
        "Error404": "HTTP Error 404 : Not Found",
        "UnknownErrorOccurred": "Unknown error Occurred!",
        "List of Employees of": "List of Employees of​",
        "CreateSession​": "Create Training Session​",
        "Time Shift": "Time Shift",
        "SessionDate": "Date",
        "Training Module": "Training Module",
        "List of Companies active in System​": "List of Companies active in System​",
        "EmployeeName": "Employee Name",
        "Skills": "Skills",
        "Function": "Function",
        "ContractType": "Contract Type​",
        "ExperienceYears": "Experience (in years)",
        "Status": "Status",
        "Approve": "Approve",
        "Rooms": "Rooms",
        "Room": "Room",
        "Start Time": "Start Time",
        "End Time": "End Time",
        "Reject": "Reject",
        "View Company": "View Company",
        "Edit Company": "Edit Company",
        "Manhole Supervisor function​": "Manhole Supervisor function",
        "Proof of function supervisor manhole​": "Proof of function supervisor manhole​",
        "Proof of sling lifting authorization": "Proof of sling lifting authorization",
        "Technical authorization ATEX 0:": "Technical authorization ATEX 0:",
        "Proof of Technical authorization ATEX 0:": "Proof of Technical authorization ATEX 0:",
        "Basket Lift Driver": "Basket Lift Driver",
        "Proof of basket driver's license": "Proof of basket driver's license",
        "PEMP Driver": "PEMP Driver",
        "Proof of PEMP Driver": "Proof of PEMP Driver",
        "Forklift Driver": "Driver's license Forklift",
        "Forklift Driver": "Proof of Forklift Driver​",
        "Truck Driver": "Truck Driver​",
        "Truck driving license proof​​": "Truck driving license proof​​",
        "Crane or Truck Crane Driver": "Crane or Truck Crane Drivere",
        "Proof of Crane or Truck Crane driving license​": "Proof of Crane or Truck Crane driving license",
        "Telehandler Driver": "Telehandler Driver",
        "Proof of driving license Telehandler": "Proof of driving license Telehandler",
        "Vehicle Attendant function​": "Vehicle attendant function​",
        "Proof of Vehicle Attendant function": "Proof of Vehicle Attendant function",
        "Authorization to wear the SCBA mask": "Authorization to wear the SCBA mask",
        "Proof of authorization to wear the SCBA mask": "Proof of authorization to wear the SCBA mask",
        "Authorization to wear the harness": "Authorization to wear the harness",
        "Proof of authorization to wear the harness": "Proof of authorization to wear the harness",
        "GTIS jointing Technical Qualification": "GTIS jointing Technical Qualification",
        "Proof of GTIS jointing technical authorization": "Proof of GTIS jointing technical authorization",
        "Sling Lifting": "Sling Lifting",
        "Role": "Role",
        "Worker": "Worker",
        "Manager": "Manager",
        "Work Shift": "Work Shift",
        "Normal Working Hours": "Normal Working Hours",
        "Shift Working Hours": "Shift Working Hours",
        "Type of Contract": "Type of Contract",
        "Submit": "Submit",
        "Onsite Manager": "Onsite Manager",
        "Onsite QHSE Manager": "Onsite QHSE Manager",
        "Are you sure you want to Approve?": "Are you sure you want to Approve?",
        "Are you sure you want to Disable?": "Are you sure you want to Disable?",
        "Please Provide a reason for Rejection *": "Please Provide a reason for Rejection *",
        "Confirm": "Confirm",
        "Ok": "Ok",
        "Proceed": "Proceed",
        "Rejection Reason": "Rejection Reason",
        "Please select a row to delete!": "Please select a row to delete!",
        "POC cannot be deleted! Please choose any other responsible to delete!": "POC cannot be deleted! Please choose any other responsible to delete!",
        "Please provide end time to proceed": "Please provide end time to proceed",
        "Please provide start time to proceed": "Please provide start time to proceed",
        "Please select a shift to proceed": "Please select a shift to proceed",
        "Please select one Room to proceed": "Please select one Room to proceed",
        "Please select one Training Module to proceed": "Please select one Training Module to proceed",
        "Are you sure you want to cancel this session?": "Are you sure you want to cancel this session?",
        "Are you sure you want to delete this employee from session?": "Are you sure you want to delete this employee from session?",
        "Please provide correct email!": "Please provide correct email!",
        "Add Employee": "Add Employee",
        "Capacity": "Capacity",
        "Not Available": " Not Available",
        "Proof of": "Proof of ",
        "Please Select The Function !": "Please Select The Function !",
        "Employee has": "Employee has",
        "function, but having less than 1.5 years of experience.": "function, but having less than 1.5 years of experience.",
        "function, but having less than 5 years of experience.": "function, but having less than 5 years of experience.",
        "have a": "have a",
        "Years of Experience": "Years of Experience",
        "Please upload a valid PDF or JPEG or JPG or PNG file": "Please upload a valid PDF or JPEG or JPG or PNG file",
        "File size exceeds 4 MB limit.": "File size exceeds 4 MB limit.",
        "File size exceeds 1.5 MB limit.": "File size exceeds 1.5 MB limit.",
        "Excel Downloading Please wait...": "Excel Downloading Please wait...",
        "Excel downloaded successfully": "Excel downloaded successfully",
        "Responsable d'affaires": "Business Manager",
        "Responsable QHSE sur site": "HSE Manager",
        "Chef d'équipe": "Leader",
        "Manager sur site": "Manager on site",
        "Please upload only .jpg format." : "Please upload only .jpg format.",
        "Please upload an image with equal to or less than 480px width and 640px height.": "Please upload an image with equal to or less than 480px width and 640px height.",
        "Session Date" : "Session Date",
        "Please Select Date or Company" : "Please Select Date or Company",
        "No user found." : "No user found.",
        

      },

    },
    fe: {
      translation: {
        "Search": "Chercher",
        "Download": "Télécharger",
        "Available List of Companies": "Liste des entreprises disponibles ​",
        "List of Companies": "Liste des entreprises",
        "Company Name": "Nom de l'entreprise",
        "No. Of Employees": "Nbre de collaborateurs sur site",
        "Business Manager": "Coordonnées du responsable d’affaires",
        "On-site Manager": "Coordonnées du manager sur site",
        "HSE Manager": "Coordonnées du responsable HSE sur site",

        "Business Manager": "Chef d'entreprise",
        "HSE Manager": "Responsable HSE",
        "Leader" : "Chef",
        "Manager on site" : "Gestionnaire sur place",
        "Work Start Date": "Date de début des travaux",
        "PO No.": "Numéro OP de la commande",
        "View": "Voir",
        "Edit": "Editer​",
        "Disable": "Désactiver",
        "Users": "Utilisateurs",
        "ListOfContractors": "Liste des Responsables de",
        "Id": "Numéro ID",
        "Contractor Name": "Prénom et Nom des Responsables",
        "Contarctor Email": "Adresse Email des Responsables",
        "Add New Contractor": "Ajouter un Responsable",
        "Name of Contractor": "Nom du responsable",
        "Responsible": "Responsable",
        "Email Id": "Adresse Email",
        "Enter Company Name": "Nom de l’entreprise",
        "Point of Contact": "Personne de contact",
        "Name of Point of Contact": "Nom, Prénom et Téléphone de la personne de contact",
        "Enter Email": "Adresse Email",
        "Cancel": "Annuler",
        "Add": "Ajouter",
        "Add Company": "Ajouter une entreprise",
        "Company": "Entreprise",
        "No Of Employees on Site": "Nbre d’employés sur site",
        "HSE Manager Contact": "Responsable HSE sur site",
        "Name & Email Id": "Nom, Prénom, Téléphone et adresse Email",
        "No of Project Batch": "Numéro de projet / Lot",
        "List of Vehicles": "Liste exhaustive des véhicules sur site",
        "Radioactive Sources": "Annonce des sources radioactives",
        "SUVA Hazard List": "Activités présentant des dangers particuliers selon liste SUVA",
        "Sub-Contracting Company?": "Entreprise sous-traitante",
        "Sub-Contract Company Name": "Nom de l’entreprise sous-traitante",
        "Oranization Chart of functions": "Organigramme des fonction",
        "Business Manager Contact": "Coordonnées du responsible d’affaires",
        "On-site Manager Contact": "Coordonnées du manager sur site",
        "Lifting Tool Test Certificate": "Certificat de contrôle des outils de levage",
        "Business Risk Analysis": "Analyse de risque métier",
        "PO Number": "Numéro OP de la commande",
        "PO Number of the order": "Numéro OP",
        "Sub-Contractor Company POC": "Personne de contact de l’entreprise sous-traitante",
        "Name": "Nom",
        "Sub-Contractor POC Email": "Adresse Email de la personne de contact",
        "Form F-505 received completed with requested documentation": "Formulaire F-505 complete avec la documentation demandée",
        "Edit Employee": "Modifier un employé",
        "View Employee": "Voir l'employé",
        "EmployeeFname​​": "Prénom​",
        "Dateob​": "Date de naissance​",
        "Employee Last Name": "Nom de famille​​",
        "Govt ID": "Numéro de la pièce d’identité",
        "Proof of Govt ID": "Copie de la pièce d’identité​",
        "ContactVARO​": "Personne de contact VARO",
        "Photo​": "Photo​",
        "Function​": "Function​",
        "Select": "Sélectionner​",
        "Collaborator": "Collaborateur",
        "Leader​": "Chef d’équipe",
        "Save": "Sauvegarder",
        "Override": "Passer outre",
        "Next​": "Suivant",
        "Reset​": "Réinitialiser​",
        "Employee CV": "CV du collaborateur",
        "Authorization to work in Switzerland": "Autorisation de travailler en Suisse​",
        "Start Date": "Date de début de l’autorisation​",
        "End Date": "Date de fin de l’autorisation",
        "Proof of Authorization": "Copie de l’autorisation de travailler en Suisse​",
        "Language": "Langue​",
        "French": "Français",
        "German​": "Allemand​",
        "English": "Anglais",
        "Ex-Situ training questionnaire completed and signed": "Questionnaire de formation Ex-Situ rempli et signé​",
        "ProofMedicalClearance": "Preuve de l'habilitation médicale à exercer son métier",
        "Years of experience": "Nombre d’années d’experience – Date de début​",
        "Previous​": "Précédent​",
        "Minimum N1 chemical risk authorization or equivalent": "Risque chimique N1 minimum ou équivalent​",
        "Yes": "Oui",
        "No": "Non​",
        "Proof of N1 chemical risk clearance​": "Preuve habilitation risque chimique N1​",
        "Training Schedule by Session": "Horaire des formations par session​",
        "Sessions By Employee": "Sessions par collaborateur​",
        "Employee": "Collaborateur",
        "Registration": "Enregistrement",
        "Training": "Formation",
        "No Records Found": "Aucun enregistrement trouvé",
        "Select Any Date": "Sélectionner une date",
        "Please select any date to proceed": "Sélectionner une date pour continuer",
        "Error400": "Erreur HTTP 400 : Requête incorrecte",
        "Error404": "Erreur HTTP 404 : Non trouvé",
        "UnknownErrorOccurred": "Erreur inconnue!",
        "List of Employees of": "Liste des collaborateurs de​",
        "CreateSession​": "Créer une session de formation​",
        "Time Shift": "Plage horaire​",
        "SessionDate": "Date de la session​",
        "Training Module": "Module de formation​",
        "List of Companies active in System​": "Liste des entreprises actives dans le système​​",
        "EmployeeName": "Prénom et Nom",
        "Skills": "Aptitudes",
        "Function": "Fonction",
        "ContractType": "Type de contract​",
        "ExperienceYears": "Nbre d’années d’expérience",
        "Status": "statut",
        "Approve": "Approuver",
        "Rooms": "Salles",
        "Room": "Salle",
        "Start Time": "Heure de début",
        "End Time": "Heure de fin",
        "Reject": "Refuser",
        "View Company": "Voir l’entreprise",
        "Edit Company": "Modifier l’entreprise",
        "Manholefunction": "Fonction de superviseur de regard​​",
        "Proof of function supervisor manhole​": "Preuve  fonction surveillant trou d'homme",
        "Proof of sling lifting authorization": "Preuve habilitation port du harnai",
        "Sling Lifting": "Habilitation port du harnais​",
        "Technical authorization ATEX 0": "Habilitation technique ATEX 0​",
        "Proof of Technical authorization ATEX 0": "Preuve habilitation technique ATEX 0",
        "Basket Lift Driver": "Permis de conduire nacelle​",
        "Proof of Basket Lift Driver": "Preuve permis de conduire nacelle",
        "PEMP Driver": "Permis de conduire PEMP",
        "Proof of PEMP Driver": "Preuve permis de conduire PEMP",
        "Forklift Driver": "Permis de conduire Chariot élévaleur",
        "Proof of Forklift Driver​": "Preuve permis de conduire chariot élévateur",
        "Truck Driver": "Permis de conduire Camion",
        "Truck Driver​": "Preuve permis de conduire Camion",
        "Crane or Truck Crane Driver": "Permis de conduire Grue ou Camion Grue",
        "Proof of Crane or Truck Crane driving license​": "Preuve permis de conduire Grue ou Camion Grue",
        "Telehandler Driver": "Permis de conduire Chariot téléscopique",
        "Proof of driving license Telehandler": "Preuve permis de conduire Chariot téléscopique",
        "Vehicle attendant function​": "Fonction accompagnant de véhicule",
        "Proof of Vehicle Attendant function": "Preuve de fonction accompagnant de véhicule",
        "Authorization to wear the SCBA mask": "Habilitation port du masque ARI",
        "Proof of authorization to wear the SCBA mask": "Preuve habilitation port du masque ARI",
        "Authorization to wear the harness": "Habilitation port du harnais​",
        "Proof of authorization to wear the harness": "Preuve habilitation port du harnai",
        "GGTIS jointing Technical Qualification": "Habilitation technique jointage GTIS",
        "Proof of GTIS jointing technical authorization": "Preuve habilitation technique jointage GTIS",
        "Manhole Supervisor function​": "Fonction de surveillant trou d'homme",
        "Role": "Rôle",
        "Worker": "Collaborateur",
        "Manager": "Responsable",
        "Work Shift": "Un poste de travail",
        "Normal Working Hours": "Heures normales de travail",
        "Shift Working Hours": "Horaires de travail décalés",
        "Type of Contract": "Type de contrat",
        "Submit": "Soumettre",
        "Onsite Manager": "Manager sur site",
        "Onsite QHSE Manager": "Responsable QHSE sur site",
        "Are you sure you want to Approve?": "Êtes-vous sûr de vouloir approuver?",
        "Are you sure you want to Disable?": "Êtes-vous sûr de vouloir désactiver?",
        "Please Provide a reason for Rejection *": "Veuillez fournir une raison pour le rejet *",
        "Confirm": "Confirmer",
        "Ok": "D'accord",
        "Proceed": "Procéder",
        "Rejection Reason": "Raison du rejet",
        "Please select a row to delete!": "Veuillez sélectionner une ligne à supprimer!",
        "POC cannot be deleted! Please choose any other responsible to delete!": "Le POC ne peut pas être supprimé ! Veuillez choisir tout autre responsable à supprimer !",
        "Please provide end time to proceed": "Veuillez indiquer l'heure de fin pour continuer",
        "Please provide start time to proceed": "Veuillez indiquer l'heure de début pour continuer",
        "Please select a shift to proceed": "Veuillez sélectionner un quart de travail pour continuer",
        "Please select one Room to proceed": "Veuillez sélectionner une chambre pour continuer",
        "Please select one Training Module to proceed": "Veuillez sélectionner un module de formation pour continuer",
        "Are you sure you want to cancel this session?": "Êtes-vous sûr de vouloir annuler cette session?",
        "Are you sure you want to delete this employee from session?": "Êtes-vous sûr de vouloir supprimer cet employé de la session?",
        "Please provide correct email!": "Veuillez fournir un e-mail correct!",
        "Add Employee": "Ajouter un employé",
        "Capacity": "Capacité",
        "Not Available": "Pas disponible",
        "Proof of": "Permis de ",
        "Please Select The Function !": "Veuillez sélectionner la fonction !",
        "Employee has": "L'employé a",
        "function, but having less than 1.5 years of experience.": "fonction de il a moins de 1,5 ans d'expérience",
        "function, but having less than 5 years of experience.": "fonction de il a moins de 5 ans d'expérience",
        "have a": "avoir un",
        "Years of Experience": "Des années d'expérience",
        "Please upload a valid PDF or JPEG or JPG or PNG file": "Veuillez télécharger un fichier PDF, JPEG, JPG ou PNG valide",
        "File size exceeds 4 MB limit.": "La taille du fichier dépasse la limite de 4 Mo.",
        "File size exceeds 1.5 MB limit.": "La taille du fichier dépasse la limite de 1,5 Mo.",
        "Excel Downloading Please wait...": "Téléchargement d'Excel Veuillez patienter...",
        "Excel downloaded successfully": "Excel téléchargé avec succès",
        "Please upload only .jpg format." : "Veuillez télécharger uniquement le format .jpg.",
        "Please upload an image with equal to or less than 480px width and 640px height." : "Veuillez télécharger une image d'une largeur égale ou inférieure à 480 px et d'une hauteur de 640 px.",
        "Session Date" : "Date de la séance",
        "Please Select Date or Company" : "Veuillez sélectionner une date ou une entreprise",
        "No user found." : "Aucun utilisateur trouvé.",
      },
    },
  };
  let items = JSON.parse(sessionStorage.getItem('languageMasterData'));
  console.log(items, "hi")
  if (items) {

    let engVals = {};
    let frVals = {};
    items.forEach(element => {
      engVals = {
        [(element.englishValue).trim()]: (element.englishValue).trim(),
      }
      frVals = {
        [(element.englishValue).trim()]: (element.frenchValue).trim(),
      }
      engVal = { ...engVal, ...engVals }
      frVal = { ...frVal, ...frVals }
    });

  }

  // let resources = {
  //   en: {
  //     translation: {

  //     },
  //   },
  //   fe: {
  //     translation: {
  //     },
  //   },
  // };
  resources.en.translation = { ...resources.en.translation, ...engVal };
  resources.fe.translation = { ...resources.fe.translation, ...frVal };
  return {
    translationData: resources
  }
}