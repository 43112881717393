import { useRef } from 'react';
import { authProvider } from '../authProvider';
const APIUrl = 'https://agw-vmsapi-u-weu-v02.varoenergy.online/api';
//const APIUrl = 'https://10.246.40.135/api';
const API_ROOT1 = `${APIUrl}/`;
const CryptoJS = require("crypto-js");
const jwtToken = JSON.parse(sessionStorage.getItem('token'));
let bytes = localStorage.getItem("XSRF-TOKEN") != null ? CryptoJS.AES.decrypt(localStorage.getItem("XSRF-TOKEN"), '') : null;

const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Authorization': jwtToken != null ? `Bearer ${jwtToken[0].token}` : `Bearer ${null}`,
    'X-XSRF-TOKEN': bytes != null ? bytes.toString(CryptoJS.enc.Utf8) : null
}

// signIn 
export function signIn(user, jwtToken, culture, instance) {
    return fetch(`${API_ROOT1 + 'Login/ValidateUser?emailID=' + user + '&culture=' + culture}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,

        },
        //  withCredentials: true,
        //  credentials: 'include',

    })
        .then((response) => {
            console.log(response.headers.get('XSRF-TOKEN'))
            let ciphertext = CryptoJS.AES.encrypt(response.headers.get('XSRF-TOKEN'), '').toString();
            localStorage.setItem("XSRF-TOKEN", ciphertext);
            console.log(ciphertext)
            if (API_ROOT1.includes("-d-") == true) {
                sessionStorage.setItem("env", 'DEV');
            } else if (API_ROOT1.includes("-u-") == true) {
                sessionStorage.setItem("env", 'UAT');
            } else if (API_ROOT1.includes("-p-") == true) {
                sessionStorage.setItem("env", 'PROD');
            } else {
                sessionStorage.setItem("env", '');
            }
            //if (!response.ok) throw new Error(response.status);
            //let  res = response => response.json()
            return response.json();
        })
        .catch((error) => {
            setLogout(error, instance)
        });
}


// signOut
export function signOut(user, jwtToken, culture) {
    let userRole = JSON.parse(sessionStorage.getItem('loginAs'));
    fetch(`${API_ROOT1 + 'Admin/LogOut?emailID=' + user + '&role_id=' + userRole + '&culture=' + culture}`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,
            'X-XSRF-TOKEN': bytes != null ? bytes.toString(CryptoJS.enc.Utf8) : null
        }

    })
        .then((response) => {
            response.json()
            sessionStorage.clear();
            localStorage.clear();
            //authProvider.logout()
        });
}
export async function getLoginUser(user, jwtToken, culture) {
    let bytes = localStorage.getItem("XSRF-TOKEN") != null ? CryptoJS.AES.decrypt(localStorage.getItem("XSRF-TOKEN"), '') : null;
    return fetch(`${API_ROOT1 + 'Admin/GetValidateUserDetails?emailID=' + user + '&culture=' + culture}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,
            'X-XSRF-TOKEN': bytes != null ? bytes.toString(CryptoJS.enc.Utf8) : null
        }
    })
        .then((response) => {
            if (API_ROOT1.includes("-d-") == true) {
                sessionStorage.setItem("env", 'DEV');
            } else if (API_ROOT1.includes("-u-") == true) {
                sessionStorage.setItem("env", 'UAT');
            } else {
                sessionStorage.setItem("env", '');
            }
            if (!response.ok && response.status == 401)
                throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });
}

// Get all translations for master data
export async function getLanguageTranslations(culture) {
    return fetch(`${API_ROOT1 + 'MasterData/GetLanguages?' + 'culture=' + culture}`, {
        headers: headers
    })
        .then((response) => {

            if (!response.ok && response.status == 401) {
                throw new Error(response.status);
            }
            else {
                return response.json();

            }
        })
        .catch((error) => {
            setLogout(error)

        });
}

//-------------------------------- notification -----------------------------------//
// notification get api call
export function getNotification(culture, instance) {
    let user = JSON.parse(sessionStorage.getItem('token'));
    let userRole = JSON.parse(sessionStorage.getItem('loginAs'));
    let userId = user[0].user.usR_ID;
    //let roleId = user[0].loginAs;
    let roleId = userRole;
    return fetch(`${API_ROOT1 + '/Notification/GetAppNotification?' + 'culture=' + culture}`, {
        method: 'get',
        headers: headers,

    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error, instance)
        });
}

// notification put api call
export function clearNotification(data, culture, instance) {
    let userRole = JSON.parse(sessionStorage.getItem('loginAs'));
    return fetch(`${API_ROOT1 + '/Notification/UpdateReadStatus'}`, {
        method: 'put',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error, instance)
        });
}

export function getLanguage(token) {


    return fetch(`${API_ROOT1 + 'MasterData/GetLanguages'}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': token != null ? `Bearer ${token}` : `Bearer ${null}`,
            'X-XSRF-TOKEN': bytes != null ? bytes.toString(CryptoJS.enc.Utf8) : null
        },

    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });
}


//for unauthorized logout
function setLogout(error, instance) {
    console.log(error, "rrrr")
    if (error.message == "Failed to fetch") {
        // window.location.href = '/'
        // instance.logoutPopup();
        instance.logoutRedirect();
        sessionStorage.clear();
        localStorage.clear();
        // setTimeout(() => { window.location.href = ''; window.location.reload(); }, 1000);
        
        // sessionStorage.clear();
        // localStorage.clear();
    }
}

/*------------------------------------------company-----------------------------------*/
// contract list api call
export async function getContractorData(param, culture) {
    return fetch(`${API_ROOT1 + 'User/GetContractorsbyCompany?companyId=' + param + '&culture=' + culture}`, {
        headers: headers
    })
        .then((response) => {
            if (!response.ok && response.status == 401)
                throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });
}
//api call for update contractor list
export function updateContractorData(data, culture) {
    return fetch(`${API_ROOT1 + 'User/UpdateContractor?' + 'culture=' + culture}`, {
        method: 'put',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });

}
// api call for add contractor list
export function addContractorData(data, culture) {
    let userRole = JSON.parse(sessionStorage.getItem('loginAs'));
    return fetch(`${API_ROOT1 + 'User/CreateContractor?' + 'culture=' + culture}`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });

}

// disable contractor api call
export function disableContractorData(data, culture) {
    console.log(data, "api")
    return fetch(`${API_ROOT1 + 'User/UpdateContractor?isRemove=true&culture=' + culture}`, {
        method: 'put',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });

}
/*------------------------------------------company-----------------------------------*/
// Add Company api call

export async function getCompanyData(culture, instance) {
    return fetch(`${API_ROOT1 + 'Company/GetCompany?' + 'culture=' + culture}`, {
        headers: headers
    })
        .then((response) => {

            if (!response.ok && response.status == 401) {
                throw new Error(response.status);
            }
            else {
                return response.json();

            }
        })
        .catch((error) => {
            setLogout(error, instance)

        });
}
export function updateCompanyData(data, culture, instance) {
    return fetch(`${API_ROOT1 + 'Company/UpdateCompany?' + 'culture=' + culture}`, {
        method: 'put',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error, instance)
        });

}

export function addCompanyData(data, culture) {
    console.log(data, "APIjS Data Came in")
    let userRole = JSON.parse(sessionStorage.getItem('loginAs'));
    return fetch(`${API_ROOT1 + 'Company/AddCompanyData?' + 'culture=' + culture}`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) {
                console.log(response, "APPLE 401 error response")
                throw new Error(response.status);

            }

            else {
                console.log(response, "else good response")
                return response.json();
            }
        })
        .catch((error) => {
            console.log(error, "APPLE catch response")
            setLogout(error)
        });

}


export function disableCompanyData(data, culture) {
    console.log(data, "api")
    return fetch(`${API_ROOT1 + 'Company/UpdateCompany?isRemove=true' + 'culture=' + culture}`, {
        method: 'put',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });

}

export function approveCompanyData(data, culture) {
    console.log(data, "api")
    return fetch(`${API_ROOT1 + 'Company/UpdateCompany?isApproved=true&culture=' + culture}`, {
        method: 'put',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });

}


/*------------------------------------------Training-----------------------------------*/
// company list based on workstart date for create session page
export async function getCompaniesByWorkStartDate(date, location, culture) {
    return fetch(`${API_ROOT1}Company/GetCompaniesByWorkStartDate?chosenDate=${date}&locationId=${location}&culture=${culture}`, {
        headers: headers
    })
        .then((response) => {
            if (!response.ok && response.status == 401)
                throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });
}


// Rooms by Training Module api call
export async function getRoomsbyTrainingModule(param, culture) {
    return fetch(`${API_ROOT1 + 'Training/GetRoomsbyTraining?trainingId=' + param + '&culture=' + culture}`, {
        headers: headers
    })
        .then((response) => {
            if (!response.ok && response.status == 401)
                throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });
}

// session list by Training Module api call
export async function getSessionByTrainingModule(param, culture) {
    return fetch(`${API_ROOT1 + 'Training/GetSessionsByTrainingModule?chosenDate=' + param + '&culture=' + culture}`, {
        headers: headers
    })
        .then((response) => {
            if (!response.ok && response.status == 401)
                throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });
}

// delete session by Training Module api call
export function deleteSessionByTrainingEmployee(empId, culture) {
    return fetch(`${API_ROOT1 + 'Training/RemoveEmployeeFromSession?userSessionID=' + empId + '&culture=' + culture}`, {
        method: 'delete',
        headers: headers
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });

}

// cancel session by Training Module api call
export function cancelSessionByModule(sessionId, culture) {
    return fetch(`${API_ROOT1 + 'Training/CancelSession?trainingSessionId=' + sessionId + '&culture=' + culture}`, {
        method: 'delete',
        headers: headers
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });

}


// get employee list for session list by Employee api call
export async function getEmployeeByCompany(param, culture, instance) {
    return fetch(`${API_ROOT1 + 'Training/GetVisitorNamesByCompany?companyId=' + param + '&culture=' + culture}`, {
        headers: headers
    })
        .then((response) => {
            if (!response.ok && response.status == 401)
                throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error, instance)
        });
}

// get session list by Employee api call
export async function getSessionByEmployee(date, companyId, employeeData, culture, instance) {
    date = date == undefined ? null : date
    companyId = companyId === undefined ? 0 : companyId

    return fetch(`${API_ROOT1 + 'Training/GetSessionsByEmployee?companyId=' + companyId + '&chosenDate=' + date + '&culture=' + culture}`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(employeeData),
    })
        .then((response) => {
            if (!response.ok && response.status == 401)
                throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error, instance)
        });
}

// download session list by Employee api call
export async function downloadSessionByEmployee(date, companyId, employeeData, culture, instance) {
    date = date == undefined ? null : date
    companyId = companyId === undefined ? 0 : companyId
    return fetch(`${API_ROOT1 + 'Training/DownloadSessionsByEmployee?companyId=' + companyId + '&chosenDate=' + date + '&culture=' + culture}`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(employeeData),
    })
        .then((response) => {
            if (!response.ok && response.status == 401)
                throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error, instance)
        });
}

export async function getVisitorsByCompanyData(companyId, culture, instance) {
    return fetch(`${API_ROOT1 + 'Registration/GetVisitorsByCompany?companyId=' + companyId + '&culture=' + culture}`, {
        headers: headers
    })
        .then((response) => {

            if (!response.ok && response.status == 401) {
                throw new Error(response.status);
            }
            else {
                return response.json();

            }
        })
        .catch((error) => {
            setLogout(error, instance)

        });
}
export async function getVisitorImg(data, culture) {
    console.log(data, "Visitor")
    return fetch(`${API_ROOT1 + 'Registration/GetVisitorDocuments?' + 'culture=' + culture}`, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data)
    })
        .then((response) => {

            if (!response.ok && response.status == 401) {
                throw new Error(response.status);
            }
            else {
                return response.json();

            }
        })
        .catch((error) => {
            setLogout(error)

        });
}
export function updateVisitorsData(data, culture, instance) {

    return fetch(`${API_ROOT1 + 'Registration/UpdateVisitors?' + 'culture=' + culture}`, {
        method: 'put',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
           
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error, instance)
        });

}

export async function downloadVisitorsByCompanyData(companyId, culture) {
    return fetch(`${API_ROOT1 + 'Registration/DownloadListVisitors?companyId=' + companyId + '&culture=' + culture}`, {
        method: 'post',
        headers: headers
    })
        .then((response) => {

            if (!response.ok && response.status == 401) {
                throw new Error(response.status);
            }
            else {
                return response.json();

            }
        })
        .catch((error) => {
            setLogout(error)

        });
}


//get all available training in system
export async function getAvailableTrainings(culture) {
    return fetch(`${API_ROOT1 + 'MasterData/GetAvailableTrainings?' + 'culture=' + culture}`, {
        headers: headers
    })
        .then((response) => {

            if (!response.ok && response.status == 401) {
                throw new Error(response.status);
            }
            else {
                return response.json();

            }
        })
        .catch((error) => {
            setLogout(error)

        });
}
export function submitEditRegistration(employeeID, culture, instance) {
    console.log(employeeID, "employeeID")
    return fetch(`${API_ROOT1 + 'Registration/SubmitRegistration?employeeId=' + employeeID + '&culture=' + culture}`, {
        method: 'put',
        headers: headers,
        // body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error, instance)
        });

}
export function addVisitorsData(data, culture, instance) {
    console.log(data, "APIjS Data Came in")
    let userRole = JSON.parse(sessionStorage.getItem('loginAs'));
    return fetch(`${API_ROOT1 + 'Registration/AddVisitors?' + 'culture=' + culture}`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) {
                console.log(response, "APPLE 401 error response")
                throw new Error(response.status);

            }

            else {
                console.log(response, "else good response")
                return response.json();
            }
        })
        .catch((error) => {
            console.log(error, "APPLE catch response")
            setLogout(error, instance)
        });

}

export async function getAvailableSkills(locationId, culture, instance) {
    //console.log(locationId)
    try {
        const response = await fetch(`${API_ROOT1 + 'MasterData/GetAvailableSkills?locationId=' + locationId + '&culture=' + culture}`, {
            method: 'get',
            headers: headers,
        });


        if (!response.ok && response.status == 401) {
            throw new Error(response.status);
        }
        const data = await response.json();
        return data;

    }
    catch (error) {
        setLogout(error, instance)
        throw error;
    }
};
export async function getVisitorSkills(employeeId, instance) {
    try {
        const response = await fetch(`${API_ROOT1 + 'Registration/GetVisitorSkills?employeeId=' + employeeId}`, {
            method: 'get',
            headers: headers,
        });


        if (!response.ok && response.status == 401) {
            throw new Error(response.status);
        }
        const data = await response.json();
        return data;

    }
    catch (error) {
        setLogout(error, instance)
        throw error;
    }
};




// approve reject registration api call
export function createSession(data, culture) {
    return fetch(`${API_ROOT1 + 'Training/CreateSession?' + 'culture=' + culture}`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });

}

//*------------------------------------------registration-----------------------------------*/
// approve reject registration api call
export function approveRejectRegistration(empId, reason, culture) {
    let regUrl;
    if (reason == null) {
        regUrl = 'Registration/ApproveRejectRegistration?employeeID=' + empId + '&approvalFlag=2' + '&culture=' + culture;
    } else {
        regUrl = 'Registration/ApproveRejectRegistration?employeeID=' + empId + '&approvalFlag=3&rejectionReason=' + reason + '&culture=' + culture
    }
    return fetch(`${API_ROOT1 + regUrl}`, {
        method: 'put',
        headers: headers,
        //body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error)
        });

}
export function disableRegistration(empId, culture, instance) {
    return fetch(`${API_ROOT1 + 'Registration/DisableRegistration?employeeID=' + empId + '&culture=' + culture}`, {
        method: 'put',
        headers: headers,
        // body: JSON.stringify(data),
    })
        .then((response) => {
            if (!response.ok && response.status == 401) throw new Error(response.status);
            else return response.json();
        })
        .catch((error) => {
            setLogout(error, instance)
        });

}
//*------------------------------------------registration-----------------------------------*/




// =---------new code added---------------

export async function getAvailableFunctions(locationId, culture, instance) {
    try {
        const response = await fetch(`${API_ROOT1 + 'MasterData/GetAvailableFunctions?locationId=' + locationId + '&culture=' + culture}`, {
            method: 'get',
            headers: headers,
        });

        if (!response.ok && response.status == 401) {
            throw new Error(response.status);
        }
        const data = await response.json();
        return data;

    }
    catch (error) {
        setLogout(error, instance)
        throw error;
    }
}