//importing npm dependencies and hooks 
import React, { useEffect, useState, useContext } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport, ColumnToggle } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Form, InputGroup, Table } from 'react-bootstrap';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { set, useForm } from "react-hook-form"
import Popup from '../popup';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getVisitorsByCompanyData, disableRegistration } from '../../services/api';
import enGB from "date-fns/locale/en-GB";
import { PaginationContext } from "../../context/pagination-context";
import {useMsal } from "@azure/msal-react";
//importing translation
import { useTranslation } from 'react-i18next';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getRegCompanyId } from '../../redux/actions';

//importing styles
import '../../css/table.css';
import '../../css/form.css';
import '../../css/admin.css';
import '../../css/business.css';

//importing sample table data later removed

const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;
const ref = React.createRef();

function RegistrationList() {

    const {
        page,
        pageSize,
        displayedCtx,
        dispatch: dispatchPagination,
    } = useContext(PaginationContext);

    //initiate language translation 
    const { t, i18n } = useTranslation();
    const [companyNam, setCompanyNam] = useState();
    const [companyId, setCompanyId] = useState();
    const [regStatus, setRegStatus] = useState([]);
    const [fullData, setFullData] = useState([]);
    const { instance } = useMsal();

    function formatDate(date) {
        let mydate = new Date(date);
        let days = mydate.getDate();

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        let month = monthNames[mydate.getMonth()];
        let year = mydate.getFullYear();
        return month + " " + days + " " + year;

    };
    function formatFromDate(cell, row) {

        return (
            <>{formatDate(row.workStartDate)}
            </>
        );
    }
    function formatCreateDate(cell, row) {

        return (
            <>{formatDate(row.createdOn)}
            </>
        );
    }
    function viewReason(cell, row) {
        if (row.status == 'Rejected' || row.status == 'Rejeté') {
            return (

                <a style={{ color: "red" }} href="javascript: void(0)" onClick={(e) => toastedChange(row.rejectionReason)}>{row.status}</a>
            )
        } else {
            return (
                <div>
                    <p>{row.status}</p>
                </div>
            )
        }
    }

    const toastedChange = (message) => {
        confirmAlert({
            message: message,
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                    }
                }
            ]
        });

    }
    const disableReg = (data) => {
        confirmAlert({
            message: t('Are you sure you want to Disable?'),
            buttons: [
                {
                    label: t('Ok'),
                    onClick: () => {
                        let culture = 'fr-CH'
                        if (i18n.language == 'en') {
                            culture = 'en-US'
                        }
                        if (i18n.language == 'de') {
                            culture = 'de-DE'
                        }
                        disableRegistration(data['userId'], culture, instance)
                            .then(data => {
                                if (data['status'] === 400) {
                                    toast.error(<p style={{ color: "red" }}>HTTP Error 400 : Bad Request</p>)
                                } else if (data['status'] === 404) {
                                    toast.error(<p style={{ color: "red" }}>HTTP Error 404 : Not Found</p>)
                                } else if (data['status'] === 500) {
                                    toast.error(<p style={{ color: "red" }}> {data['detail']}</p>)
                                } else if (data['name'] == "SuccessMessage") {
                                    toast.success(data['value']);
                                    //getTableDataFunc();
                                } else {
                                    toast.error(<p style={{ color: "red" }}> Unknown error Occurred!</p>)

                                }
                            })

                    }
                },
                {
                    label: t('Cancel'),
                    onClick: () => {

                    }
                }
            ]
        });

    }
    const { ToggleList } = ColumnToggle;
    const columns = [

        {
            dataField: 'userName',
            text: t('EmployeeName'),
        },
        {
            dataField: 'skills',
            text: t('Skills'),
            headerClasses: ' tbl-line',

        },
        {
            dataField: 'functionName',
            text: t('Function'),
        },
        {
            dataField: 'contractType',
            text: t('ContractType'),
        },
        {
            dataField: 'workStartDate',
            text: t('Work Start Date'),
            formatter: formatFromDate,
            sort: true,
        },
        {
            dataField: 'createdOn',
            text: t('Created Date'),
            formatter: formatCreateDate,
            sort: true,
        },
        {
            dataField: 'status',
            text: t('Status'),
            formatter: viewReason,
        },

        {
            dataField: 'actions',
            text: '',
            formatter: (cell, row) => (
                <div>
                    <Link to="/viewRegistration" state={{ data: row }}><button class="popup-admin-btn" type="button" style={{ marginLeft: 10 }}
                    // onClick={()=>handleAction('view, row')}
                    >{t("View")}</button></Link>
                    <Link to="/editRegistration" state={{ data: row }} disabled={row.status == 'Approved' || row.status == 'Approuvé'}><button disabled={row.status == 'Approved' || row.status == 'Approuvé'} class="popup-admin-btn" type="button" style={{ marginLeft: 10 }}
                    // onClick={()=>handleAction('view, row')}
                    >{t("Edit")}</button></Link>
                    <button disabled={row.status == 'Approved' || row.status == 'Approuvé'} class="popup-admin-btn" type="button" style={{ marginLeft: 10 }}
                        onClick={() => disableReg(row)}
                    >{t("Disable")}</button>
                </div>

            ),
            headerClasses: 'tbl-header-mediumLarge tbl-line',

        }
    ];


    const defaultSorted = [
        {
            dataField: 'createdOn',
            order: 'asc'
        }
    ];

    var asc = document.getElementById(".caret-4-asc");
    var desc = document.getElementById(".caret-4-asc");
    // state initialization


    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    // const [page, setPage] = useState(1);

    useEffect(() => {
        let mounted = true;
        setLoading(false);
        if (mounted) {
            //getDDLDataFunc()
            let companyID = sessionStorage.getItem('companyId')
            if (companyID && companyID != null && companyID != "") {
                setCompanyId(companyID)
                // getCompanyList(companyID)
                setTimeout(() => getCompanyList(companyID), 1500);
                //
            }
            return () => mounted = false;
        }
    }, [loading, i18n.language]);


    function getCompanyList(companyId) {
        setTableData([])
        let culture = 'fr-CH'
        if (i18n.language == 'en') {
            culture = 'en-US'
        }
        if (i18n.language == 'de') {
            culture = 'de-DE'
        }
        getVisitorsByCompanyData(companyId, culture, instance)
            .then(items => {
                let statusVal = [];
                items.forEach((element, index) => {

                    if (element.status != '' || element.status != null) {
                        const elementExists = statusVal.includes(element.status);
                        if (!elementExists) {
                            statusVal.push(element.status);
                        }
                    }

                });
                setRegStatus(statusVal)
                setTableData(items);
                setFullData(items);
            })
    }


    // table no record message
    const emptyDataMessage = () => { return t('No Records Found') };
    // table pagination options
    const options = {
        page: page,
        sizePerPage: 25,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        custom: true,
        totalSize: tableData.length,
        // onPageChange: function (page, sizePerPage) {
        //     options.page = page;
        //     setPage(page);
        //     options.sizePerPage = sizePerPage;
        // },
        onPageChange: function (page, sizePerPage) {
            options.page = page;
            dispatchPagination({ type: "set_page", payload: { page } });
            options.sizePerPage = sizePerPage;
        },
    };




    // search box display functions
    const handleFilter = (event, param, filterval) => {
        if (param == "searchInput") {
            if (document.getElementById(param).style.display == "none") {
                document.getElementById(param).style.display = "block";
            } else {
                document.getElementById(param).style.display = "none";
            }
        } else if (filterval == 'toggle') {
            if (document.getElementById(param).style.display == "none") {
                document.getElementById(param).style.display = "block";
            } else {
                document.getElementById(param).style.display = "none";
            }
        } else if (param == 'filter_alt-child' && filterval != 'toggle') {
            // group filter functionality
            //document.getElementById(param).style.display = "none";
            let groupArr = [];
            fullData.forEach((element, i) => {

                if (element['status'] == filterval) {
                    groupArr.push(element)
                }
                if (filterval == 'allData') {
                    setTableData(fullData)
                } else {
                    setTableData(groupArr)

                }
            });
        }
    }


    //second header functions


    const {
        register,
        handleSubmit,
        getValues,
        reset,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
    };


    const contentTable = ({ paginationProps, paginationTableProps }) => (

        <ToolkitProvider
            bootstrap4
            keyField='companyName'
            data={tableData}
            columns={columns}
            bordered={false}
            defaultSorted={defaultSorted}
            hover
            exportCSV
            search
            columnToggle
        >
            {
                toolkitprops => (
                    <React.Fragment>
                        <div className="row second-header" >
                            <div className="col-7 tittle-div">
                                <Row>
                                    <Col md={10}><span className="tittle">{t('List of Employees of')} {sessionStorage.getItem('companyName')}</span>
                                        {console.log(sessionStorage.getItem('companyName'), "Lp")}
                                        <span className="vertcal-line "> </span>
                                    </Col>
                                    <Col md={1}><span
                                    // className="vertical-line"
                                    > </span>
                                    </Col>
                                </Row>

                            </div>


                            <div className="col-5 button-div">
                                <div className="search-input" id="searchInput" style={{ display: "none" }}>
                                    <SearchBar className="custome-search-field"
                                        delay={100}
                                        id="search-bar-0"
                                        placeholder={"Search"}
                                        {...toolkitprops.searchProps} />
                                </div>
                                <div className="M-icons">
                                    <button className="addNew" onClick={event => handleFilter(event, 'searchInput')}><span class="material-symbols-outlined">search</span></button>
                                    <button className="addNew" id="" onClick={event => handleFilter(event, 'filter_alt-child', 'toggle')}><span class="material-symbols-outlined" title="filter">filter_alt</span>
                                        <ul class="filter-li" id="filter_alt-child" style={{ display: "none" }}>
                                            <li onClick={event => handleFilter(event, 'filter_alt-child', 'allData')}>All</li>
                                            {regStatus.length > 0

                                                && (regStatus).map(val => (
                                                    <li onClick={event => handleFilter(event, 'filter_alt-child', val)}>{val}</li>
                                                ))}
                                        </ul></button>
                                    <Link to="/addRegistration">
                                        <button className="addNew"><span class="material-symbols-outlined addR" id="add-icon">add_box</span></button>
                                    </Link>
                                </div>
                            </div>



                        </div>

                        <div className="row table-block">
                            <div className="col-12" ref={ref}>

                                <BootstrapTable
                                    bootstrap4
                                    hover
                                    noDataIndication={emptyDataMessage}
                                    headerClasses="tbl-line"
                                    classes='tbl-line'
                                    // selectRow={selectRow}
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    {...toolkitprops.baseProps}
                                />
                            </div>

                        </div>
                        <div className="row" >
                            <div className="col-5 ">
                                <div className="table-result"><PaginationTotalStandalone
                                    {...paginationProps}
                                /></div>
                            </div>
                            {/* <div className="col-4 table-col"></div> */}
                            <div className="col-6">
                                <nav data-pagination className="pagination-nav">
                                    <PaginationListStandalone {...paginationProps} />

                                </nav>
                            </div>
                            <div className="col-1 table-col"></div>
                        </div>

                    </React.Fragment>
                )
            }
        </ToolkitProvider>

    );

    return (
        <div>
            <ToastContainer />
            <PaginationProvider
                pagination={
                    paginationFactory(options)
                }
            >
                {contentTable}
            </PaginationProvider>
        </div >
    );


}


export default RegistrationList;