import React, { useState, useRef, useEffect } from "react";
import Router from './Router';
import { Routes, Route, useNavigate } from "react-router-dom";



// MSAL imports
import { EventType } from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";

// Sample app imports
import Login from "./component/login";
import Headers from "./component/header";
import './css/style.css';
import useToken from './services/useToken';
import { useDispatch, useSelector } from 'react-redux';

import { b2cPolicies } from "./authProvider";
import { useTranslation } from 'react-i18next';

function App({ pca }) {
  const { token, setToken } = useToken();
  const [notific, setNotific] = useState([]);
  const [notificCount, setNotificCount] = useState(0);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation()


  if(!token) {
    return (
      <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
      <Login setToken={setToken} />
      
        
      </MsalProvider>
    </ClientSideNavigation>
    )
  } else {
    return (
      <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
      <div className="container-fluid container-custom">          
              <div className="row">
                  <Headers notiCount={notificCount} />
              </div>
      <div className="App">
        <Router/>     
      </div>
      
              <div className="row">
                  <footer className="footer">© VARO Energy 2023
                  </footer>
              </div>
             
             
          </div>
            
      </MsalProvider>
      </ClientSideNavigation>
    );
}
}

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */ 
function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate();
  // const navigationClient = new CustomNavigationClient(navigate);
  // pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}

function Pages() {
  const { instance } = useMsal();
  const [status, setStatus] = useState(null);

  useEffect(() => {
      const callbackId = instance.addEventCallback((event) => {
        if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && event.payload.account) {
            /**
             * For the purpose of setting an active account for UI update, we want to consider only the auth 
             * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy 
             * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
             * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
             */
            if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
              // retrieve the account from initial sing-in to the app
              const originalSignInAccount = instance.getAllAccounts()
                  .find(account =>
                    account.idTokenClaims.oid === event.payload.idTokenClaims.oid
                    &&
                    account.idTokenClaims.sub === event.payload.idTokenClaims.sub
                    &&
                    account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
                  );
              
              let signUpSignInFlowRequest = {
                  authority: b2cPolicies.authorities.signUpSignIn.authority,
                  account: originalSignInAccount
              };
      
              // silently login again with the signUpSignIn policy
              instance.ssoSilent(signUpSignInFlowRequest);
            }
          }

          if (event.eventType === EventType.SSO_SILENT_SUCCESS && event.payload.account) {
            setStatus("ssoSilent success");
          }
      });

      return () => {
          if (callbackId) {
              instance.removeEventCallback(callbackId);
          }
      }
  // eslint-disable-next-line  
  }, []);

  return (
    
      <Routes>
        {console.log("hithere")}
          {/* <Route path="/profile" element={<Profile />} />
          <Route path="/logout" element={<Logout />} /> */}
          <Route path="/" element={<Login status={status} />} />
      </Routes>
  );
}

export default App;
