import React, { useEffect, useState } from "react";
import { Form, FormLabel, InputGroup, Table } from 'react-bootstrap';
import { Link, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { set, useForm } from "react-hook-form"
import Popup from '../popup';
//import { getTableManagementData, updateTableData, addTableData } from '../../services/api';
import { updateCompanyData } from '../../services/api';
import { confirmAlert } from 'react-confirm-alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import moment from "moment";
import FileResizer from "react-image-file-resizer";
import { useMsal } from "@azure/msal-react";
//importing translation
import { useTranslation } from 'react-i18next';

//importing styles
import '../../css/table.css';
import '../../css/form.css';
import '../../css/admin.css';
import '../../css/business.css';

//import cripto-js for file encryption
const CryptoJS = require("crypto-js");

let header = "";
function EditCompany() {
    const location = useLocation();
    let editCompanyData = location.state.data;

    let workStartDateEDR
    if (editCompanyData.workStartDate != null && editCompanyData.workStartDate != "") {

        workStartDateEDR = moment(editCompanyData.workStartDate).format("MM/DD/YYYY")
    } else {
        workStartDateEDR = ""
    }

    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const [dateRange, setDateRange] = useState((editCompanyData.workStartDate != null && editCompanyData.workStartDate != "") ? new Date(workStartDateEDR) : "");

    // const [isCompanyNameValid, setCompnayNameValid] = useState(true);
    // const [isPointOfContactNameValid, setPointOfContactNameValid] = useState(true)
    // const [isPointOfContactEmailValid, setPointOfContactEmailValid] = useState(true)

    const [isFormValid, setFormValid] = useState(false);
    const [file, setFile] = useState(null);
    const [f505File, setF505File] = useState("");
    const [vehicleListFile, setVehicleListFile] = useState("");
    const [radioActiveSourcesFile, setRadioActiveSourcesFile] = useState("");
    const [suvaHazardListFile, setSuvaHazardListFile] = useState("");
    const [liftingToolFile, setLiftingToolFile] = useState("");
    const [businessRiskFile, setBusinessRiskFile] = useState("");
    const [orgFunctionFile, setOrgFunctionFile] = useState("");



    const [isSubtractingCompanyEnabled, setisSubtractingCompanyEnabled] = useState(editCompanyData.isSubContractingCompany);
    const [subContractingCompanyName, setsubContractingCompanyName] = useState(editCompanyData.subContractingCompanyName);
    const [subContractingCompanyPOC, setsubContractingCompanyPOC] = useState(editCompanyData.subContractingCompanyPOCName);
    const [subContractingCompanyEmail, setsubContractingCompanyEmail] = useState(editCompanyData.subContractingCompanyPOCEmail);
    const encryptKey = 'vmsuat1234';
    const navigate = useNavigate();

    const resizeFile = (file) => new Promise(resolve => {
        // const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
        const allowedTypes = ['PDF', 'JPEG', 'JPG', 'PNG'];

        FileResizer.imageFileResizer(file, 600, 600, allowedTypes, 100, 0,
            uri => {
                resolve(uri);
            }, 'base64');
    });
    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];
        // mime check 
        var fileReader = new FileReader();
        fileReader.onloadend = async function (event) {
            var arr = (new Uint8Array(event.target.result)).subarray(0, 4);

            for (var i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }
            console.log(header, "header in fun");
            // Check the file signature against known types
            // let type;
            // switch (header) {
            //     case "89504e47":
            //     case "64617461":
            //         type = "image/png";
            //         header = ""
            //         break;
            //     case "25504446":
            //     case "255044462D":
            //         type = "application/pdf";
            //         header = ""
            //         break;
            //     case "ffd8ffe0":
            //     case "ffd8ffe1":
            //     case "ffd8ffe2":
            //     case "ffd8ffe3":
            //     case "ffd8ffe8":
            //         type = "image/jpeg";
            //         header = ""
            //         break;
            //     default:
            //         type = "unknown"; // Or you can use the blob.type as fallback
            //         toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
            //         e.target.value = null;
            //         header = ""
            //         break;
            // }
            const allowedTypes = ['89504e47', '64617461', '25504446', '255044462D', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'];
            if (!allowedTypes.includes(header)) {
                header = "";
                toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
                e.target.value = null;
                return;
            }
            const selectedFile = e.target.files[0];
            if (allowedTypes.includes(header)) {
                header = "";
                if (selectedFile) {


                    // const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
                    // if (!allowedTypes.includes(selectedFile.type)) {
                    //     toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
                    //     e.target.value = null;
                    //     return;
                    // }
                    const maxSizeInBytes = 4 * 1024 * 1024;
                    const maxNormalFileSizeInByte = 1024 * 1024;
                    if (selectedFile.size > maxSizeInBytes) {
                        toast.error(t('File size exceeds 4 MB limit.'))
                        e.target.value = null;
                        return;
                    }
                    let base64Content
                    if (selectedFile.size > maxNormalFileSizeInByte && e.target.files[0].type != 'application/pdf') {
                        console.log("if")
                        const file = e.target.files[0];
                        const documentData = await resizeFile(file);
                        console.log(documentData, "kkkkk");

                        base64Content = documentData.split(',')[1];
                        setFile(selectedFile);

                        if (e.target.name === "F505Path") {
                            setF505File(base64Content);
                        } else if (e.target.name === "VehicleListPath") {
                            setVehicleListFile(base64Content);
                        }
                        else if (e.target.name === "RadioactiveSourcesPath") {
                            setRadioActiveSourcesFile(base64Content);
                        }
                        else if (e.target.name === "SUVAActivitiesPath") {
                            setSuvaHazardListFile(base64Content);
                        }
                        else if (e.target.name === "LiftingToolCertificatePath") {
                            setLiftingToolFile(base64Content);
                        }
                        else if (e.target.name === "BusinessRiskPath") {
                            setBusinessRiskFile(base64Content);
                        }
                        else if (e.target.name === "OrgFunctionPath") {
                            setOrgFunctionFile(base64Content);
                        }
                    } else {
                        console.log("else")
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            base64Content = event.target.result.split(',')[1];
                            setFile(selectedFile);

                            if (e.target.name === "F505Path") {
                                setF505File(base64Content);
                            } else if (e.target.name === "VehicleListPath") {
                                setVehicleListFile(base64Content);
                            }
                            else if (e.target.name === "RadioactiveSourcesPath") {
                                setRadioActiveSourcesFile(base64Content);
                            }
                            else if (e.target.name === "SUVAActivitiesPath") {
                                setSuvaHazardListFile(base64Content);
                            }
                            else if (e.target.name === "LiftingToolCertificatePath") {
                                setLiftingToolFile(base64Content);
                            }
                            else if (e.target.name === "BusinessRiskPath") {
                                setBusinessRiskFile(base64Content);
                            }
                            else if (e.target.name === "OrgFunctionPath") {
                                setOrgFunctionFile(base64Content);
                            }

                        };
                        reader.readAsDataURL(selectedFile)
                    }

                }
            }


        };
        fileReader.readAsArrayBuffer(selectedFile);
    }
    useEffect(() => {
        if (editCompanyData.workStartDate != null) {
            setDateRange(new Date(editCompanyData.workStartDate));
        }

        registerLocale("en-GB", enGB)
    }, [])

    const {
        register,
        handleSubmit,
        getValues,
        reset,
        watch,
        formState: { errors }
    } = useForm();

    const { t, i18n } = useTranslation();


    const onSubmitForm = (data, e) => {
        setIsLoading(true);
        e.preventDefault();
        let workStdate
        if (dateRange != null && dateRange != "") {
            workStdate = moment(dateRange).format("YYYY-MM-DD")
        }
        else {
            workStdate = null
        }


        let Data = {

            "Id": editCompanyData.id,
            "CompanyName": editCompanyData.companyName,
            "WorkStartDate": (dateRange != null && dateRange != "") ? workStdate : null,
            "NoOfEmployees": data["NoOfEmployees"] ? data["NoOfEmployees"] : null,
            "BusinessManager": data["BusinessManager"],
            "OnSiteManager": data["OnSiteManager"],
            "IsActive": true,
            "IsApproved": true,
            "HSEManager": data["HSEManager"],
            "NoOfProjectBatch": data["NoOfProjectBatch"] ? data["NoOfProjectBatch"] : null,
            "F505Path": data.F505Path[0] ? data.F505Path[0].name : editCompanyData.f505Path,
            "F505PathEncoded": f505File ? CryptoJS.AES.encrypt(f505File, encryptKey).toString() : "",
            "OrgFunctionPath": data.OrgFunctionPath[0] ? data.OrgFunctionPath[0].name : editCompanyData.orgFunctionPath,
            "OrgFunctionPathEncoded": orgFunctionFile ? CryptoJS.AES.encrypt(orgFunctionFile, encryptKey).toString() : "",
            "VehicleListPath": data.VehicleListPath[0] ? data.VehicleListPath[0].name : editCompanyData.vehicleListPath,
            "VehicleListPathEncoded": vehicleListFile ? CryptoJS.AES.encrypt(vehicleListFile, encryptKey).toString() : "",
            "LiftingToolCertificatePath": data.LiftingToolCertificatePath[0] ? data.LiftingToolCertificatePath[0].name : editCompanyData.liftingToolCertificatePath,
            "LiftingToolCertificatePathEncoded": liftingToolFile ? CryptoJS.AES.encrypt(liftingToolFile, encryptKey).toString() : "",
            "RadioactiveSourcesPath": data.RadioactiveSourcesPath[0] ? data.RadioactiveSourcesPath[0].name : editCompanyData.radioactiveSourcesPath,
            "RadioactiveSourcesPathEncoded": radioActiveSourcesFile ? CryptoJS.AES.encrypt(radioActiveSourcesFile, encryptKey).toString() : "",
            "BusinessRiskPath": data.BusinessRiskPath[0] ? data.BusinessRiskPath[0].name : editCompanyData.businessRiskPath,
            "BusinessRiskPathEncoded": businessRiskFile ? CryptoJS.AES.encrypt(businessRiskFile, encryptKey).toString() : "",
            "SUVAActivitiesPath": data.SUVAActivitiesPath[0] ? data.SUVAActivitiesPath[0].name : editCompanyData.suvaActivitiesPath,
            "SUVAActivitiesPathEncoded": suvaHazardListFile ? CryptoJS.AES.encrypt(suvaHazardListFile, encryptKey).toString() : "",
            "IsSubContractingCompany": isSubtractingCompanyEnabled,
            "SubContractingCompanyName": isSubtractingCompanyEnabled == true ? subContractingCompanyName : "",
            "SubContractingCompanyPOCName": isSubtractingCompanyEnabled == true ? subContractingCompanyPOC : "",
            "SubContractingCompanyPOCEmail": isSubtractingCompanyEnabled == true ? subContractingCompanyEmail : "",
            "PONumber": data["PONumber"],
            "PointOfContactUserName": editCompanyData.pointOfContactUserName,
            "PointOfContactEmail": editCompanyData.pointOfContactEmail,
            "ShutdownYearId": 1,
            "LocationId": 1

        }


        onSaveClick(Data);
    };

    const onSaveClick = (data) => {
        updateFunc(data);
    };
    const updateFunc = (data) => {
        let culture = 'fr-CH'
        if (i18n.language == 'en') {
            culture = 'en-US'
        }
        if (i18n.language == 'de') {
            culture = 'de-DE'
        }
        // setFormValid(isCompanyNameValid && isPointOfContactNameValid && isPointOfContactEmailValid);
        // if (isFormValid) {
        updateCompanyData(data, culture, instance)
            .then(data => {
                setIsLoading(false);
                if (data['status'] === 400) {
                    toast.error(<p style={{ color: "red" }}>HTTP Error 400 : Bad Request</p>)
                } else if (data['status'] === 404) {
                    toast.error(<p style={{ color: "red" }}>HTTP Error 404 : Not Found</p>)
                } else if (data['status'] === 500) {
                    toast.error(<p style={{ color: "red" }}> {data['detail']}</p>)
                } else if (data['name'] == "SuccessMessage") {
                    toast.success(data['value']);
                    //  getTableDataFunc();
                    setTimeout(() => navigate('/CompanyList'), 1000);
                } else if (data['errorMessage'] != null) {
                    toast.error(data['errorMessage']);
                } else if (data) {
                    toast.error(<p style={{ color: "red" }}>{data}</p>)
                } else {
                    toast.error(<p style={{ color: "red" }}> Unknown error Occurred!</p>)

                }
            })
        // }
    }

    return (
        <div className="row rect-row form-row" >

            <div className="row second-header" >
                <div className="col-5 tittle-div">
                    <Row>
                        <Col md={10}> <Link to="/"> <button className="addNew" style={{ marginTop: "3px" }}><span class="material-symbols-outlined" title="clear" >arrow_back</span></button> </Link><span className="tittle">{t('Edit Company') + " - " + editCompanyData.companyName}</span>
                        </Col>
                        <Col md={1}><span className="vertcal-line"> </span>
                        </Col>
                    </Row>

                </div>


                {/* {isLoading ? <div class="loader"></div> : <div className="col-7 button-div">

                </div>} */}
                <div className="col-1"></div>
                <div className="col-10 form-col edit-form">
                    <form
                        onSubmit={handleSubmit(onSubmitForm)}

                    >
                        <Row className="sub-header pb-2 pt-2 mb-2">

                        </Row>
                        <Row className="heading mb-2 mt-2">

                            <Row>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft"> {t('Company Name')}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                                    <div className="popup-input mt-2">
                                        <InputGroup  >
                                            <Form.Control
                                                placeholder={t("Enter Company Name")}
                                                aria-label="Company Name "
                                                aria-describedby="basic-addon1"
                                                disabled
                                                className=""
                                                {...register("CompanyName")}
                                                defaultValue={editCompanyData.companyName}
                                            // onBlur={() => setCompnayNameValid(!!getValues("CompanyName"))}
                                            // isInvalid={!isCompanyNameValid}
                                            />
                                            {/* {!isCompanyNameValid && (
                                                <Form.Control.Feedback type="invalid">
                                                    {t("Company Name Required")}
                                                </Form.Control.Feedback>
                                            )} */}

                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('Point of Contact')}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                                    <div className="popup-input mt-2">
                                        <InputGroup  >
                                            <Form.Control
                                                placeholder="Name"
                                                aria-label="Name "
                                                aria-describedby="basic-addon1"
                                                disabled
                                                className=""
                                                {...register("PointOfContactUserName")}
                                                defaultValue={editCompanyData.pointOfContactUserName}
                                            // onBlur={() => setPointOfContactNameValid(!!getValues("PointOfContactUserName"))}
                                            // isInvalid={!isPointOfContactNameValid}

                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('Form F-505 received completed with requested documentation')}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                                    </label>
                                    <Row className="heading mb-2 mt-2">
                                        <Col md={12}>
                                            <div className="popup-Dropdown">

                                                <input type="file" id="file-upload" className="Upload-excel-input"   {...register("F505Path")}

                                                    placeholder={file ? file.name : 'Choose a file'}
                                                    style={{ color: 'black', opacity: f505File ? null : 0 }} onChange={handleFileChange}

                                                />
                                                <input type="hidden"
                                                    {...register("F505PathEncoded")}
                                                    value={f505File} />
                                                <span class="material-symbols-outlined" id="cloud-upload"> cloud_upload </span>
                                                {editCompanyData.f505Path &&
                                                    <span title={editCompanyData.f505Path} className="material-symbols-outlined" >
                                                        attach_file
                                                    </span>
                                                }

                                            </div>


                                        </Col>
                                    </Row>


                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">Email:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                                    <div className="popup-input mt-2">
                                        <InputGroup  >
                                            <Form.Control
                                                placeholder="Email"
                                                aria-label="Email "
                                                aria-describedby="basic-addon1"
                                                disabled
                                                className=""
                                                {...register("PointOfContactEmail")}
                                                defaultValue={editCompanyData.pointOfContactEmail}
                                            // onBlur={() => setPointOfContactEmailValid(!!getValues("pointOfContactEmail"))}
                                            // isInvalid={!isPointOfContactEmailValid}

                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('No Of Employees on Site')}:</label>
                                    <div className="popup-input mt-2">
                                        <InputGroup  >
                                            <Form.Control
                                                placeholder={t("No Of Employees on Site")}
                                                aria-label="No Of Employees on Site "
                                                aria-describedby="basic-addon1"
                                                className=""
                                                {...register("NoOfEmployees")}
                                                defaultValue={editCompanyData.noOfEmployees}

                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('HSE Manager Contact')}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                                    <div className="popup-input mt-2">
                                        <InputGroup  >
                                            <Form.Control
                                                placeholder={t("Name & Email Id")}
                                                aria-label="HSE Manager Contact"
                                                aria-describedby="basic-addon1"
                                                className=""
                                                {...register("HSEManager")}
                                                defaultValue={editCompanyData.hseManager}

                                            />

                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('No of Project Batch')}:</label>
                                    <div className="popup-input mt-2">
                                        <InputGroup  >
                                            <Form.Control
                                                placeholder={t("No of Project Batch")}
                                                aria-label="No of Project Batch"
                                                aria-describedby="basic-addon1"
                                                className=""
                                                {...register("NoOfProjectBatch")}
                                                defaultValue={editCompanyData.noOfProjectBatch}

                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('List of Vehicles')}:</label>
                                    <Row className="heading mb-2 mt-2">
                                        <Col md={12}>
                                            <div className="popup-Dropdown">

                                                <input type="file" id="file-upload" className="Upload-excel-input"   {...register("VehicleListPath")}
                                                    placeholder={file ? file.name : 'Choose a file'}
                                                    style={{ color: 'black', opacity: vehicleListFile ? null : 0 }}
                                                    onChange={handleFileChange}
                                                />
                                                <input type="hidden"
                                                    {...register("VehicleListPathEncoded")}
                                                    value={vehicleListFile}
                                                />

                                                <span class="material-symbols-outlined" id="cloud-upload"> cloud_upload </span>
                                                {editCompanyData.vehicleListPath &&
                                                    <span title={editCompanyData.vehicleListPath} className="material-symbols-outlined" >
                                                        attach_file
                                                    </span>
                                                }

                                            </div>

                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('Radioactive Sources')}:</label>
                                    <Row className="heading mb-2 mt-2">
                                        <Col md={12}>
                                            <div className="popup-Dropdown">

                                                <input type="file" id="file-upload" className="Upload-excel-input" {...register("RadioactiveSourcesPath")}
                                                    placeholder={file ? file.name : 'Choose a file'}
                                                    style={{ color: 'black', opacity: radioActiveSourcesFile ? null : 0 }}
                                                    onChange={handleFileChange}
                                                />
                                                <input type="hidden"
                                                    {...register("RadioactiveSourcesPathEncoded")}
                                                    value={radioActiveSourcesFile}
                                                />

                                                <span class="material-symbols-outlined" id="cloud-upload"> cloud_upload </span>
                                                {editCompanyData.radioactiveSourcesPath &&
                                                    <span title={editCompanyData.radioactiveSourcesPath} className="material-symbols-outlined" >
                                                        attach_file
                                                    </span>
                                                }
                                            </div>

                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('SUVA Hazard List')}:</label>
                                    <Row className="heading mb-2 mt-2">
                                        <Col md={12}>
                                            <div className="popup-Dropdown">

                                                <input type="file" id="file-upload" className="Upload-excel-input" {...register("SUVAActivitiesPath")}
                                                    placeholder={file ? file.name : 'Choose a file'}
                                                    style={{ color: 'black', opacity: suvaHazardListFile ? null : 0 }}
                                                    onChange={handleFileChange}
                                                />
                                                <input type="hidden"
                                                    {...register("SUVAActivitiesPathEncoded")}
                                                    value={suvaHazardListFile}
                                                />

                                                <span class="material-symbols-outlined" id="cloud-upload"> cloud_upload </span>
                                                {editCompanyData.suvaActivitiesPath &&
                                                    <span title={editCompanyData.suvaActivitiesPath} className="material-symbols-outlined" >
                                                        attach_file
                                                    </span>
                                                }
                                            </div>

                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('Oranization Chart of functions')}:</label>
                                    <Row className="heading mb-2 mt-2">
                                        <Col md={12}>
                                            <div className="popup-Dropdown">
                                                <input type="file" id="file-upload" className="Upload-excel-input" {...register("OrgFunctionPath")}
                                                    placeholder={file ? file.name : 'Choose a file'}
                                                    style={{ color: 'black', opacity: orgFunctionFile ? null : 0 }}
                                                    onChange={handleFileChange}
                                                />
                                                <input type="hidden"
                                                    {...register("OrgFunctionPathEncoded")}
                                                    value={orgFunctionFile}
                                                />

                                                <span class="material-symbols-outlined" id="cloud-upload"> cloud_upload </span>
                                                {editCompanyData.orgFunctionPath &&
                                                    <span title={editCompanyData.orgFunctionPath} className="material-symbols-outlined" >
                                                        attach_file
                                                    </span>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('Business Manager Contact')}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                                    <div className="popup-input mt-2">
                                        <InputGroup  >
                                            <Form.Control
                                                placeholder={t("Name & Email Id")}
                                                aria-label="Business Manager Contact"
                                                aria-describedby="basic-addon1"
                                                className=""
                                                {...register("BusinessManager")}
                                                defaultValue={editCompanyData.businessManager}

                                            />

                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('On-site Manager Contact')}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                                    <div className="popup-input mt-2">
                                        <InputGroup  >
                                            <Form.Control
                                                placeholder={t("Name & Email Id")}
                                                aria-label="On-site Manager Contact"
                                                aria-describedby="basic-addon1"
                                                className=""
                                                {...register("OnSiteManager")}
                                                defaultValue={editCompanyData.onSiteManager}

                                            />

                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('Work Start Date')}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                                    <div className="popup-input mt-2">
                                        <div className="calendar">

                                            <DatePicker
                                                className="selectpickers"
                                                // selectsRange={false}
                                                // startDate={startDate}
                                                // endDate={endDate}
                                                showYearDropdown
                                                selected={dateRange}
                                                onChange={(date) => {
                                                    setDateRange(date)
                                                }
                                                }
                                                dateFormat="dd/MM/yyyy"
                                                isClearable={true}
                                                minDate={new Date()}
                                            //{...register("WorkStartDate")}
                                            />

                                        </div>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('Lifting Tool Test Certificate')}:</label>
                                    <Row className="heading mb-2 mt-2">
                                        <Col md={12}>
                                            <div className="popup-Dropdown">
                                                <input type="file" id="file-upload" className="Upload-excel-input" {...register("LiftingToolCertificatePath")}
                                                    placeholder={file ? file.name : 'Choose a file'}
                                                    style={{ color: 'black', opacity: liftingToolFile ? null : 0 }}
                                                    onChange={handleFileChange}
                                                />
                                                <input type="hidden"
                                                    {...register("LiftingToolCertificatePathEncoded")}
                                                    value={liftingToolFile}
                                                />

                                                <span class="material-symbols-outlined" id="cloud-upload"> cloud_upload </span>
                                                {editCompanyData.liftingToolCertificatePath &&
                                                    <span title={editCompanyData.liftingToolCertificatePath} className="material-symbols-outlined" >
                                                        attach_file
                                                    </span>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('Business Risk Analysis')}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                                    <Row className="heading mb-2 mt-2">
                                        <Col md={12}>
                                            <div className="popup-Dropdown">
                                                <input type="file" id="file-upload" className="Upload-excel-input" {...register("BusinessRiskPath")}
                                                    placeholder={file ? file.name : 'Choose a file'}
                                                    style={{ color: 'black', opacity: businessRiskFile ? null : 0 }}
                                                    onChange={handleFileChange}

                                                />

                                                <input type="hidden"
                                                    {...register("BusinessRiskPathEncoded")}
                                                    value={businessRiskFile}
                                                />
                                                <span class="material-symbols-outlined" id="cloud-upload"> cloud_upload </span>
                                                {editCompanyData.businessRiskPath &&
                                                    <span title={editCompanyData.businessRiskPath} className="material-symbols-outlined" >
                                                        attach_file
                                                    </span>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft fClabel">{t('Sub-Contracting Company?')}
                                    </label>
                                    <div className="popup-input mt-2 fCheckbox">
                                        < input type="checkbox"
                                            checked={isSubtractingCompanyEnabled}
                                            onChange={(e) => {
                                                if (isSubtractingCompanyEnabled) {
                                                    setsubContractingCompanyEmail("");
                                                    setsubContractingCompanyName("");
                                                    setsubContractingCompanyPOC("");
                                                }
                                                setisSubtractingCompanyEnabled(e.target.checked);
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('PO Number')}:</label>
                                    <div className="popup-input mt-2">
                                        <InputGroup  >
                                            <Form.Control
                                                placeholder={t("PO Number of the order")}
                                                aria-label="PO Number"
                                                aria-describedby="basic-addon1"
                                                className=""
                                                {...register("PONumber")}
                                                defaultValue={editCompanyData.poNumber}
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('Sub-Contract Company Name')}:</label>
                                    <div className="popup-input mt-2">
                                        <InputGroup  >
                                            <Form.Control
                                                placeholder={t("Sub-Contract Company Name")}
                                                aria-label="Sub-Contract Company Name"
                                                aria-describedby="basic-addon1"
                                                value={subContractingCompanyName}
                                                disabled={!isSubtractingCompanyEnabled}
                                                onChange={(e) =>
                                                    setsubContractingCompanyName(e.target.value)
                                                }
                                            />
                                        </InputGroup>
                                    </div>


                                </Col>


                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('Sub-Contractor Company POC')}:</label>
                                    <div className="popup-input mt-2">
                                        <InputGroup  >
                                            <Form.Control
                                                placeholder={t("Name")}
                                                aria-label="Sub-Contractor Company POC"
                                                aria-describedby="basic-addon1"
                                                value={subContractingCompanyPOC}
                                                disabled={!isSubtractingCompanyEnabled}
                                                onChange={(e) =>
                                                    setsubContractingCompanyPOC(e.target.value)
                                                }
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5}>
                                    <label className="fLeft">{t('Sub-Contractor POC Email')}:</label>
                                    <div className="popup-input mt-2">
                                        <InputGroup  >
                                            <Form.Control
                                                placeholder={t("Email Id")}
                                                aria-label="Sub-Contractor POC Emai"
                                                aria-describedby="basic-addon1"
                                                value={subContractingCompanyEmail}
                                                disabled={!isSubtractingCompanyEnabled}
                                                onChange={(e) =>
                                                    setsubContractingCompanyEmail(e.target.value)
                                                }
                                            />
                                        </InputGroup>
                                    </div>



                                </Col>
                            </Row>




                        </Row>
                        <Row style={{ paddingBottom: '15px' }}>
                            <Col md={7}></Col>
                            <Col md={4}>



                                <Row>
                                    <Col md={6}>

                                        <Link to="/CompanyList"> 
                                        {/* <Button className='cancel-button m-2' variant="light" >{t('Cancel')} </Button> */}
                                        <Button className='cancel-button btn btn-light m-2'  >{t('Cancel')} </Button>
                                        </Link>

                                    </Col>
                                    <Col md={6}>
                                        <Button className='add-button m-2' variant="light" type="submit" onClick={event => onSaveClick(event)} >{t('Save')} </Button>

                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </form>
                </div>

            </div>
            <ToastContainer />

        </div >
    );
}


export default EditCompany;