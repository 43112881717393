import React, { Component } from "react";
import { Router, Routes, Route } from "react-router-dom";
//import components for routing
//role super Admin
import Login from "./component/login";

//company screens
import CompanyList from "./component/company/companyList";
import RegistrationEdit from "./component/registration/registrationEdit";
import RegistrationView from "./component/registration/registrationView";
//registration Screens
import RegistrationList from "./component/registration/registrationList";
//training screens
import EditCompany from "./component/company/editCompany";
import ViewCompany from "./component/company/viewCompany";
import SessionByEmp from "./component/training/reports/sessionByEmp";
import RegistrationAdd from "./component/registration/registrationAdd";

export default class Routers extends Component {
    render() {
        let loginAs = sessionStorage.getItem('loginAs');
        return (
            <Routes>

                <>
                {loginAs == 2 && <Route exact path="/" element={<RegistrationList />} />}
                {loginAs == 3 && <Route exact path="/" element={<CompanyList />} />}
                     {/* company */}
                    <Route exact path="/companyList" element={<CompanyList />} />                  
                    <Route exact path="/companyList" element={<CompanyList />} />
                    <Route exact path="/editCompany" element={<EditCompany />} /> 
                    <Route exact path="/viewCompany" element={<ViewCompany />} /> 
                    

                     {/* company */}
                    {/* registration */}
                    <Route exact path="/registerList" element={<RegistrationList />} />
                    <Route exact path="/editRegistration" element={<RegistrationEdit />} />
                    <Route exact path="/viewRegistration" element={<RegistrationView />} />
                    <Route exact path="/addRegistration" element={<RegistrationAdd />} />
                    {/* registration */}
                    {/* training */}                    
                    <Route exact path="/trainingList/session-by-emp" element={<SessionByEmp />} />                    
                    {/* training */}
                </>

            </Routes>
        )
    }
}
