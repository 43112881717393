import React, { useEffect, useRef, useState } from "react";
import { Form, Dropdown, FormLabel, InputGroup, Table } from "react-bootstrap";
import { Link, useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Button, Row, Col, Container } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import { set, useForm } from "react-hook-form";
import Popup from "../popup";
import {
  getTableManagementData,
  updateTableData,
  addTableData,
} from "../../services/api";
import { updateVisitorsData, getVisitorSkills, getAvailableFunctions, submitEditRegistration } from '../../services/api';
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import FileResizer from "react-image-file-resizer";
import { useMsal } from "@azure/msal-react";
//importing translation
import { useTranslation } from "react-i18next";

import moment from "moment";


//importing styles
import "../../css/table.css";
import "../../css/form.css";
import "../../css/admin.css";
import "../../css/business.css";
import { StrictMode } from "react";

const CryptoJS = require("crypto-js");

let formattedData;
let updatedSkills;
let header = "";
function RegistrationEdit() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  let editRegistrationData = location.state.data;
  const encryptKey = 'vmsuat1234';
  const navigate = useNavigate();


  const [selectedRole, setSelectedRole] = useState(t(editRegistrationData.functionName) == t("Collaborator") ? "Worker" : t(editRegistrationData.functionName) == t("Business Manager") ||
    t(editRegistrationData.functionName) == t("HSE Manager") || t(editRegistrationData.functionName) == t("Leader") || t(editRegistrationData.functionName) == t("Manager on site") ? "Manager" : "")

  // --------Decrypting datas-----------
  let dcGovId = null;
  if (editRegistrationData.govtId != '') {
    const key = 'visitormsuat1234';
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);
    let dcGovIds = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Base64.parse(editRegistrationData.govtId) },
      keyutf,
      {
        iv: iv
      });

    dcGovId = CryptoJS.enc.Utf8.stringify(dcGovIds)
  }
  let dcAuthStartDate
  if (editRegistrationData.authCHStartDate != null && editRegistrationData.authCHStartDate != "") {
    dcAuthStartDate = CryptoJS.AES.decrypt(editRegistrationData.authCHStartDate, encryptKey);
    dcAuthStartDate = dcAuthStartDate.toString(CryptoJS.enc.Utf8);
    dcAuthStartDate = dcAuthStartDate.replaceAll('"', '')
    dcAuthStartDate = moment(dcAuthStartDate).format("MM/DD/YYYY")

    // dcAuthStartDate = dcAuthStartDate.slice(6, 8) + "/" + dcAuthStartDate.slice(9, 11) + "/" + dcAuthStartDate.slice(1, 5)

  } else {

    dcAuthStartDate = ""
  }

  let dcAuthEndDate
  if (editRegistrationData.authCHEndDate != null && editRegistrationData.authCHEndDate != "") {
    dcAuthEndDate = CryptoJS.AES.decrypt(editRegistrationData.authCHEndDate, encryptKey);
    dcAuthEndDate = dcAuthEndDate.toString(CryptoJS.enc.Utf8);
    dcAuthEndDate = dcAuthEndDate.replaceAll('"', '')
    dcAuthEndDate = moment(dcAuthEndDate).format("MM/DD/YYYY")
    // dcAuthEndDate = dcAuthEndDate.slice(6, 8) + "/" + dcAuthEndDate.slice(9, 11) + "/" + dcAuthEndDate.slice(1, 5)

  } else {

    dcAuthEndDate = ""
  }

  let dcDOB
  if (editRegistrationData.dateOfBirth != null && editRegistrationData.dateOfBirth != "") {
    dcDOB = CryptoJS.AES.decrypt(editRegistrationData.dateOfBirth, encryptKey);
    dcDOB = dcDOB.toString(CryptoJS.enc.Utf8);
    // debugger;

    dcDOB = dcDOB.replaceAll('"', '')
    dcDOB = moment(dcDOB).format("MM/DD/YYYY")
    // dcDOB = dcDOB.slice(6, 8) + "/" + dcDOB.slice(9, 11) + "/" + dcDOB.slice(1, 5)

  } else {

    dcDOB = ""
  }

  let dcYearsOfExp
  if (editRegistrationData.yearsOfExperience != null && editRegistrationData.yearsOfExperience != "") {
    dcYearsOfExp = CryptoJS.AES.decrypt(editRegistrationData.yearsOfExperience, encryptKey);
    dcYearsOfExp = dcYearsOfExp.toString(CryptoJS.enc.Utf8);
    dcYearsOfExp = dcYearsOfExp.replaceAll('"', '')
    dcYearsOfExp = moment(dcYearsOfExp).format("MM/DD/YYYY")
    // dcYearsOfExp = dcYearsOfExp.slice(6, 8) + "/" + dcYearsOfExp.slice(9, 11) + "/" + dcYearsOfExp.slice(1, 5)

  } else {

    dcYearsOfExp = ""
  }
  let workStartDateERD
  if (editRegistrationData.workStartDate != null && editRegistrationData.workStartDate != "") {
    workStartDateERD = editRegistrationData.workStartDate.replaceAll('"', '')
    workStartDateERD = moment(workStartDateERD).format("MM/DD/YYYY")

  } else {
    workStartDateERD = ""
  }








  // --------Decrypting datas-----------
  const [erdN1File, setErdN1File] = useState(editRegistrationData.proofOfN1ChemicalRiskAuthPath)
  const [erdATEXFile, setErdATEXFile] = useState(editRegistrationData.proofOfATEXAuthPath)
  const [erdSCBAFile, setErdSCBAFile] = useState(editRegistrationData.proofOfSCBAMaskAuthPath)
  const [erdHarnessFile, setErdHarnessFile] = useState(editRegistrationData.proofOfHarnessAuthPath)

  const [selectedFunction, setSelectedFunction] = useState('')
  const [selectedHours, setSelectedHours] = useState(editRegistrationData.shiftId == 1 ? 'Normal Working Hours' : 'Shift Working Hours')
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [dobDate, setDobDate] = useState((editRegistrationData.dateOfBirth != null && editRegistrationData.dateOfBirth != "") ? new Date(dcDOB) : "");
  const [workStartDate, setWorkStartDate] = useState((editRegistrationData.workStartDate != null && editRegistrationData.workStartDate != "") ? new Date(workStartDateERD) : "");
  const [yearsOfExpDate, setYearsOfExpDate] = useState((editRegistrationData.yearsOfExperience != null && editRegistrationData.yearsOfExperience != "") ? new Date(dcYearsOfExp) : "");
  const [startDate, setStartDate] = useState((editRegistrationData.authCHStartDate != null && editRegistrationData.authCHStartDate != "") ? new Date(dcAuthStartDate) : "");
  const [endDate, setEndDate] = useState((editRegistrationData.authCHEndDate != null && editRegistrationData.authCHEndDate != "") ? new Date(dcAuthEndDate) : "");

  const [file, setFile] = useState(null);
  const [photoFile, setPhotoFile] = useState("")
  const [govtIdFile, setGovtIdFile] = useState("")
  const [employeeCvFile, setemployeeCvFile] = useState("")
  const [proofOfAuthFile, setproofOfAuthFile] = useState("")
  const [proofOfExSituFile, setproofOfExSituFile] = useState("")
  const [proofOfMFile, setproofOfMFile] = useState("")
  const [proofOfSCBAFile, setproofOfSCBAFile] = useState("")
  const [proofOfATEXFile, setproofOfATEXFile] = useState("")
  const [proofOfHarnessFile, setproofOfHarnessFile] = useState("")
  const [proofOfN1File, setproofOfN1File] = useState("")
  const [skills, setSkills] = useState([]);
  const [availableFunctions, setAvailableFunctions] = useState([]);
  const [availableFunctionsWorker, setAvailableFunctionsWorker] = useState([]);
  const [isSelected, setIsSelected] = useState("")
  const [savedStatus, setSavedStatus] = useState(false)

  const { instance } = useMsal();


  const functionOptions = selectedRole === 'Worker'
    ? availableFunctionsWorker
    : availableFunctions;
  const getAvlFunction = (locationId) => {
    let mounted = true;
    let culture = 'fr-CH'
    if (i18n.language == 'en') {
      culture = 'en-US'
    }
    if (i18n.language == 'de') {
      culture = 'de-DE'
    }

    getAvailableFunctions(1, culture, instance)
      .then(items => {

        if (mounted) {
          setAvailableFunctions(items.managerFunctions);
          setAvailableFunctionsWorker(items.workerFunctions);
          return () => mounted = false;
        }


      })
  }

  const calculate_experience = (dob) => {
    var today = new Date();
    var yoe = new Date(dob);  // create a date object directly from `dob` argument
    var age_now = today.getFullYear() - yoe.getFullYear();
    var m = today.getMonth() - yoe.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < yoe.getDate())) {
      age_now--;
    }

    return age_now;
  }

  const getSkills = () => {
    let mounted = true;
    const visitorId = editRegistrationData.userId
    getVisitorSkills(visitorId, instance)

      .then(items => {
        if (mounted) {
          setSkills(items);
          //setAvailableFunctionsWorker(items.Collaborator);
          // skills-------------

          let filteredData = items.filter(item => item.documentPath !== "");

          formattedData = filteredData.map(item => ({
            "visitorSkillTypeId": item.id,
            "skillType": item.skillName,
            "documentPathEncoded": "",
            "documentPath": item.documentPath
          }));

          // skills-------------
          return () => mounted = false;
        }


      })
  }



  const handleSelect2 = (selectedId, value, name) => {
    if (value === "Yes") {
      document.getElementById(selectedId + 'fileinput').disabled = false;
      document.getElementById(selectedId + 'fileup').style.background = "none";
      // document.getElementById(selectedId + 'file-icon').style.background = "block";

    } else if (value === "No") {
      document.getElementById(selectedId + 'fileinput').disabled = true;
      document.getElementById(selectedId + 'fileup').style.background = "#e9ecef";
      document.getElementById(selectedId + 'fileinput').value = ""
      //  document.getElementById(selectedId + 'file-icon').style.background = "none";

    }

    if (value === "No" && proofOfN1File != "" && name === "N1chemical") {
      setproofOfN1File("")
    } else if (value === "No" && proofOfATEXFile != "" && name === "ATEX") {
      setproofOfATEXFile("")
    } else if (value === "No" && proofOfSCBAFile != "" && name === "SCBAmask") {
      setproofOfSCBAFile("")
    } else if (value === "No" && proofOfHarnessFile != "" && name === "harness") {
      setproofOfHarnessFile("")
    }

    if (value === "No" && erdN1File != "" && name === "N1chemical") {
      setErdN1File("")
    } else if (value === "No" && erdATEXFile != "" && name === "ATEX") {
      setErdATEXFile("")
    } else if (value === "No" && erdSCBAFile != "" && name === "SCBAmask") {
      setErdSCBAFile("")
    } else if (value === "No" && erdHarnessFile != "" && name === "harness") {
      setErdHarnessFile("")
    }

    if (value === "Yes" && erdN1File == "" && name === "N1chemical") {
      setErdN1File(editRegistrationData.proofOfN1ChemicalRiskAuthPath)
    } else if (value === "Yes" && erdATEXFile == "" && name === "ATEX") {
      setErdATEXFile(editRegistrationData.proofOfATEXAuthPath)
    } else if (value === "Yes" && erdSCBAFile == "" && name === "SCBAmask") {
      setErdSCBAFile(editRegistrationData.proofOfSCBAMaskAuthPath)
    } else if (value === "Yes" && erdHarnessFile == "" && name === "harness") {
      setErdHarnessFile(editRegistrationData.proofOfHarnessAuthPath)
    }



  }

  const handleSelect = (selectedId, value, skillList, skillName, excDocPath) => {
    if (value === "Yes") {
      document.getElementById(selectedId + 'fileinput').disabled = false;
      document.getElementById(selectedId + 'fileup').style.background = "none";
      // document.getElementById(selectedId + 'file-icon').style.background = "block";

    } else if (value === "No") {
      document.getElementById(selectedId + 'fileinput').disabled = true;
      document.getElementById(selectedId + 'fileup').style.background = "#e9ecef";
      document.getElementById(selectedId + 'fileinput').value = ""

      //  document.getElementById(selectedId + 'file-icon').style.background = "none";

    }

    if (value === "Yes" && excDocPath != "") {
      for (let i in formattedData) {
        if (formattedData[i].visitorSkillTypeId === selectedId) {
          formattedData[i].documentPath = excDocPath;
          return formattedData;
        }

      }
    }

    if (value === "Yes") {
      let skillDetails = [{
        "visitorSkillTypeId": selectedId,
        "skillType": skillName,
        // "documentPath": "Lift Driver License Path",
        // "documentPathEncoded":"liftDriver.pdf"
      }]
      setSelectedSkills(selectedSkills.concat(skillDetails))
    }

    if (value === "No") {

      for (let i in formattedData) {
        if (formattedData[i].visitorSkillTypeId === selectedId) {

          formattedData[i].documentPath = "";
          formattedData[i].documentPathEncoded = "";
          return formattedData;
        }

      }
      for (let j in selectedSkills) {
        if (selectedSkills[j].visitorSkillTypeId === selectedId) {

          selectedSkills[j].documentPath = "";
          selectedSkills[j].documentPathEncoded = "";
          return selectedSkills;
        }

      }

    }

  }


  const resizeFile = (file) => new Promise(resolve => {
    // const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
    const allowedTypes = ['PDF', 'JPEG', 'JPG', 'PNG'];

    FileResizer.imageFileResizer(file, 600, 830, allowedTypes, 100, 0,
      uri => {
        resolve(uri);
      }, 'base64');
  });
  const resizePhoto = (file) => new Promise(resolve => {

    FileResizer.imageFileResizer(file, 480, 640, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'base64');
  });

  const handleFileChange = async (e, docName) => {
    const selectedFile = e.target.files[0];

    // mime check 
    var fileReader = new FileReader();
    fileReader.onloadend = async function (event) {
      var arr = (new Uint8Array(event.target.result)).subarray(0, 4);

      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      console.log(header, "header in fun");
      // Check the file signature against known types
      // let type;
      // switch (header) {
      //   case "89504e47":
      //   case "64617461":
      //     type = "image/png";
      //     header = ""
      //     break;
      //   case "25504446":
      //   case "255044462D":
      //     type = "application/pdf";
      //     header = ""
      //     break;
      //   case "ffd8ffe0":
      //   case "ffd8ffe1":
      //   case "ffd8ffe2":
      //   case "ffd8ffe3":
      //   case "ffd8ffe8":
      //     type = "image/jpeg";
      //     header = ""
      //     break;
      //   default:
      //     type = "unknown"; // Or you can use the blob.type as fallback
      //     toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
      //     uploadFileIsErr = true
      //     e.target.value = null;
      //     selectedFile = null;
      //     header = ""
      //     break;
      // }

      const allowedTypes = ['89504e47', '64617461', '25504446', '255044462D', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'];
      if (!allowedTypes.includes(header)) {
        header = "";
        toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
        e.target.value = null;
        return;
      }
      const selectedFile = e.target.files[0];
      if (allowedTypes.includes(header)) {
        header = "";
        if (selectedFile) {


          // const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
          // if (!allowedTypes.includes(selectedFile.type)) {
          //   toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
          //   e.target.value = null;
          //   return;
          // }
         
            const maxSizeInBytes = 4 * 1024 * 1024;
            const maxNormalFileSizeInByte = 1024 * 1024
            if (selectedFile.size > maxSizeInBytes && e.target.files[0].type != 'application/pdf') {
              toast.error(t('File size exceeds 4 MB limit.'))
              e.target.value = null;
              return;
            }
            if (docName === "photoPath" && e.target.files[0].type != 'image/jpeg') {
              toast.error(t('Please upload only .jpg format.'))
              e.target.value = null;
            }

            let base64Content
            if (selectedFile.size > maxNormalFileSizeInByte && e.target.files[0].type != 'application/pdf') {
              const file = e.target.files[0];
              let documentData
              if (e.target.name === "photoPath") {
                documentData = await resizePhoto(file)
              } else {
                documentData = await resizeFile(file);
              }
              // Photo width & height validation
              // if (e.target.name === "photoPath") {
              //   const image = new Image();
              //   image.src = documentData;

              //   image.onload = function () {
              //     const width = this.width;
              //     const height = this.height;
              //     // setImageDimensions({ width, height });

              //     if (width > 480 || height > 640) {
              //       toast.error(t('Please upload an image with equal to or less than 480px width and 640px height.'));
              //       e.target.value = null;
              //       return;
              //     }
              //   };
              // }
              // Photo width & height validation end
              base64Content = documentData.split(',')[1];
              setFile(selectedFile);
              if (e.target.name === "photoPath") {
                setPhotoFile(base64Content);
              } else if (e.target.name === "govtIdPath") {
                setGovtIdFile(base64Content);
              } else if (e.target.name === "employeeCVPath") {
                setemployeeCvFile(base64Content);
              } else if (e.target.name === "proofOfAuthorization") {
                setproofOfAuthFile(base64Content);
              } else if (e.target.name === "proofOfExSituTrainingCompletedPath") {
                setproofOfExSituFile(base64Content);
              } else if (e.target.name === "proofOfMedicalClearance") {
                setproofOfMFile(base64Content);
              } else if (e.target.name === "proofOfSCBAMaskAuthPath") {
                setproofOfSCBAFile(base64Content);
              } else if (e.target.name === "proofOfATEXAuthPath") {
                setproofOfATEXFile(base64Content);
              } else if (e.target.name === "proofOfHarnessAuthPath") {
                setproofOfHarnessFile(base64Content);


              } else if (e.target.name === "proofOfN1ChemicalRiskAuthPath") {
                setproofOfN1File(base64Content);
              }
            }
            else {
              const maxPdfFileSizeInByte = 1.5 * 1024 * 1024
              if (selectedFile.size > maxPdfFileSizeInByte && e.target.files[0].type === 'application/pdf') {
                toast.error(t('File size exceeds 1.5 MB limit.'))
                e.target.value = null;
                return;
              }
              const file = e.target.files[0];
              let documentData
              if (e.target.name === "photoPath") {
                documentData = await resizePhoto(file)
              }
              const reader = new FileReader();
              reader.onload = (event) => {
                // Photo width & height validation
                // if (e.target.name === "photoPath") {
                //   const image = new Image();
                //   image.src = event.target.result;

                //   image.onload = function () {
                //     const width = this.width;
                //     const height = this.height;
                //     // setImageDimensions({ width, height });

                //     if (width > 480 || height > 640) {
                //       toast.error(t('Please upload an image with equal to or less than 480px width and 640px height.'));
                //       e.target.value = null;
                //       return;
                //     }
                //   };
                // }
                // Photo width & height validation end 
                // const base64Content = documentData.split(',')[1];

                if (e.target.name === "photoPath") {
                  base64Content = documentData.split(',')[1];
                } else {
                  base64Content = event.target.result.split(',')[1];
                }


                setFile(selectedFile);
                if (e.target.name === "photoPath") {
                  setPhotoFile(base64Content);
                } else if (e.target.name === "govtIdPath") {
                  setGovtIdFile(base64Content);
                } else if (e.target.name === "employeeCVPath") {
                  setemployeeCvFile(base64Content);
                } else if (e.target.name === "proofOfAuthorization") {
                  setproofOfAuthFile(base64Content);
                } else if (e.target.name === "proofOfExSituTrainingCompletedPath") {
                  setproofOfExSituFile(base64Content);
                } else if (e.target.name === "proofOfMedicalClearance") {
                  setproofOfMFile(base64Content);
                } else if (e.target.name === "proofOfSCBAMaskAuthPath") {
                  setproofOfSCBAFile(base64Content);
                } else if (e.target.name === "proofOfATEXAuthPath") {
                  setproofOfATEXFile(base64Content);
                } else if (e.target.name === "proofOfHarnessAuthPath") {
                  setproofOfHarnessFile(base64Content);


                } else if (e.target.name === "proofOfN1ChemicalRiskAuthPath") {
                  setproofOfN1File(base64Content);
                }



              };
              reader.readAsDataURL(selectedFile)
            }

          

        }
      }


    };
    fileReader.readAsArrayBuffer(selectedFile);
    // mime check end
  }
  const handleFileChangeDynamic = async (e, id, oldDocPath) => {
    const selectedFile = e.target.files[0];
    // mime check 
    var fileReader = new FileReader();
    fileReader.onloadend = async function (event) {
      var arr = (new Uint8Array(event.target.result)).subarray(0, 4);

      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      console.log(header, "header in fun");
      // Check the file signature against known types

      const allowedTypes = ['89504e47', '64617461', '25504446', '255044462D', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8'];
      if (!allowedTypes.includes(header)) {
        header = "";
        toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
        e.target.value = null;
        return;
      }
      const selectedFile = e.target.files[0];
      if (allowedTypes.includes(header)) {
        header = "";
        if (selectedFile) {
          // const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
          // if (!allowedTypes.includes(selectedFile.type)) {
          //   toast.error(t('Please upload a valid PDF or JPEG or JPG or PNG file'));
          //   e.target.value = null;
          //   return;
          // }
          const maxSizeInBytes = 4 * 1024 * 1024;
          const maxNormalFileSizeInByte = 1024 * 1024
          if (selectedFile.size > maxSizeInBytes && e.target.files[0].type != 'application/pdf') {
            toast.error(t('File size exceeds 4 MB limit.'))
            e.target.value = null;
            return;
          }
          let base64Content
          if (selectedFile.size > maxNormalFileSizeInByte && e.target.files[0].type != 'application/pdf') {
            console.log("if", e.target.files[0].type)
            const file = e.target.files[0];
            const documentData = await resizeFile(file);
            base64Content = documentData.split(',')[1];

            document.getElementById(id + 'fileinput').style.opacity = null
            setFile(selectedFile);
            let skillsArray = selectedSkills
            selectedSkills.forEach((element, elementId) => {

              if (element.visitorSkillTypeId == id) {
                skillsArray[elementId].documentPathEncoded = CryptoJS.AES.encrypt(base64Content, encryptKey).toString()
                skillsArray[elementId].documentPath = selectedFile.name
                setSelectedSkills(skillsArray)
              }
            });

            // --------update skills---------
            if (oldDocPath != "") {
              for (let i in formattedData) {

                if (formattedData[i].visitorSkillTypeId === id) {

                  formattedData[i].documentPath = selectedFile.name;
                  formattedData[i].documentPathEncoded = CryptoJS.AES.encrypt(base64Content, encryptKey).toString();
                  // return formattedData;

                }

              }
            }
            // -------- update skills---------

          } else {
            const maxPdfFileSizeInByte = 1.5 * 1024 * 1024
            if (selectedFile.size > maxPdfFileSizeInByte && e.target.files[0].type === 'application/pdf') {
              toast.error(t('File size exceeds 1.5 MB limit.'))
              e.target.value = null;
              return;
            }
            console.log("else")
            const reader = new FileReader();
            reader.onload = (event) => {

              base64Content = event.target.result.split(',')[1];

              document.getElementById(id + 'fileinput').style.opacity = null
              setFile(selectedFile);
              let skillsArray = selectedSkills
              selectedSkills.forEach((element, elementId) => {

                if (element.visitorSkillTypeId == id) {
                  skillsArray[elementId].documentPathEncoded = CryptoJS.AES.encrypt(base64Content, encryptKey).toString();
                  skillsArray[elementId].documentPath = selectedFile.name
                  setSelectedSkills(skillsArray)
                }
              });

              // --------update skills---------
              if (oldDocPath != "") {
                for (let i in formattedData) {

                  if (formattedData[i].visitorSkillTypeId === id) {

                    formattedData[i].documentPath = selectedFile.name;
                    formattedData[i].documentPathEncoded = CryptoJS.AES.encrypt(base64Content, encryptKey).toString();
                    // return formattedData;

                  }

                }
              }
              // -------- update skills---------

            };
            reader.readAsDataURL(selectedFile)
          }

        }
      }


    };
    fileReader.readAsArrayBuffer(selectedFile);
  }

  const handleChangeSelect = event => {
    setSelectedRole(event.target.value);
    if (selectedRole != "Manager") {
      toast.info(t("Please Select The Function !"));
    }
  };


  useEffect(() => {
    registerLocale("en-GB", enGB)
    getSkills();
    getAvlFunction();


  }, [])
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors }
  } = useForm();
  console.log(editRegistrationData, "ERD")


  const onSubmitForm = (data, e) => {
    e.preventDefault();
    // skills---------------------
    updatedSkills = [...formattedData]

    selectedSkills.forEach((obj2) => {
      const index = updatedSkills.findIndex((obj1) => obj1.visitorSkillTypeId === obj2.visitorSkillTypeId);
      if (index != -1) {
        updatedSkills[index] = obj2
      } else {
        updatedSkills.push(obj2)
      }
    })
    // skills---------------------


    // debugger
    let encryptDob
    if (dobDate != null && dobDate != "") {
      encryptDob = moment(dobDate).format("MM/DD/YYYY")
      encryptDob = CryptoJS.AES.encrypt(encryptDob, encryptKey).toString()
    } else {
      encryptDob = null
    }
    let encryptAuthStartDate
    if (startDate != null && startDate != "") {
      encryptAuthStartDate = moment(startDate).format("MM/DD/YYYY")
      encryptAuthStartDate = CryptoJS.AES.encrypt(encryptAuthStartDate, encryptKey).toString()
    } else {
      encryptAuthStartDate = null
    }
    let encryptEndDate
    if (endDate != null && endDate != "") {
      encryptEndDate = moment(endDate).format("MM/DD/YYYY")
      encryptEndDate = CryptoJS.AES.encrypt(encryptEndDate, encryptKey).toString()
    } else {
      encryptEndDate = null
    }
    let encryptYearOfExp
    if (yearsOfExpDate != null && yearsOfExpDate != "") {
      encryptYearOfExp = moment(yearsOfExpDate).format("MM/DD/YYYY")
      encryptYearOfExp = CryptoJS.AES.encrypt(encryptYearOfExp, encryptKey).toString()
    } else {
      encryptYearOfExp = null
    }
    let gId = null;
    if (data["govtId"]) {
      const key = 'visitormsuat1234';
      const keyutf = CryptoJS.enc.Utf8.parse(key);
      const iv = CryptoJS.enc.Base64.parse(key);
      const enc = CryptoJS.AES.encrypt(data["govtId"], keyutf, { iv: iv });
      gId = enc.toString();
    }

    let workStdate;
    if (workStartDate != null && workStartDate != "") {
      workStdate = moment(workStartDate).format("YYYY-MM-DD")
    } else {
      workStdate = null
    }

    let VisiterFunIdERD
    if (editRegistrationData.functionName === "Collaborator") {
      VisiterFunIdERD = 3
    } else if (editRegistrationData.functionName === "Business Manager") {
      VisiterFunIdERD = 2
    } else if (editRegistrationData.functionName === "HSE Manager") {
      VisiterFunIdERD = 4
    } else if (editRegistrationData.functionName === "Leader") {
      VisiterFunIdERD = 5
    } else if (editRegistrationData.functionName === "Manager on site") {
      VisiterFunIdERD = 6
    }

    //  photoPath jpeg to jpg name convertion
    let renamePhotoPath;
    if (data.photoPath[0]?.name != "" && data.photoPath[0]?.name != undefined) {
      const originalPhotoPath = data.photoPath[0]?.name
      const dotIndex = originalPhotoPath.lastIndexOf('.');
      const fileNameWithoutExtension = originalPhotoPath.slice(0, dotIndex);
      renamePhotoPath = fileNameWithoutExtension + '.jpg';
    } else {
      renamePhotoPath = ""
    }

    let ERDrenamePhotoPath
    if (editRegistrationData.photoPath != "") {
      const ERDoriginalPhotoPath = editRegistrationData.photoPath
      const ERDdotIndex = ERDoriginalPhotoPath.lastIndexOf('.');
      const ERDfileNameWithoutExtension = ERDoriginalPhotoPath.slice(0, ERDdotIndex);
      ERDrenamePhotoPath = ERDfileNameWithoutExtension + '.jpg';
    } else {
      ERDrenamePhotoPath = ""
    }
    //  photoPath jpeg to jpg name convertion end 

    let Data = {
      "userId": editRegistrationData.userId,
      "companyId": editRegistrationData.companyId,
      // "dateOfBirth": dobDate,
      "dateOfBirth": (dobDate != null && dobDate != "") ? encryptDob : null,
      "firstName": data["firstName"],
      "lastName": data["lastName"],
      "locationId": 1,
      // "govtId": data["govtId"],
      "govtId": data["govtId"] ? gId : "",
      //"visitorFunctionId": 4,
      "visitorFunctionId": selectedRole == 'Worker' ? 3 : (data["visitorFunctionId"] === "" ? VisiterFunIdERD : parseInt(data["visitorFunctionId"])),
      "IsManager": selectedRole == "Manager" ? true : false,
      // "visitorContractorTypeId": 3,
      "visitorContractorTypeId": data["visitorContractorTypeId"] === "CDI" ? 1 : 2,
      "shiftId": selectedHours === "Normal Working Hours" ? 1 : 2,
      "approvalStatusId": (editRegistrationData.approvalStatus === "Rejected" || editRegistrationData.approvalStatus === "Rejeté") ? 3 : 1,
      //"isSubmitted": null,
      "varoPointOfContact": data["varoPointOfContact"],
      "workStartDate": (workStartDate != null && workStartDate !== "") ? workStdate : null,
      "languageKnownId": parseInt(data["languageKnownId"]),
      // "authCHStartDate": startDate,
      "authCHStartDate": (startDate != null && startDate != "") ? encryptAuthStartDate : null,
      // "authCHEndDate": endDate,
      "authCHEndDate": (endDate != null && endDate != "") ? encryptEndDate : null,
      // "yearsOfExperience": editRegistrationData.yearsOfExperience,
      "yearsOfExperience": (yearsOfExpDate != null && yearsOfExpDate != "") ? encryptYearOfExp : null,
      // "yearsOfExperience": calculate_experience(yearsOfExpDate),
      "skills": updatedSkills,
      "photoPath": data.photoPath && data.photoPath[0] ? renamePhotoPath : ERDrenamePhotoPath,
      "photoEncoded": photoFile ? CryptoJS.AES.encrypt(photoFile, encryptKey).toString() : "",
      "govtIdPath": data.govtIdPath[0] == undefined ? editRegistrationData.govtIdPath : data.govtIdPath[0]?.name,
      "govtIdEncoded": govtIdFile ? CryptoJS.AES.encrypt(govtIdFile, encryptKey).toString() : "",
      "employeeCVPath": selectedRole == 'Worker' ? null : (data.employeeCVPath && data.employeeCVPath[0] ? data.employeeCVPath[0]?.name : editRegistrationData.employeeCVPath),
      "employeeCVEncoded": selectedRole == 'Worker' ? null : (employeeCvFile ? CryptoJS.AES.encrypt(employeeCvFile, encryptKey).toString() : ""),
      "proofOfAuthorization": data.proofOfAuthorization && data.proofOfAuthorization[0] ? data.proofOfAuthorization[0]?.name : editRegistrationData.proofOfAuthorization,
      "proofOfAuthorizationEncoded": proofOfAuthFile ? CryptoJS.AES.encrypt(proofOfAuthFile, encryptKey).toString() : "",
      "proofOfExSituTrainingCompletedPath": data.proofOfExSituTrainingCompletedPath && data.proofOfExSituTrainingCompletedPath[0] ? data.proofOfExSituTrainingCompletedPath[0]?.name : editRegistrationData.proofOfExSituTrainingCompletedPath,
      "ProofOfExSituTrainingCompletedEncoded": proofOfExSituFile ? CryptoJS.AES.encrypt(proofOfExSituFile, encryptKey).toString() : "",
      "proofOfMedicalClearance": data.proofOfMedicalClearance && data.proofOfMedicalClearance[0] ? data.proofOfMedicalClearance[0]?.name : editRegistrationData.proofOfMedicalClearance,
      "proofOfMedicalClearanceEncoded": proofOfMFile ? CryptoJS.AES.encrypt(proofOfMFile, encryptKey).toString() : "",
      "proofOfSCBAMaskAuthPath": data.proofOfSCBAMaskAuthPath && data.proofOfSCBAMaskAuthPath[0] ? data.proofOfSCBAMaskAuthPath[0]?.name : erdSCBAFile,
      "proofOfSCBAMaskAuthEncoded": proofOfSCBAFile ? CryptoJS.AES.encrypt(proofOfSCBAFile, encryptKey).toString() : "",
      "proofOfATEXAuthPath": data.proofOfATEXAuthPath && data.proofOfATEXAuthPath[0] ? data.proofOfATEXAuthPath[0]?.name : erdATEXFile,
      "proofOfATEXAuthEncoded": proofOfATEXFile ? CryptoJS.AES.encrypt(proofOfATEXFile, encryptKey).toString() : "",
      "proofOfHarnessAuthPath": data.proofOfHarnessAuthPath && data.proofOfHarnessAuthPath[0] ? data.proofOfHarnessAuthPath[0]?.name : erdHarnessFile,

      "proofOfHarnessAuthEncoded": proofOfHarnessFile ? CryptoJS.AES.encrypt(proofOfHarnessFile, encryptKey).toString() : "",
      "proofOfN1ChemicalRiskAuthPath": data.proofOfN1ChemicalRiskAuthPath && data.proofOfN1ChemicalRiskAuthPath[0] ? data.proofOfN1ChemicalRiskAuthPath[0]?.name : erdN1File,
      "proofOfN1ChemicalRiskAuthEncoded": proofOfN1File ? CryptoJS.AES.encrypt(proofOfN1File, encryptKey).toString() : "",
    }
    onSaveClick(Data);
  }
  const onSaveClick = (data) => {


    updateFunc(data);
  };

  const updateFunc = (data) => {
    let culture = 'fr-CH'
    if (i18n.language == 'en') {
      culture = 'en-US'
    }
    if (i18n.language == 'de') {
      culture = 'de-DE'
    }
    updateVisitorsData(data, culture, instance)
      .then(data => {
        // console.log(data, "datadata")
        if (data['status'] === 400) {
          toast.error(<p style={{ color: "red" }}>HTTP Error 400 : Bad Request</p>)
        } else if (data['status'] === 404) {
          toast.error(<p style={{ color: "red" }}>HTTP Error 404 : Not Found</p>)
        } else if (data['status'] === 500) {
          toast.error(<p style={{ color: "red" }}> {data['detail']}</p>)
        } else if (data['successMessage']) {
          toast.success(data['successMessage']);
          setSavedStatus(true)
          setTimeout(() => navigate('/registerList'), 2000);

          // window.location.href = '#/security/logout';
          // window.location.reload();
          //  getTableDataFunc();
        }
        else if (data['errorMessage'] != null) {
          toast.error(data['errorMessage']);
        } else {
          // toast.error(<p style={{ color: "red" }}> {data['value']}</p>)
          toast.error(<p style={{ color: "red" }}> Unknown error Occurred!</p>)


        }
      })
  }

  const onSubmitClick = (data) => {
    let culture = 'fr-CH'
    if (i18n.language == 'en') {
      culture = 'en-US'
    }
    if (i18n.language == 'de') {
      culture = 'de-DE'
    }

    submitEditRegistration(editRegistrationData.userId, culture, instance)
      .then(data => {

        if (data['status'] === 400) {
          toast.error(<p style={{ color: "red" }}>HTTP Error 400 : Bad Request</p>)
        } else if (data['status'] === 404) {
          toast.error(<p style={{ color: "red" }}>HTTP Error 404 : Not Found</p>)
        } else if (data['status'] === 500) {
          toast.error(<p style={{ color: "red" }}> {data['detail']}</p>)
        } else if (data['name'] == "SuccessMessage") {
          toast.success(data['value']);
          //  getTableDataFunc();
        } else if (data) {
          toast.error(<p style={{ color: "red" }}>{data}</p>);

        } else {
          toast.error(<p style={{ color: "red" }}> Unknown error Occurred!</p>)

        }
      })

  };

  return (
    <div className="row rect-row form-row">
      <div className="row second-header">
        <div className="col-5 tittle-div">
          <Row>
            <Col md={8}>
              {" "}
              <Link to="/registerList">
                {" "}
                <button className="addNew" style={{ marginTop: "3px" }}>
                  <span class="material-symbols-outlined" title="clear">
                    arrow_back
                  </span>
                </button>{" "}
              </Link>
              <span className="tittle">{t("Edit Employee")}</span>
            </Col>
            <Col md={1}>
              <span className="vertcal-line"> </span>
            </Col>
          </Row>

        </div>
        {/* <div className="col-7 button-div"></div>
        <div className="col-1"></div> */}
        <div className="col-10 form-col edit-form">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Row className="sub-header pb-2 pt-2 mb-2"></Row>
            <Row className="heading mb-2 mt-2">
              <Row>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft"> {t("Company Name")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <InputGroup>
                      <Form.Control
                        placeholder={t("Enter Company Name")}
                        aria-label="Company Name "
                        aria-describedby="basic-addon1"
                        disabled
                        className=""
                        {...register("CompanyName")}
                        defaultValue={editRegistrationData.companyName}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("EmployeeFname​​")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <InputGroup>
                      <Form.Control
                        placeholder={t("EmployeeFname​​")}
                        aria-label="Name "
                        aria-describedby="basic-addon1"
                        className=""
                        {...register("firstName")}
                        defaultValue={editRegistrationData.firstName}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Dateob​")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <div className="calendar">
                      <DatePicker
                        className="selectpickers"
                        // selectsRange={false}
                        // startDate={startDate}
                        // endDate={endDate}
                        showYearDropdown
                        selected={dobDate}
                        onChange={(date) => {
                          setDobDate(date)
                        }
                        }
                        dateFormat="dd/MM/yyyy"
                        isClearable={true}
                        maxDate={new Date("01/01/2009")}
                      //{...register("WorkStartDate")}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Employee Last Name")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <InputGroup>
                      <Form.Control
                        placeholder={t("Employee Last Name")}
                        aria-label="FirstName "
                        aria-describedby="basic-addon1"
                        className=""
                        {...register("lastName")}
                        defaultValue={editRegistrationData.lastName}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Govt ID")}: <span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <InputGroup>
                      <Form.Control
                        placeholder={t("Govt ID")}
                        aria-label="GovtID"
                        aria-describedby="basic-addon1"
                        className=""
                        {...register("govtId", {
                          required: true,
                          message: "Govt Id Required"
                        })}
                        defaultValue={dcGovId}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Proof of Govt ID")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <input
                          type="file"
                          id="file-upload"
                          className="Upload-excel-input"
                          {...register("govtIdPath"
                          )}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: govtIdFile ? null : 0 }} onChange={(e) => handleFileChange(e)}
                        />
                        <input type="hidden"
                          {...register("govtIdEncoded")}
                          value={govtIdFile} />
                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}

                        </span>
                        {editRegistrationData.govtIdPath &&
                          <span title={editRegistrationData.govtIdPath} className="material-symbols-outlined" >
                            attach_file
                          </span>
                        }

                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("ContactVARO​")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <InputGroup>
                      <Form.Control
                        placeholder={t("ContactVARO​")}
                        aria-label="Name "
                        aria-describedby="basic-addon1"
                        className=""
                        {...register("varoPointOfContact")}
                        defaultValue={editRegistrationData.varoPointOfContact}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Photo")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <input
                          type="file"
                          id="file-upload"
                          className="Upload-excel-input"
                          {...register("photoPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: photoFile ? null : 0 }}
                          onChange={(e) => handleFileChange(e, "photoPath")}
                        />
                        <input type="hidden"
                          {...register("photoEncoded")}
                          value={photoFile} />

                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {editRegistrationData.photoPath &&
                          <span title={editRegistrationData.photoPath
                          } className="material-symbols-outlined" >
                            attach_file
                          </span>
                        }

                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Work Start Date")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <div className="popup-input mt-2">
                    <div className="calendar">
                      <DatePicker
                        className="selectpickers"
                        // selectsRange={false}
                        // startDate={startDate}
                        // endDate={endDate}
                        showYearDropdown
                        selected={workStartDate}
                        onChange={(date) => {
                          setWorkStartDate(date)
                        }
                        }
                        dateFormat="dd/MM/yyyy"
                        isClearable={true}
                        minDate={new Date()}
                      //{...register("WorkStartDate")}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Type of Contract")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <select className="heading mb-2 mt-2  selectpicker"
                          {...register("visitorContractorTypeId")}
                          defaultValue={editRegistrationData.contractType}
                        >
                          <option>{t("Select")}</option>
                          <option>CDI</option>
                          <option>CDD</option>
                        </select>
                      </div>

                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <label className="fLeft">{t("Role")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                      <div style={{ display: 'flex', gap: '20px' }}>
                        <input type="radio" id="Worker" name="role" value="Worker" checked={selectedRole == 'Worker'} onChange={handleChangeSelect}
                        />
                        <label for="Worker">{t("Worker")}</label>


                        <input type="radio" id="Manager" name="role" value="Manager" checked={selectedRole == 'Manager'} onChange={handleChangeSelect}

                        />
                        <label for="Manager">{t("Manager")}</label>
                      </div>

                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <label className="fLeft">{t("Work Shift")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                      <div style={{ display: 'flex', gap: '8px' }}>
                        <input type="radio" id="normalShift" name="shift" value="Normal Working Hours" checked={selectedHours === 'Normal Working Hours'}
                          onChange={() => setSelectedHours('Normal Working Hours')}

                        />
                        <label htmlFor="normalalRadio">{t("Normal Working Hours")}</label>


                        <input type="radio" id="workerShift" name="shift" value="Shift Working Hours" checked={selectedHours === 'Shift Working Hours'}
                          onChange={() => setSelectedHours('Shift Working Hours')}

                        />
                        <label htmlFor="workerRadio">{t("Shift Working Hours")}</label>

                      </div>

                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Function")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>

                      <div className="popup-Dropdown">
                        <select className="heading mb-2 mt-2  selectpicker"
                          //value={selectedFunction}
                          onChange={(e) => {
                            setSelectedFunction(e.target.value);
                          }}

                          {...register("visitorFunctionId")}
                          defaultValue={editRegistrationData.functionName}
                        // disabled={selectedRole==='Worker'}
                        >

                          {/* <option value="">{t("Select")}</option> */}
                          {selectedRole != 'Worker' ? <option value="">{t("Select")}</option> : <></>}
                          {functionOptions.map((option, index) => (

                            <option value={option.id} selected={t(editRegistrationData.functionName) == t(option.functionName) ? "selected" : ""}>

                              {t(option.functionName)

                              }
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Employee CV")}:<span style={{ color: 'red', marginLeft: '2px' }}>{selectedRole === 'Manager' ? '*' : ''}</span></label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown" style={{ background: (selectedRole === 'Worker') ? "#e9ecef" : "white" }}
                      >
                        <input
                          type="file"
                          id="file-upload"
                          disabled={selectedRole === 'Worker'}
                          className="Upload-excel-input"
                          {...register("employeeCVPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: employeeCvFile ? null : 0 }} onChange={(e) => handleFileChange(e)}
                        />
                        <input type="hidden"
                          {...register("employeeCVEncoded")}
                          value={employeeCvFile} />
                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {editRegistrationData.employeeCVPath &&
                          <span title={editRegistrationData.employeeCVPath} className="material-symbols-outlined" >
                            attach_file
                          </span>
                        }
                      </div>
                      {/* ------------- dummy input for disabled not working so -------------- */}
                      {/* <div className="popup-Dropdown" style={{ display: selectedRole === 'Worker' ? 'block' : 'none', background: (selectedRole === 'Worker') ? "#e9ecef" : "white" }}
                      >
                        <input
                          type="file"
                          disabled
                          className="Upload-excel-input"
                          {...register("employeeCVPath")}
                          placeholder={'Choose a file'}
                          style={{ color: 'black' }}
                        />
                        <input type="hidden"
                        />
                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>

                      </div> */}
                      {/* ------------- dummy input for disabled-------------- */}
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">{t("Language")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span></label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <select className="heading mb-2 mt-2  selectpicker"
                          {...register("languageKnownId")}
                          defaultValue={editRegistrationData.languageKnownId}
                        >
                          <option value={1} selected={editRegistrationData.languageKnown === "French"}>French</option>
                          <option value={2} selected={editRegistrationData.languageKnown === "English"}>English</option>
                          <option value={3} selected={editRegistrationData.languageKnown === "German"}>German</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Ex-Situ training questionnaire completed and signed")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <input
                          type="file"
                          id="file-upload"
                          className="Upload-excel-input"
                          {...register("proofOfExSituTrainingCompletedPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: proofOfExSituFile ? null : 0 }} onChange={(e) => handleFileChange(e)}

                        />
                        <input type="hidden"
                          {...register("ProofOfExSituTrainingCompletedEncoded")}
                          value={proofOfExSituFile} />

                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {editRegistrationData.proofOfExSituTrainingCompletedPath &&
                          <span title={editRegistrationData.
                            proofOfExSituTrainingCompletedPath
                          } className="material-symbols-outlined" >
                            attach_file
                          </span>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("ProofMedicalClearance")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      {/* <div className="popup-Dropdown">
                        <input
                          type="file"
                          id="file-upload"
                          className="Upload-excel-input"
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black' }} onChange={(e) => handleFileChange(e)}
                          {...register("proofOfMedicalClearance")}
                        />
                        <input type="hidden"
                          {...register("proofOfMedicalClearanceEncoded")}
                          value={proofOfMFile} />

                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                      </div> */}
                      <div className="popup-Dropdown">
                        <input
                          type="file"
                          id="file-upload"
                          className="Upload-excel-input"
                          {...register("proofOfMedicalClearance")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: proofOfMFile ? null : 0 }} onChange={(e) => handleFileChange(e)}
                        />
                        <input type="hidden"
                          {...register("proofOfMedicalClearanceEncoded")}
                          value={proofOfMFile} />
                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {editRegistrationData.proofOfMedicalClearance &&
                          <span title={editRegistrationData.proofOfMedicalClearance
                          } className="material-symbols-outlined" >
                            attach_file
                          </span>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Years of experience")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </label>
                  <div className="popup-input mt-2">
                    <div className="calendar">
                      {/* {(parseInt(editRegistrationData.yearsOfExperience) < 5 && (editRegistrationData.functionName === "Business Manager" || editRegistrationData.functionName === "HSE Manager" || editRegistrationData.functionName === "Leader" || editRegistrationData.functionName === "Manager on site")) || (parseInt(editRegistrationData.yearsOfExperience) < 1.5 && editRegistrationData.functionName === "Collaborator") ? <Spinner style={{ marginLeft: "2px", marginTop: "3px" }} title={editRegistrationData.functionName === "Collaborator" ? t("Employee has") + " " + t(editRegistrationData.functionName) + " " + t("function, but having less than 1.5 years of experience.") : t("Employee has") + " " + t(editRegistrationData.functionName) + " " + t("function, but having less than 5 years of experience.")} id="spinner" animation="grow" variant="danger" /> : <></>} */}

                      {/* <Spinner style={{marginLeft:"295px"}} id="" animation="grow" variant="danger" /> */}
                      <DatePicker
                        className="selectpickers"
                        // selectsRange={false}
                        // startDate={startDate}
                        // endDate={endDate}
                        selected={yearsOfExpDate}
                        showYearDropdown
                        onChange={(date) => {
                          setYearsOfExpDate(date)
                        }
                        }
                        dateFormat="dd/MM/yyyy"
                        isClearable={true}
                        maxDate={new Date()}
                      //{...register("WorkStartDate")}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={12}>
                  <div class="panel panel-default centre" style={{ fontWeight: 'bold' }}>
                    <div class="panel-body">{t("Authorization to work in Switzerland")}:</div>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Start Date")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </label>
                  <div className="popup-input mt-2">
                    <div className="calendar">
                      <DatePicker
                        className="selectpickers"
                        selectsRange={false}
                        // startDate={startDate}
                        // endDate={endDate}
                        // value={dateValue(startDate, endDate)}
                        showYearDropdown
                        selected={startDate}
                        onChange={(update) => {

                          setStartDate(update);
                          setEndDate()
                        }}
                        dateFormat="dd/MM/yyyy"
                        isClearable={true}
                      // maxDate={new Date()}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("End Date")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </label>
                  <div className="popup-input mt-2">
                    <div className="calendar">
                      <DatePicker
                        className="selectpickers"
                        selectsRange={false}
                        // startDate={startDate}
                        // endDate={endDate}
                        // value={dateValue(startDate, endDate)}
                        disabled={startDate == null}
                        showYearDropdown
                        selected={endDate}
                        onChange={(update) => {

                          setEndDate(update);
                        }}
                        dateFormat="dd/MM/yyyy"
                        isClearable={true}
                        minDate={new Date(startDate)}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Proof of Authorization")}:<span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <input
                          type="file"
                          id="file-upload"
                          className="Upload-excel-input"
                          {...register("proofOfAuthorization")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: proofOfAuthFile ? null : 0 }} onChange={(e) => handleFileChange(e)}
                        />

                        <input type="hidden"
                          {...register("proofOfAuthorizationEncoded")}
                          value={proofOfAuthFile} />
                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {editRegistrationData.proofOfAuthorization &&
                          <span title={editRegistrationData.proofOfAuthorization} className="material-symbols-outlined" >
                            attach_file
                          </span>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
                <div style={{ height: '15px' }}></div>
                {/* <Col md={1}></Col>
                <Col md={5}></Col> */}
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Minimum N1 chemical risk authorization or equivalent")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <select className="heading mb-2 mt-2  selectpicker" onChange={(e) => handleSelect2(101, e.target.value, "N1chemical")}>
                          <option value={'No'} selected={editRegistrationData.proofOfN1ChemicalRiskAuthPath == null}>{t("No")}</option>
                          <option value={'Yes'} selected={editRegistrationData.proofOfN1ChemicalRiskAuthPath}>{t("Yes")}</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Proof of N1 chemical risk clearance​")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown" title={editRegistrationData.proofOfN1ChemicalRiskAuthPath} id="101fileup" style={{ background: editRegistrationData.proofOfN1ChemicalRiskAuthPath ? 'white' : "#e9ecef" }} >
                        <input
                          type="file"
                          id="101fileinput"
                          disabled={!editRegistrationData.proofOfN1ChemicalRiskAuthPath}
                          className="Upload-excel-input"
                          {...register("proofOfN1ChemicalRiskAuthPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: proofOfN1File ? null : 0 }}
                          onChange={(e) => handleFileChange(e)}

                        />
                        <input type="hidden"
                          {...register("proofOfN1ChemicalRiskAuthEncoded")}
                          value={proofOfN1File} />

                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {editRegistrationData.proofOfN1ChemicalRiskAuthPath &&
                          <span title={editRegistrationData.proofOfN1ChemicalRiskAuthPath} className="material-symbols-outlined" >
                            attach_file
                          </span>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Technical authorization ATEX 0")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <select className="heading mb-2 mt-2  selectpicker" onChange={(e) => handleSelect2(102, e.target.value, "ATEX")}>
                          <option value={'No'} selected={editRegistrationData.proofOfATEXAuthPath == null}>{t("No")}</option>
                          <option value={'Yes'} selected={editRegistrationData.proofOfATEXAuthPath}>{t("Yes")}</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Proof of Technical authorization ATEX 0")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown" title={editRegistrationData.proofOfATEXAuthPath} id="102fileup" style={{ background: editRegistrationData.proofOfATEXAuthPath ? 'white' : "#e9ecef" }}>
                        <input
                          type="file"
                          id="102fileinput"
                          disabled={!editRegistrationData.proofOfATEXAuthPath}
                          className="Upload-excel-input"
                          placeholder={file ? file.name : 'Choose a file'}
                          {...register("proofOfATEXAuthPath")}
                          style={{ color: 'black', opacity: proofOfATEXFile ? null : 0 }}
                          onChange={(e) => handleFileChange(e)}

                        />
                        <input type="hidden"
                          {...register("proofOfATEXAuthEncoded")}
                          value={proofOfATEXFile} />


                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {editRegistrationData.proofOfATEXAuthPath &&
                          <span title={editRegistrationData.proofOfATEXAuthPath} className="material-symbols-outlined" >
                            attach_file
                          </span>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Authorization to wear the SCBA mask")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown" >
                        <select className="heading mb-2 mt-2  selectpicker" onChange={(e) => handleSelect2(103, e.target.value, "SCBAmask")}>
                          <option value={'No'} selected={editRegistrationData.proofOfSCBAMaskAuthPath == null}>{t("No")}</option>
                          <option value={'Yes'} selected={editRegistrationData.proofOfSCBAMaskAuthPath}>{t("Yes")}</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Proof of authorization to wear the SCBA mask")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown" title={editRegistrationData.proofOfSCBAMaskAuthPath} id="103fileup" style={{ background: editRegistrationData.proofOfSCBAMaskAuthPath ? 'white' : "#e9ecef" }}>
                        <input
                          type="file"
                          id="103fileinput"
                          disabled={!editRegistrationData.proofOfSCBAMaskAuthPath}
                          className="Upload-excel-input"
                          {...register("proofOfSCBAMaskAuthPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: proofOfSCBAFile ? null : 0 }}
                          onChange={(e) => handleFileChange(e)}

                        />
                        <input type="hidden"
                          {...register("proofOfSCBAMaskAuthEncoded")}
                          value={proofOfSCBAFile} />

                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {editRegistrationData.proofOfSCBAMaskAuthPath &&
                          <span title={editRegistrationData.proofOfSCBAMaskAuthPath} className="material-symbols-outlined" >
                            attach_file
                          </span>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Authorization to wear the harness")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown">
                        <select className="heading mb-2 mt-2  selectpicker" onChange={(e) => handleSelect2(104, e.target.value, "harness")}>
                          <option value={'No'} selected={editRegistrationData.proofOfHarnessAuthPath == null}>{t("No")}</option>
                          <option value={'Yes'} selected={editRegistrationData.proofOfHarnessAuthPath}>{t("Yes")}</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <label className="fLeft">
                    {t("Proof of authorization to wear the harness")}:
                  </label>
                  <Row className="heading mb-2 mt-2">
                    <Col md={12}>
                      <div className="popup-Dropdown" title={editRegistrationData.proofOfHarnessAuthPath} id="104fileup" style={{ background: editRegistrationData.proofOfHarnessAuthPath ? 'white' : "#e9ecef" }}>
                        <input
                          type="file"
                          id="104fileinput"
                          disabled={!editRegistrationData.proofOfHarnessAuthPath}
                          className="Upload-excel-input"
                          {...register("proofOfHarnessAuthPath")}
                          placeholder={file ? file.name : 'Choose a file'}
                          style={{ color: 'black', opacity: proofOfHarnessFile ? null : 0 }}
                          onChange={(e) => handleFileChange(e)}

                        />
                        <input type="hidden"
                          {...register("proofOfHarnessAuthEncoded")}
                          value={proofOfHarnessFile} />

                        <span class="material-symbols-outlined" id="cloud-upload">
                          {" "}
                          cloud_upload{" "}
                        </span>
                        {editRegistrationData.proofOfHarnessAuthPath &&
                          <span title={editRegistrationData.proofOfHarnessAuthPath} className="material-symbols-outlined" >
                            attach_file
                          </span>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
                {/* <Col md={1}></Col> */}

                <>

                  {skills?.map((item, index) => (
                    //  editRegistrationData.skills.includes(item.skillName)? (
                    skills ? (

                      <React.Fragment key={index}>
                        <Col md={1}></Col>
                        <Col md={5}>
                          <label className="fLeft">
                            {t(item.skillName)}:
                          </label>
                          <Row className="heading mb-2 mt-2">
                            <Col md={12}>
                              <div className="popup-Dropdown" key={item.id}>
                                <select className="heading mb-2 mt-2  selectpicker" id={item.id} onChange={(e) => handleSelect(item.id, e.target.value, item, item.skillName, item.documentPath)}>
                                  <option value={'No'} selected={item.documentPath == null}>{t("No")}</option>
                                  <option value={'Yes'} selected={item.documentPath}>{t("Yes")}</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={5}>
                          <label className="fLeft">
                            {t('Proof of')} {t(item.skillName)}:
                          </label>
                          <Row className="heading mb-2 mt-2">
                            <Col md={12}>
                              <div className="popup-Dropdown" title={item.documentPath} style={{ background: item.documentPath ? 'white' : "#e9ecef" }} id={item.id + 'fileup'}>

                                <input
                                  type="file"
                                  id={item.id + 'fileinput'}
                                  disabled={!item.documentPath}
                                  className="Upload-excel-input"
                                  style={{ color: 'black', opacity: 0 }}
                                  placeholder={file ? file.name : 'Choose a file'}
                                  //  style={{ color: 'black'}}
                                  onChange={(e) => handleFileChangeDynamic(e, item.id, item.documentPath)}
                                />
                                <span className="material-symbols-outlined" id="cloud-upload">
                                  cloud_upload
                                </span>
                                {item.documentPath &&
                                  <span title={item.documentPath} className="material-symbols-outlined" >
                                    attach_file
                                  </span>
                                }

                              </div>


                            </Col>
                          </Row>
                        </Col>

                      </React.Fragment>



                    ) : null
                  ))}
                </>

              </Row>

            </Row>
            <Col md={1}></Col>

            <Row>
              <Col md={6}></Col>
              <Col md={6}>
                <Row>

                  <Col md={4}>
                    <Link to="/registerList">
                      {" "}
                      <Button className="cancel-button m-2" variant="light">
                        {t("Cancel")}{" "}
                      </Button>
                    </Link>
                  </Col>
                  <Col md={4}>
                    <Button
                      className="add-button m-2"
                      variant="light"
                      type="submit"
                      onClick={event => onSaveClick(event)}
                    >
                      {t("Save")}{" "}

                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      className="add-button m-2"
                      variant="light"
                      // disabled
                      onClick={event => onSubmitClick(event.target.value)}
                    // style={{ background: "#e9ecef" }}
                    >
                      {t("Submit")}{" "}
                    </Button>
                  </Col>

                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6}></Col>
              <Col md={6}>
                <Row>

                  <Col md={4}>

                  </Col>
                  <Col md={4}>
                    <div className="dummydiv">

                    </div>
                  </Col>

                </Row>
              </Col>
            </Row>
          </form>
        </div>
      </div>
      <ToastContainer />

    </div>
  );
}
export default RegistrationEdit;
