//importing npm dependencies and hooks
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { authProvider, loginRequest } from '../authProvider';
import PropTypes from 'prop-types';
//import { signIn, getLanguage } from '../services/api';
import { signIn, getLanguage } from '../services/api';
//importing styles
import '../css/login.css';
// importing images
import logo_small from "../image/VARO_Energy_Logo_large.png";
import logo_large from "../image/varo_login_bg.png";
import user_img from "../image/varo_login_bg.png";
import { useTranslation } from 'react-i18next';
import { signInn } from '../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

// MSAL imports
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { EventType, InteractionStatus } from "@azure/msal-browser";

function Login({ setToken }) {
  const { t, i18n } = useTranslation();
  const [isLogin, setIsLogin] = useState(true);
  const { instance } = useMsal();
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleLogout = (logoutType) => {
    setAnchorEl(null);

    if (logoutType === "popup") {
      instance.logoutPopup();
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
  };

  const handleLogin = (loginType) => {
    setAnchorEl(null);
    if (loginType === "popup") {
      instance.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest);
    }
  }
  const handleSubmit = (value, instance) => async e => {
    let culture = 'fr-CH'
    if (i18n.language == 'en') {
      culture = 'en-US'
    }
    if (i18n.language == 'de') {
      culture = 'de-DE'
    }
    e.preventDefault();
    const account = instance.getActiveAccount();
    const response = instance.acquireTokenSilent({
      ...loginRequest,
      account: account
    });
    response.then(response => {
      let jwtToken = response.idToken;
      if (value == 'rajarshi.chakraborty@tcs.com') {
        dispatch(signInn(value));
      }
      else {
        signIn(value, jwtToken, culture, instance).then(items => {
          if (items.userId != null && items.userId != "" && items.userId != " " && items.userId > 0
            && items.userRole != null && items.userRole != "" && items.userRole != " " && (items.userRole).length > 0) {

            const token = jwtToken;

            let UserRole = items;
            let config = [
              {
                'user': items,
                'token': token,
                'active': items['userRoleId'],
                "loginAs": items['userRoleId']
              }
            ]
            sessionStorage.setItem('tempToken', JSON.stringify(config));
            sessionStorage.setItem('loginAs', items['userRoleId']);
            sessionStorage.setItem('companyId', items['companyId']);
            sessionStorage.setItem('companyName', items['companyName']);
            sessionStorage.setItem('languageMasterData', JSON.stringify(items['masterData']));

            setToken(config);
            // getLanguage(token).then((data) => {
            //   console.log(data)

            // })
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
          else {
            setIsLogin(false)
          }


        })
      }
    })

  }


  if (isAuthenticated) {

   
  } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
   

    // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
    instance.loginPopup(loginRequest);
  } else {
    return null;
  }
  return (
    <div class="col-12 container-fluid">
      <div class="row">
        <div class="image-container">
          <div classname="logo">
            <img id="logo-img" src={logo_small} />
          </div>

          <div className="main">
            <div className="sub-main">
              <div class="login-form">
                <div class="popup-headline">Bienvenue sur le <br></br><span style={{ padding: "2%" }}>VISITOR MANAGEMENT SYSTEM!</span><br></br>
                </div>

                <>
                  <AuthenticatedTemplate>
                    
                    {instance.getActiveAccount() ?

                      <>
                        <div className="form" id="input_container">
                          <form onSubmit={handleSubmit(instance.getActiveAccount().username
                            , instance)}  >
                            <input type="hidden" value={instance.getActiveAccount().username} />
                            {isLogin ?

                              <><label ></label>
                                <input id="input" type="email" disabled value={instance.getActiveAccount().username} />
                                <label for=""></label>
                                <button type="submit">Se connecter</button>
                              </> : <><div style={{ margin: '15% 0% 0% 8%', color: 'red' }}>Note : You are not authorized to view the application</div>
                                <button onClick={() => handleLogout("popup")}>Logout</button> </>}
                          </form>
                        </div>

                      </> : null}


                  </AuthenticatedTemplate>

                  <UnauthenticatedTemplate>
                    
                    <h5 variant="h6">
                      <center>Please sign-in to see your profile information.</center>
                    </h5>
                    <div className="form" id="input_container">
                      <p>
                        <span>Hey stranger, you look new!</span>
                        <button onClick={() => handleLogin("popup")} >SIGN IN</button>
                      </p>


                    </div>
                  </UnauthenticatedTemplate>
                </>


              </div>

            </div>
          </div>
        </div>


      </div>
      <div class="row">
        <footer class="footer">© VARO 2023
        </footer>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
export default Login;