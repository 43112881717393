import { GETNOTIFYDATA,GETLANGDATA, GETREGCOMPANYID, SIGNIN } from './actionTypes';

export const getNotifyCount = (data) => {
  return {
    type: GETNOTIFYDATA,
    payload: data
  };
};

export const getLang = (data) => {
  return {
    type: GETLANGDATA,
    payload: data
  };
};
export const getRegCompanyId = (data) => {
  return {
    type: GETREGCOMPANYID,
    payload: data
  };
};
export const signInn = (data) => {
  return {
    type: SIGNIN,
    payload: data
  };
};
