import React from "react";
 
const Popup = props => {
  return (
    <div className="popupNoti-boxs">
      <div className="notiBoxs">
    <>
        <span className="notiClose-icons" onClick={props.handleClose}>x</span>
        {props.content}
        </>
    </div>
     </div>
  );
};
 
export default Popup;