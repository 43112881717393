const initialState = {
    
};

export const regcompReducer = (state = initialState, action) => {  
  // new state start
  switch (action.type) {
    case 'GETREGCOMPANYID':
      return {
        ...state,
        regCompanyId: action.payload
      };
      default:
    return state;
  }
  // new state end
 
  
};